import React from 'react'
import classNames from 'classnames'

import { ContainerStyled } from './styled'

export default ({ children, className, ...props }) => (
  <ContainerStyled {...props} className={classNames(className)}>
    {children}
  </ContainerStyled>
)

import React, { useEffect, useMemo, useState } from 'react';
import BaseTable from '../../../components/base-table';
import { Select, DatePicker } from 'antd';
import ButtonImage from './../../../components/button-img';
import { STATUS_ADS } from '../../../constants';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../stores';
import { listRequestManagementAction } from '../../../stores/screens/requestManagement/management.action';

import {
  getHistoriesAdvertiseAction
} from '../../../stores/screens/advertise/advertise.action';
import { columns } from './columns';
import { Images } from '../../../theme';
import { TabInfoWrapper, FilterWrapper } from './styled';
import { ADS_TYPE_OPTION } from '../../../constants';

const ManagementPage = () => {
  const [filter, setFilter] = useState({
    advertisementId: null,
    category: null,
    buildingId: null,
    startDate: null,
    endDate: null
  });
  const navigate = useNavigate();
  const { advertiseHistories, listBuilding } = useAppSelector(
    (state) => state.advertise
  );
  const { listRequestManagement } = useAppSelector(
    (state) => state.requestManagement
  );
  const { data, paginate } = advertiseHistories;
  const dispatch = useAppDispatch();

  const onFetch = () => dispatch(getHistoriesAdvertiseAction({
    page: 1,
    limit: paginate.limit,
    ...filter
  }));

  useEffect(() => {
    dispatch(getHistoriesAdvertiseAction({
      ...paginate,
      page: 1
    }));
    dispatch(listRequestManagementAction({ status: STATUS_ADS.APPROVED }));
  }, []);

  const NavigateTodetail = ({id, transactionId}) => {
    navigate(`/advertise/${id}/transaction/${transactionId}`)
  }

  const buildingOptions = useMemo(() => {
    if (listBuilding && !!listBuilding.length) return listBuilding.map((m) => ({ value: m.id, label: m.name, }))
    return [];
  }, [listBuilding]);

  const advertiseOptions = useMemo(() => {
    if (listRequestManagement?.items && !!listRequestManagement?.items.length)
      return listRequestManagement?.items.map((m) => ({
        value: m.id,
        label: <span ellipsis>{m.name}</span>,
        type: m?.type
      }))
    return [];
  }, [listRequestManagement?.items]);
  const onChangePage = (pagination) => dispatch(getHistoriesAdvertiseAction({
    page: pagination.current,
    limit: paginate.limit,
    ...filter
  }));

  const disabledStartDate = (current) => {
    return !!filter.endDate  && moment(filter.endDate, 'YYYY-MM-DD').isBefore(moment(current, 'YYYY-MM-DD'), 'day');
  };

  const disabledEndDate = (current) => {
    return !!filter.startDate && moment(filter.startDate, 'YYYY-MM-DD').isAfter(moment(current, 'YYYY-MM-DD'), 'day');
  };

  return (
    <TabInfoWrapper>
      <h3 className="title">List of Booked Advertisements</h3>
      <FilterWrapper className='dp-flex'>
        <div className="form-group">
          <Select
            value={filter?.advertisementId}
            options={advertiseOptions}
            listHeight={230}
            className={`select`}
            placeholder="Advertisements"
            popupClassName="custom-option"
            onChange={(e) => setFilter({
              ...filter,
              advertisementId: e
            })}
            allowClear={true}
            suffixIcon={
              <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
            }
            autoClearSearchValue={true}
          />
        </div>
        <div className="form-group">
          <Select
            value={filter?.category}
            options={ADS_TYPE_OPTION}
            listHeight={230}
            className={`select`}
            placeholder="Ads Type"
            popupClassName="custom-option"
            onChange={(e) => setFilter({
              ...filter,
              category: e
            })}
            allowClear={true}
            suffixIcon={
              <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
            }
            autoClearSearchValue={true}
          />
        </div>
        <div className="form-group">
          <Select
            value={filter?.buildingId}
            options={buildingOptions}
            listHeight={230}
            className={`select`}
            placeholder="Building"
            popupClassName="custom-option"
            onChange={(e) => setFilter({
              ...filter,
              buildingId: e
            })}
            allowClear={true}
            suffixIcon={
              <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
            }
            autoClearSearchValue={true}
          />
        </div>
        <div className="form-group">
          <DatePicker
            value={filter?.startDate ? moment(filter?.startDate) : null}
            className={`date-picker`}
            popupClassName="date-picker-panel-custom"
            placeholder={'Start date'}
            onChange={(e) => {
              setFilter({
                ...filter,
                startDate: e ? moment(e).format('YYYY-MM-DD') : null,
              });
            }}
            suffixIcon={<img src={Images.ICON_CALENDAR} alt="calendar" />}
            allowClear
            disabledDate={disabledStartDate}
          />
        </div>
        <div className="form-group">
          <DatePicker
            value={filter?.endDate ? moment(filter?.endDate) : null}
            className={`date-picker`}
            popupClassName="date-picker-panel-custom"
            placeholder={'End date'}
            onChange={(e) => {
              setFilter({
                ...filter,
                endDate: e ? moment(e).format('YYYY-MM-DD') : null,
              });
            }}
            suffixIcon={<img src={Images.ICON_CALENDAR} alt="calendar" />}
            allowClear
            disabledDate={disabledEndDate}
          />
        </div>
        <ButtonImage
          onClick={onFetch}
          type="action"
          height={40}
          textTransform="unset"
          fontWeight={300}
          fontSize={14}
          className="btn-search"
        >
          Search
        </ButtonImage>
      </FilterWrapper>
      <BaseTable
        data={data}
        columns={columns({ navigate: NavigateTodetail, currentPage: paginate.page })}
        pagination={paginate.totalCount > paginate.limit ?  { current: paginate.page, pageSize: paginate.limit, total: paginate.totalCount } : false}
        onChange={onChangePage}
        rowKey={(record) => record?.mcc_transaction_id}
      />
    </TabInfoWrapper>
  );
};

export default ManagementPage;

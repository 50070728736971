import styled from "styled-components";
import { Colors } from '../../theme'

export const FooterStyled = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #051847;
  color: #fff;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 0 50px 0;

    .menu-categories {
      display: flex;
      justify-content: flex-end;
      align-self: flex-start;
      gap: 100px;

      .menu-items {
        .title {
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 15px;
        }

        .list-link > p, .list-link > a {
          display: block;
          margin-bottom: 8px;
          font-size: 18px;
          font-weight: 400;
          cursor: pointer;
          color: #fff;
          &:hover {
            text-decoration: underline;
          }
        }

        .list-link.icon img {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .bottom {
    width: 100%;
    text-align: center;
    padding: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 767.98px) {
    .top {
      flex-direction: column;
      padding: 30px 0px 20px;

      .logo {
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
      }

      .menu-categories {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px;
        padding: 20px;

        .menu-items {
          width: calc(50% - 10px);

          .title {
            font-size: 18px;
            margin-bottom: 10px;
          }

          .list-link > p , .list-link > a {
            margin-bottom: 5px;
            font-size: 16px;
          }
        }
      }
    }

    .bottom {
      font-size: 14px;
      text-align: center;
      padding: 15px 20px;
    }
  }

  @media (min-width: 768px) {
  }

  @media (max-width: 991px) {
    .top {
      flex-direction: column;
      padding: 30px 0px 20px;

      .logo {
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
      }

      .menu-categories {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        padding: 20px;
      }
    }
  }
`;

import React from 'react';

import { CountryBlockedStyled } from './styled';
import { Images } from '../../theme';

const CountryBlocked = () => {
  return (
    <CountryBlockedStyled>
      <div className='content'>
        <div className='logo'>
          <img src={Images.LOGO_LOGIN} alt="Logo MCC" />
        </div>
        <div className='images'>
          <img src={Images.COUNTRY_BLOCKED} alt='Country Blocked' />
        </div>
        <div className='text'>Country Blocked</div>
        <div className='sub-text'>This site cannot be accessed in your Country/Region.</div>
      </div>
    </CountryBlockedStyled>
  );
};
export default CountryBlocked;

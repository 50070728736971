import styled from "styled-components";

import { Images, Colors } from "../../theme";

export const CardStyled = styled.div`
  position: relative;
  width: 525px;
  max-width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  background: transparent;

  .bg-card {
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .image {
    flex: 2;
    order: ${({ positionImage }) => (positionImage === "left" ? 1 : 2)};
    height: 100%;
    display: flex;
    align-items: center;
  }

  .content {
    flex: 3.5;
    order: ${({ positionImage }) => (positionImage === "left" ? 2 : 1)};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${({ positionImage }) =>
    positionImage === "left" ? `0px 20px 0px 0px` : `0px 0px 0px 20px`};

    .title {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 900;
    }
  }

  @media (max-width: 767.98px) {
    width: 100%;
    height: auto;
    .content {
      .btn-image {
        height: 45px;
      }
      .title {
        font-size: 14px;
        margin-bottom: 5px
      }
    }
  }

  @media (min-width: 768px) {
  }
`;

import React from "react";
import moment from "moment";
import BaseButton from "../../../components/button";
import { Images } from "../../../theme";
import { Tooltip } from "antd";

export const columns = ({ navigate, currentPage }) => [
  {
    title: "#",
    key: "num",
    render: (_, record, index) => (
      <span>{(currentPage - 1) * 8 + (index + 1)}</span>
    ),
    width: 48,
  },
  {
    title: "Advertisement",
    key: "name",
    render: (_, record) => (
      <div style={{ flex: 2, display: "flex" }}>
        {record?.advertisement_name || ""}
      </div>
    ),
    width: "30%",
  },
  {
    title: "Transaction ID",
    key: "transactionId",
    render: (_, record) => (
      <div style={{ display: "flex" }}>{record?.mcc_transaction_id || ""}</div>
    ),
    width: "15%",
  },
  {
    title: "Building",
    key: "building_name",
    render: (record) => {
      const getPostContracts = record?.buildings;
      return (
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {getPostContracts.map((item, index) => (
            <Tooltip
              key={index}
              placement="topLeft"
              color="#051847"
              overlayInnerStyle={{
                border: "1px solid #156DF280",
                borderRadius: "10px",
                width: "fit-content",
                display: "inline-block",
              }}
              autoAdjustOverflow={true}
              destroyTooltipOnHide={true}
              zIndex={999}
              title={
                <div style={{ whiteSpace: "normal", display: "inline-block" }}>
                  {getPostContracts.map((item, index) => (
                    <p style={{ whiteSpace: "normal", display: "inline-block" }} key={index}>
                      {item?.building_name || ""}
                      {index !== getPostContracts.length - 1 && ", "}
                    </p>
                  ))}
                </div>
              }
            >
              <p
                key={index}
                style={{
                  display: "inline",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "200px",
                }}
              >
                {item?.building_name || ""}
              </p>
              {index !== getPostContracts.length - 1 && <span>, </span>}
            </Tooltip>
          ))}
        </div>
      );
    },
    width: "25%",
  },
  {
    title: "Start Date",
    key: "start_time",
    render: (_, record) => (
      <div style={{ display: "flex" }}>
        {moment(record?.start_time).format("MM/DD/YYYY")}
      </div>
    ),
    width: "10%",
  },
  {
    title: "End Date",
    key: "end_time",
    render: (_, record) => (
      <div style={{ display: "flex" }}>
        {moment(record?.end_time).format("MM/DD/YYYY")}
      </div>
    ),
    width: "10%",
  },
  {
    title: "Action",
    dataIndex: "id",
    render: (_, record) => (
      <BaseButton
        className="btn-action detail"
        onClick={() =>
          navigate({
            id: record?.advertisement_id,
            transactionId: record?.mcc_transaction_id,
          })
        }
      >
        <img src={Images.ICON_EYE} alt="icon-eye" />
      </BaseButton>
    ),
    width: 48,
  },
];

import styled from 'styled-components';
import { Images } from '../../theme';

export const NotFoundStyled = styled.div`
  width: 100%;
  height: 100%;
  background: url(${Images.NOT_FOUND_BG});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    text-align: center;

    .images {
      padding: 0 50px 95px 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      color: #fff;
      font-size: 30px;
      letter-spacing: 16px;
      text-transform: uppercase;
      padding-bottom: 30px;
    }

    .text-dark {
      .btn-content {
        color: #2b0c01;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
      }
    }

    @media (max-width: 575px) {
      .text {
        font-size: 25px;
        letter-spacing: 3px;
      }
      .images {
        padding: 0 30px 50px 30px;
      }
    }
  }
`

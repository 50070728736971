import React, { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../stores';
import { getListEventsAction } from '../../stores/screens/events/events.action';

/** component */
import Page from '../../components/page';
import { Container, ContentStyled, EventStyled } from './styled';
import TitleSection from '../../components/title-section';
import CardEvent from '../../components/card';
import Pagination from '../../components/pagination';
import Skeleton from '../../components/loading-skeleton';

function EventList() {
  const dispatch = useAppDispatch();
  const { listEvents, isLoading } = useAppSelector((state) => state.events);
  const [page, setPage] = useState(1);
  const defaultItemsOnPage = 12;
  useEffect(() => {
    fetchDataEvents();
  }, [page]);

  const fetchDataEvents = () => {
    dispatch(
      getListEventsAction({
        limit: defaultItemsOnPage,
        page,
      })
    );
  };

  const onChange = (currentPage) => {
    setPage(currentPage);
  };
  const renderListSkeletonLoading = Array.from(
    { length: defaultItemsOnPage },
    (x) => <Skeleton loading={isLoading} active />
  );

  return (
    <Page>
      <ContentStyled className='content'>
        <Container>
          <TitleSection>events</TitleSection>
          <EventStyled>
            {isLoading
              ? renderListSkeletonLoading
              : listEvents?.items?.map((item) => (
                  <CardEvent data={item} key={item?.id} />
                ))}
          </EventStyled>
          {listEvents?.totalCount ? (
            <Pagination
              total={listEvents?.totalCount}
              current={page}
              showSizeChanger={false}
              onChange={onChange}
              pageSize={defaultItemsOnPage}
            />
          ) : null }
        </Container>
      </ContentStyled>
    </Page>
  );
}

export default EventList;

import styled from "styled-components";
import { Colors } from "../../../theme";

export const Content = styled.div`
  .home-service {
    position: relative;
    background: rgb(3, 21, 63);
    background: linear-gradient(
      90deg,
      rgba(3, 21, 63, 1) 0%,
      rgba(5, 43, 134, 1) 100%
    );
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .card-actions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
    margin-top: 30px;
    margin-bottom: 70px;
  }

  .description {
    font-size: 20px;
    font-weight: 400;
    padding: 30px 0 40px;
    width: 100%;
    max-width: 1000px;
    p {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .video-media {
    text-align: center;
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16 / 9;
    background-color: #000;
    margin-top: 15px;
    margin-bottom: 15px;

    .line {
      position: absolute;
      overflow: inherit;

      &.top {
        left: 0;
        top: -15px;
      }

      &.bottom {
        right: 0;
        bottom: -15px;
        transform: rotate(180deg);
      }
    }
  }

  .box-event {
    width: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .title {
        font-size: 28px;
        font-weight: 700;
      }

      .view-more {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .wrapper-events {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 28px 16px;
    margin: 50px auto;
    margin-top: 30px;

    .event-item {
      flex: 1;
    }
  }

  @media (max-width: 767.98px) {
    .home-service {
      .body {
        padding-top: 25px;
        padding-bottom: 25px;
      }

      .card-actions {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .image {
          width: 35%;
          >img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        padding: 10px 10px 20px;
      }

      .video-media {
        width: 100%;
      }

      .box-event {
        .header {
          .title {
            font-size: 16px;
            font-weight: 900;
          }

          .view-more {
            font-size: 12px;
          }
        }
      }

      .wrapper-events {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 10px;
      }
    }
  }

  @media (min-width: 768px) {
    .home-service {
    }
  }

  @media (max-width: 1122px) {
    .home-service {
      .card-actions {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
      }

      .wrapper-events {
        flex-direction: column;
        margin-top: 10px;

        .event-item {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .home-service .wrapper-events {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      margin: 22px auto;
      margin-bottom: 10px;
    }
  }
`;

import React from 'react';
import classNames from 'classnames';

import { TableStyled } from './styled';

const BaseTable = ({
  className,
  columns,
  data,
  title,
  pagination,
  ...props
}) => {
  return (
    <TableStyled
      columns={columns}
      dataSource={data}
      className={classNames(className)}
      pagination={pagination}
      scroll={{ x: true }}
      {...props}
    />
  );
};

export default BaseTable;

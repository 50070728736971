import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CloseOutlined, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

import { IconCloseConfirmableStyled, StyledModal } from './styled';

let instance;

class Confirmable extends Component {
  static propTypes = {};

  state = {
    visible: false,
    content: null,
    acceptButtonText: null,
    cancelButtonText: null,
    hideCancelButton: false,
    hideOkButton: false,
    hideIconClose: false,
    width: 400,
    isShowIconSuccess: false,
    isShowIconExclamation: false
  };

  static setInstance = (ref) => {
    instance = ref;
  };

  static open = (...params) => {
    if (instance) {
      return instance.open(...params);
    }

    return null;
  };

  open = ({
    onOk,
    content,
    acceptButtonText,
    cancelButtonText,
    hideCancelButton,
    hideOkButton,
    hideIconClose,
    onLogout,
    width,
    isShowIconSuccess,
    isShowIconExclamation,
  }) =>
    new Promise((resolve) => {
      this._resolve = resolve;
      this.setState({
        visible: true,
        onOk,
        content,
        acceptButtonText,
        cancelButtonText,
        hideCancelButton,
        hideOkButton,
        hideIconClose,
        onLogout,
        width,
        isShowIconSuccess,
        isShowIconExclamation,
      });
    });

  _onClose = () => {
    this.setState({
      visible: false,
    });
  };

  _onCancel = () => {
    this._resolve(false);

    this._onClose();
  };

  _onAccept = () => {
    const { onOk } = this.state;
    if (onOk) onOk();

    this._onClose();

    setTimeout(() => {
      this._resolve(true);
    }, 300);
  };

  _handleLogout = () => {
    const { onLogout } = this.state;
    if (onLogout) {
      onLogout();
    }
  };

  render() {
    const {
      visible,
      content,
      acceptButtonText,
      cancelButtonText,
      hideCancelButton,
      hideOkButton,
      hideIconClose,
      width,
      isShowIconSuccess,
      isShowIconExclamation
    } = this.state;

    return (
      <StyledModal
        open={visible}
        centered
        onCancel={this._onCancel}
        footer={null}
        maskClosable={false}
        destroyOnClose
        width={width || 400}
      >
        {hideIconClose && (
          <IconCloseConfirmableStyled
            onClick={() => {
              this._onCancel();
              this._handleLogout();
            }}
          >
            <CloseOutlined />
          </IconCloseConfirmableStyled>
        )}
        <div className="modal-content">
          <Translation>
            {(t) => (
              <div className="body">
                <div className="container">
                  {isShowIconSuccess && (
                    <CheckCircleFilled className="icon-success" />
                  )}
                  {isShowIconExclamation && (
                    <ExclamationCircleFilled className="icon-exclamation" />
                  )}
                  <div className="content" style={{ wordBreak: 'break-word' }}>
                    {content}
                  </div>
                </div>
                <div className="action-box">
                  {!hideCancelButton && (
                    <button onClick={this._onCancel} className="action-button">
                      {cancelButtonText || 'Close'}
                    </button>
                  )}
                  {!hideOkButton && (
                    <button onClick={this._onAccept} className="action-button">
                      {acceptButtonText || 'OK'}
                    </button>
                  )}
                </div>
              </div>
            )}
          </Translation>
        </div>
      </StyledModal>
    );
  }
}

export default Confirmable;

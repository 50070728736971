import styled from 'styled-components';

import { Colors } from '../../theme';

export const DivStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  margin-top: 100px;
  margin-bottom: 100px;
  /* background-color: #E1E1E1; */

  &::after {
    display: block;
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: 6px solid ${({ theme, color }) => color || theme.white};
    border-left-color: ${({ theme, color }) =>
      Colors.alpha(color || theme.white, 0.5)};
    border-right-color: ${({ theme, color }) =>
      Colors.alpha(color || theme.white, 0.5)}; */
    border: 6px solid ${({ theme, color }) => color || '#E0EBFF'};
    border-left-color: ${({ theme, color }) =>
      Colors.alpha(color || '#E0EBFF', 0.5)};
    border-right-color: ${({ theme, color }) =>
      Colors.alpha(color || '#E0EBFF', 0.5)};
    animation: rotation 1s infinite linear;
  }

  &.small {
    min-height: 60px;
    margin-top: 40px;
    margin-bottom: 40px;
    &::after {
      width: 30px;
      height: 30px;
      /* border: 4px solid ${({ theme, color }) => color || theme.white};
      border-left-color: ${({ theme, color }) =>
        Colors.alpha(color || theme.white, 0.5)};
      border-right-color: ${({ theme, color }) =>
        Colors.alpha(color || theme.white, 0.5)}; */
      border: 4px solid ${({ theme, color }) => color || '#E0EBFF'};
      border-left-color: ${({ theme, color }) =>
        Colors.alpha(color || '#E0EBFF', 0.5)};
      border-right-color: ${({ theme, color }) =>
        Colors.alpha(color || '#E0EBFF', 0.5)};
    }
  }

  &.large {
    height: 100vh;
    width: 100vw;
  }

  &.full-screen {
    margin-top: 0;
    height: 100vh;
    width: 100vw;
  }
`;

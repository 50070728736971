import React from 'react';

import { ButtonStyled } from './styled';
import LOGIN_BTN from '../../assets/images/buttons-bg/login.png';
import PURPLE_BTN from '../../assets/images/buttons-bg/purple.png';
import BLUE_BTN from '../../assets/images/buttons-bg/blue.png';
import BROWN_BTN from '../../assets/images/buttons-bg/brown.png';
import GREEN_BTN from '../../assets/images/buttons-bg/green.png';
import CANCEL_BTN from '../../assets/images/buttons-bg/cancel.png';
import ACTION_BTN from '../../assets/images/buttons-bg/action.png';
import DELETE_BTN from '../../assets/images/buttons-bg/delete.png';

const Button = ({ children, height, fontSize, fontWeight, textTransform, disabled, ...props }) => {
  let src = PURPLE_BTN;

  switch (props?.type) {
    case 'login':
      src = LOGIN_BTN;
      break;
    case 'blue':
      src = BLUE_BTN;
      break;
    case 'green':
      src = GREEN_BTN;
      break;
    case 'brown':
      src = BROWN_BTN;
      break;
    case 'cancel':
      src = CANCEL_BTN;
      break;
    case 'action':
      src = ACTION_BTN;
      break;
    case 'delete':
      src = DELETE_BTN;
      break;
    default:
      break;
  }

  return (
    <ButtonStyled
      {...props}
      height={height}
      fontSize={fontSize}
      fontWeight={fontWeight}
      textTransform={textTransform}
      className={`btn-image ${props.className} ${disabled && 'disabled'}` }
    >
      <img src={props?.src || src} alt={`Button ${props.name}`} />
      <div className='btn-content'>{children}</div>
    </ButtonStyled>
  );
};
export default Button;

import styled from "styled-components";
import { Colors } from "../../theme";

export const SectionStyled = styled.section`
  max-width: 100%;
  overflow: hidden;

  @media (min-width: 320px) {
  }

  @media (min-width: 1024px) {
  }
`;

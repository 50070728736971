import { createAsyncThunk } from '@reduxjs/toolkit';

import AUTH_API from './auth.api';

export const loginAction = createAsyncThunk(
  'LOGIN',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.login(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const getUserInfoAction = createAsyncThunk(
  'GET_USER_INFO',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.getUserInfo(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const verifyEmailAction = createAsyncThunk(
  'VERIFY_EMAIL',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.verifyEmail(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const retrieveAccountAction = createAsyncThunk(
  'RETRIEVE_ACCOUNT',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.retrieveAccount(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const refreshTokenAction = createAsyncThunk(
  'REFRESH_TOKEN',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.refreshToken(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const loginWalletAction = createAsyncThunk(
  'LOGIN_WALLET',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.loginWallet(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const verifyEmailAuthAction = createAsyncThunk(
  'VERIFY_EMAIL_AUTH',
  async ({ params, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.verifyEmailAuth(params);
      if (callback) {
        callback(response);
      }
      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const authLoginAction = createAsyncThunk(
  'AUTH_LOGIN',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.authLogin(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const refreshTokenLoginAction = createAsyncThunk(
  'REFRESH_TOKEN_LOGIN',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.refreshTokenLogin(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);


export const redirectMccBetAction = createAsyncThunk(
  'REDIRECT_MCC_BET',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.redirectMccBet(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const triggerLoginAction = createAsyncThunk(
  'GET_USER_INFO',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AUTH_API.triggerLogin(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);
import React from 'react'

import { ModalStyled } from './styled'

export default ({ children, ...props }) => (
  <ModalStyled
    centered
    footer={null}
    {...props}
  >
    {children}
  </ModalStyled>
)

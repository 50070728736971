import axios from "axios";
export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_MCC = process.env.REACT_APP_API_URL_MCC;
export const API_URL_WALLET = process.env.REACT_APP_API_URL_WALLET;
export const GAME_SERVER_URL = process.env.REACT_APP_GAME_SERVER_URL;
export const END_POINT = {
  SIGN_WALLET: "accounts/signatureWallet",
  SSO_LOGIN: "api/v1/user/auth/sso-sign-in",
  SSO_LOGIN_WALLET: "api/v1/auth/sso-sign-in",
};

const login = async ({ access_token, wallet_address, user_id, message }) => {
  const enpoint = `${API_URL}${END_POINT.SIGN_WALLET}`;
  return axios
    .post(enpoint, {
      access_token: access_token,
      wallet_address: wallet_address,
      user_id: user_id,
      message: message,
    })
    .then((response) => response.data);
};

export const loginAsGuest = async () => {
  return login({
    access_token: "guest_login",
  });
};

export const loginSSo = async ({ auth_token, identifier }) => {
  const endpoint = `${API_URL_MCC}${END_POINT.SSO_LOGIN}`;
  return axios
    .post(endpoint, {
      auth_token,
      identifier,
    })
    .then((response) => response.data);
};

export const loginSSOWithWallet = async ({ auth_token, identifier }) => {
  const endpoint = `${API_URL_WALLET}${END_POINT.SSO_LOGIN_WALLET}`;
  return axios
    .post(endpoint, {
      auth_token,
      identifier,
    })
    .then((response) => response.data);
};


export const buildGameServerRedirectUrl = ({ access_token }) => {
  let url = GAME_SERVER_URL;
  if (access_token) {
    url += `?access_token=${access_token}`;
  } else {
    url += `?guest=true`
  }
  return url;
};

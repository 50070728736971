import { createSlice } from '@reduxjs/toolkit';

import {
  getListEventsAction,
  getEventsByIdAction,
  getRelatedEventsAction,
} from './events.action';

const initialState = {
  isLoading: false,
  error: null,
  listEvents: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
  eventDetails: {}
};

const { actions, reducer } = createSlice({
  name: 'events_slice',
  initialState,
  reducers: {
    resetListEventsAction: state => {
      state.listEvents = {
        items: [],
        limit: 10,
        page: 1,
        totalCount: 0,
        totalPage: 0,
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getListEventsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getListEventsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listEvents = {
          items: action.payload.data,
          limit: action.payload.limit,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
          totalPage: action.payload.totalPage,
        }
      })
      .addCase(getListEventsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getEventsByIdAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEventsByIdAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.eventDetails = action.payload.data
      })
      .addCase(getEventsByIdAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getRelatedEventsAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getRelatedEventsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listEvents = {
          items: action.payload.data,
          limit: action.payload.limit,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
          totalPage: action.payload.totalPage,
        }
      })
      .addCase(getRelatedEventsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export { reducer };
export default actions;

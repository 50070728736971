import styled from 'styled-components';

export const StyledDiv = styled.div`
  /* position: absolute; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000069;
  /* z-index: 2; */
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);

  .content {
    padding: 50px;
    border-radius: 10px;

    .message {
      color: white;
    }
  }
`;

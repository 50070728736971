import React, { useState } from 'react'
import classNames from 'classnames';
import { PlusCircleOutlined } from '@ant-design/icons';

import { Images } from '../../theme'
import { CardStyled } from './styled'

export default ({ key, question, answer, type, className, ...props }) => {
  const [isShow, setIsShow] = useState(false)

  return (
    <CardStyled {...props} type={type} question={question} answer={answer} className={classNames({ show: isShow }, className)}>
      <div className='question' data-aos="fade-right" data-aos-duration={!!key ? 5000 * (key + 1) / 10 : 3000}>
        <img src={Images.ICON_AVATAR1} alt='Icon question' />
        <div className='content-text'>
          <div className='content-question'>{question}</div>
          <PlusCircleOutlined onClick={() => setIsShow(true)} />
        </div>
      </div>
      <div className='answer' data-aos="fade-left" data-aos-duration={!!key ? 5000 * (key + 1) / 10 : 3000}>
        <img src={Images.ICON_AVATAR2} alt='Icon answer' />
        <div className='content-text'>{answer}</div>
      </div>
    </CardStyled>
  )
}

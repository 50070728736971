import React from 'react';
import { ModalVerifyEmailProvider } from './components/modal-verify-email';
import { Web3AuthProvider } from './contexts/web3auth';

function Providers({ children }) {
  return (
    <ModalVerifyEmailProvider>
      <Web3AuthProvider>{children}</Web3AuthProvider>
    </ModalVerifyEmailProvider>
  );
}

export default Providers;

import React from 'react'
import classNames from 'classnames'
import { UserOutlined } from '@ant-design/icons';

import { useAppSelector } from '../../../stores';
import Misc from '../../../utils/misc'

/** component */
import Section from '../../section'
import Container from '../../container'
import TitleSection from '../../title-section'
import CardRankingUser from '../../card-ranking-user'
import SkeletonStyled from '../../loading-skeleton'

/** asset */
import { Images } from '../../../theme'
import { Content } from './styled'
import { oneDecimalPlace } from '../../../utils/number';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children, className, ...props }) => {
  const { isLoading, listPointsTop } = useAppSelector(state => state.points);

  const renderListLoading = Array.from({ length: 5 }, (x) => <SkeletonStyled loading={isLoading} active />)
  const renderListPointsTop = Array.isArray(listPointsTop?.items) ? listPointsTop.items.map((user, index) => (
    <div className='item-ranking' key={index} data-aos="fade-up" data-aos-duration={3000 * (index + 1) / 10}>
      <div className='left'>
        <img src={Images.ICON_TOP_RANKING} alt="Icon ranking" />

        <div className='avatar-user'>
          {user.avatarUrl ? <img src={user.avatarUrl} alt="Avatar user" /> : <UserOutlined className='icon-user' />}
        </div>

        <div className='address'>
          {Misc.trimPublicAddress(user.walletAddress, 4)}
          <div className='point'>{oneDecimalPlace(user.point)} Point</div>
        </div>
      </div>
      <div className='right point'>{oneDecimalPlace(user.point)} Point</div>
    </div>
  )) : []

  return (
    <Content {...props} className={classNames(className)}>
      <Section className="home-ranking">
        <Container>
          <div className="body">
            <TitleSection className="title" data-aos="fade-down">RANKING</TitleSection>

            <CardRankingUser title="Top users" className="box-top-ranking" data-aos="fade-up">
              <div className='content-ranking'>
                {isLoading ? renderListLoading : renderListPointsTop}
              </div>
            </CardRankingUser>
          </div>
        </Container>
      </Section>
    </Content>
  )
}

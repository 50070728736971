import { Modal } from 'antd';
import styled from 'styled-components';
export const ModalVerifyEmailStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 10px 0px #00d1ff;
    background-color: #1f2582;
  }

  .ant-modal-body {
    background: linear-gradient(
      270deg,
      rgba(14, 82, 246, 0) 0%,
      rgba(14, 82, 246, 0.22) 8.87%,
      rgba(14, 82, 246, 0.8) 46.6%,
      rgba(49, 106, 217, 0.34) 84.3%,
      rgba(72, 138, 251, 0) 100%
    );
    color: #fff;
    text-align: center;

    .error {
      color: red;
      font-size: 13px;
      font-weight: 500;
    }

    .title {
      font-size: 20px;
      padding-bottom: 20px;
      font-weight: 900;
      text-transform: uppercase;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 500;
      text-transform: none;
      text-align: start;
    }

    input {
      border-radius: 8px;
      background: #051847;
    }

    .action-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 30px;

      .btn-content {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 500;
      }

      .generate {
        color: #2b0c01;

        &.disabled {
          color: #fff;
        }
      }
    }

    @media (max-width: 575px) {
      padding: 16px;
      padding-bottom: 24px;

      .action-box {
        .btn-vr {
          height: 42px !important;
        }

        .btn-content {
          font-size: 13px;
        }
      }

      .sub-title {
        font-size: 13px;
      }
    }
  }
`;

export const ModalVerifyEmail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  .title {
    font-size: 32px;
    font-weight: 400;
  }

  .content-verify {
    font-size: 18px;
    font-weight: 400;
  }
`;

import { createAsyncThunk } from '@reduxjs/toolkit';

import POINTS_API from './points.api';

export const getPointsTopAction = createAsyncThunk(
  'GET_POINTS_TOP',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await POINTS_API.getPointsTop(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message });
    }
  },
);
import { AxiosClient } from './../../../configs/axios/axios';

const apiVersion = process.env.REACT_APP_VERSION;

const EVENTS_API = {
  getListEvents: async (params) => {
    const listEvents = await new AxiosClient().get(`${apiVersion}/events`, { params });

    return listEvents;
  },

  getEventsById: async (params) => {
    const eventDetail = await new AxiosClient().get(`${apiVersion}/events/${params.id}`);

    return eventDetail;
  },

  getRelatedEvents: async (params) => {
    const listEvents = await new AxiosClient().get(`${apiVersion}/events/related-events/${params.id}`, params);

    return listEvents;
  }
};

export default EVENTS_API;

import { createAsyncThunk } from '@reduxjs/toolkit';

import ACCOUNTS_API from './accounts.api';


export const getAccountCheckIpAction = createAsyncThunk(
  'GET_ACCOUNT_CHECK_IP',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await ACCOUNTS_API.getAccountCheckIp();

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

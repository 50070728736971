import styled from "styled-components";

import { Colors } from "../../theme";

export const HrStyled = styled.hr`
  width: 100%;
  border-color: #fff;
  box-shadow: 0px 0px 10px 2px #00A3FF;
  margin: 50px 0;

  @media (max-width: 767.98px) {
    margin: 20px 0;
  }

  @media (min-width: 768px) {
  }
`;

import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

/** component */
import Section from '../../section';
import ButtonImage from '../../button-img';

/** asset */
import { Images, Videos } from '../../../theme';
import { Content } from './styled';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  children,
  className,
  isLogin,
  randomKey,
  handleJoinGame = () => {},
  handleAsGuest = () => {},
  onOpenGenerateVR = () => {},
  onOpenDevice = () => {},
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <Content {...props} className={classNames(className)}>
      <Section className='home-top'>
        <div className='video-top'>
          <video
            id='video'
            autoPlay
            loop
            muted
            playsInline
            type='video/mp4'
            src={Videos.VIDEO_HOME}
          />
        </div>

        <div className='let-play' data-aos='fade-up'>
          <h1 className='title'>enjoy the meta casino city</h1>
          <div className='group-join-game'>
            {isLogin || randomKey ? (
              <>
                <div className='group-blue'>
                  <ButtonImage
                    onClick={isLogin ? handleJoinGame : handleAsGuest}
                  >
                    play on browser
                    <img src={Images.ICON_LET_PLAY} alt='Button let play' />
                  </ButtonImage>
                  {isLogin && (
                    <ButtonImage
                      type='green'
                      onClick={onOpenDevice}
                      height={80}
                    >
                      play on device
                      <img src={Images.ICON_LET_PLAY} alt='Button let play' />
                    </ButtonImage>
                  )}
                </div>

                {/* <ButtonImage
                  onClick={onOpenGenerateVR}
                  type='green'
                  className='group-green'
                  height={80}
                >
                  play on vr
                  <img src={Images.ICON_LET_PLAY} alt='Button let play' />
                </ButtonImage> */}
              </>
            ) : (
              <ButtonImage onClick={() => navigate('/login')} type='blue'>
                Let’s Play
                <img src={Images.ICON_LET_PLAY} alt='Button let play' />
              </ButtonImage>
            )}
          </div>
        </div>
      </Section>
    </Content>
  );
};

import React from 'react';
import {
  Wrapper,
  TooltipWrapper,
  TBody,
  Buildings,
  Building,
  AdsFrames,
  Hours,
  Hour,
  AdsFrameHours,
  AdsFrameHour,
  FrameHourItem,
  BuildingHead,
  AdsFrameHead,
  AdsFrame
} from './styled';
import { Tooltip } from 'antd';
import { useAppSelector } from '../../../../stores';
import moment from 'moment';

const convertString = (string = '') => (string || '').replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toUpperCase() : word.toLowerCase()).replace(/\s+/g, '');

const AdvertiseTable = () => {
  const { advertiseHistoryDetail } = useAppSelector(
    (state) => state.advertise
  );

  const { data } = advertiseHistoryDetail

  return (
    <Wrapper>
      <div className={`box-table`}>
        <div className="table-advertise">
          <TBody>
            <Buildings>
              <BuildingHead>
                Buildings
              </BuildingHead>
              {data.map((m) => (
                <Tooltip
                  placement="top"
                  color="#051847"
                  overlayInnerStyle={{
                    border: "1px solid #156DF280",
                    borderRadius: "10px",
                    width: "fit-content",
                  }}
                  title={`${m?.building?.name}_${m?.building?.building_code}_${m?.advertisement_frame?.code_frame}`}
                  autoAdjustOverflow={true}
                  destroyTooltipOnHide={true}
                  zIndex={999}
                >
                  <Building key={m?.advertisement_frame.id}>
                    {m?.building?.name}_{m?.building?.building_code}_
                    {m?.advertisement_frame?.code_frame}
                  </Building>
                </Tooltip>
              ))}
            </Buildings>
            <AdsFrames>
              <AdsFrameHead>
                Ads Type
              </AdsFrameHead>
              {data.map((m) => (
                <AdsFrame key={m?.advertisement_frame.id}>
                  {convertString(m?.advertisement_frame?.category)}
                </AdsFrame>
              ))}
            </AdsFrames>
            <AdsFrameHours>
              <Hours>
                {[...Array(24).keys()].map((hour) => (
                  <Hour key={hour}> <span className="color-yellow">{hour.toString().padStart(2, '0')}</span></Hour>
                ))}
              </Hours>
              {data.map((m) => (
                <AdsFrameHour key={m?.advertisement_frame.id}>
                  {[...Array(24).keys()].map((hour) => {
                    const frameUsed = m?.prices?.find(
                      (item) => item.hour === hour
                    );

                    const tooltipContent = (
                      <RenderTooltip
                        building_name={m?.building?.name}
                        category={m?.advertisement_frame?.category}
                        price={frameUsed?.price}
                        startDate={`${moment(m?.start_time).format('MM/DD/YYYY')}`}
                        endDate={`${moment(m?.end_time).format('MM/DD/YYYY')}`}
                      />
                    );

                    return (
                      <div key={hour} className="center">
                        {!!frameUsed ? (
                          <Tooltip
                            placement="top"
                            color="#051847"
                            overlayInnerStyle={{
                              border: '1px solid #156DF280',
                              borderRadius: '10px',
                              width: 'fit-content',
                            }}
                            title={tooltipContent}
                            autoAdjustOverflow={true}
                            destroyTooltipOnHide={true}
                            zIndex={999}
                          >
                            <FrameHourItem>
                              <div className={`frame-chosen frame-used`} />
                            </FrameHourItem>
                          </Tooltip>
                        ) : (
                          <FrameHourItem>
                            <div className={`frame-chosen`} />
                          </FrameHourItem>
                        )}
                      </div>
                    );
                  })}
                </AdsFrameHour>
              ))}
            </AdsFrameHours>
            
          </TBody>
        </div>
      </div>
    </Wrapper>
  );
};

const RenderTooltip = ({
  building_name,
  category,
  price,
  startDate,
  endDate,
}) => {
  return (
    <TooltipWrapper>
      <div className="row">
        <span className="title">Position:</span>
        <span className="name">
          {building_name}
        </span>
      </div>
      <div className="row">
        <span className="title">Ads Type:</span>
        <span className="type">
          {category?.toLowerCase()}
        </span>
      </div>
      <div className="row">
        <span className="title">Start date:</span>
        <span
          className="btn-view"
        >
          {startDate}
        </span>
      </div>
      <div className="row">
        <span className="title">End date:</span>
        <span
          className="btn-view"
        >
          {endDate}
        </span>
      </div>
      <div className="row">
        <span className="title">Price:</span>
        <span className="price">
          {price}{' '}MCC Point
        </span>
      </div>
      <div className="row">
        <span className="title">Status:</span>
        <span className="sold">
          Bought by yourself
        </span>
      </div>
    </TooltipWrapper>
  );
};

export default AdvertiseTable;

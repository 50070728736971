import styled from 'styled-components';
import { Images } from '../../theme';

export const CountryBlockedStyled = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background: url(${Images.NOT_FOUND_BG});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .content {
    width: auto;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .logo {
      height: 100px;
      width: 100%;
      padding-top: 30px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .images {
      height: calc(100% - 100px - 120px);
      max-height: 500px;
      padding: 0 0px 30px 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .text {
      color: #fff;
      font-size: 30px;
      letter-spacing: 5px;
      font-weight: bold;
      text-transform: uppercase;
      padding-bottom: 10px;
    }

    .sub-text {
      color: #fff;
      font-size: 20px;
      letter-spacing: 5px;
      padding-bottom: 30px;
    }


    @media (max-width: 575px) {
      .text {
        font-size: 25px;
        padding-bottom: 5px;
        letter-spacing: 3px;
      }

      .sub-text {
        color: #fff;
        font-size: 16px;
        letter-spacing: 0px;
      }

      .images {
        height: auto;
        padding: 0 0px 20px 0px;
      }
    }
  }
`

import { createAsyncThunk } from '@reduxjs/toolkit';

import EVENTS_API from './events.api';

export const getListEventsAction = createAsyncThunk(
  'GET_LIST_EVENTS',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await EVENTS_API.getListEvents(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message });
    }
  },
);

export const getEventsByIdAction = createAsyncThunk(
  'GET_EVENTS_BY_ID',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await EVENTS_API.getEventsById(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message });
    }
  },
);

export const getRelatedEventsAction = createAsyncThunk(
  'GET_RELATED_EVENTS',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await EVENTS_API.getRelatedEvents(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message });
    }
  },
);

import { AxiosClient } from '../../../configs/axios/axios';

const GENERATE_API = {
  generateVRCode: async (accessToken) => {
    return await new AxiosClient().get('/accounts/vr-code', {
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "x-access-token": accessToken,
      },
    });
  },

  generateDevice: async (accessToken) => {
    return await new AxiosClient().get('/accounts/sent-code', {
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "x-access-token": accessToken,
      },
    });
  },
};

export default GENERATE_API;

import styled from "styled-components";

export const Content = styled.div`
  .home-faqs {
    position: relative;
    color: #fff;
    background: rgb(3, 21, 63);
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .wrapper {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;

    .description {
      font-size: 32px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btn-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 30px;
  }

  .question .content-text .anticon-plus-circle {
    display: none;
  }

  .answer {
    display: flex;
  }

  @media (max-width: 767.98px) {
    .body {
      padding-top: 25px;
      padding-bottom: 30px;
    }
    
    .wrapper {
      margin-top: 20px;

      .description {
        font-size: 16px;
        margin-bottom: 0px;
      }
    }

    .btn-actions {
      margin-top: 10px;
    }
  }
  
`;

import React from 'react';
import { CompleteModal } from './styled';
import { Images } from '../../../theme';
import ButtonImage from './../../../components/button-img';

const CompletedModal = ({ isOpenModal, onOK, title, message, ...props }) => {
  return (
    <CompleteModal
      open={isOpenModal}
      closable={false}
      footer={null}
      centered
      {...props}
    >
      <div className="content">
        <img src={Images.SUCCESS} alt="success" />

        <h1>{title}</h1>
        <h3>{message}</h3>

        <ButtonImage
          onClick={() => onOK()}
          type="action"
          height={50}
          textTransform="unset"
          fontWeight={300}
          fontSize={14}
        >
          OK
        </ButtonImage>
      </div>
    </CompleteModal>
  );
};

export default CompletedModal;

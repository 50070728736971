import styled from "styled-components";
import { Images } from '../../theme'

export const CardStyled = styled.div`
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 3px solid #2b6ae6;
  background: #1a227a;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  cursor: pointer;

  .bg-image {
    .background {
      position: absolute;
      top: 0;
      width: 100%;
      aspect-ratio: 16 / 9;
      background-image: url(${props => props.backgroundImage});
      background-color: lightgray;
      background-position: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: blur(8px);
      -webkit-filter: blur(8px);
    }

    img {
      position: relative;
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: contain;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 18px 26px 26px 26px;
  }

  .date-time {
    display: flex;
    flex-direction: column;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    .month {
      font-size: 14px;
    }
    .date {
      font-size: 26px;
      font-weight: 700;
    }
  }
  
  .event-name {
    flex: 1;
    color: #fff;
    word-wrap: break-word;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2; 
    overflow: hidden; 
    text-overflow: ellipsis;
    word-break: break-all;
  }

  ::after {
    content: "";
    background-image: url(${Images.ICON_CURSOR_TRIANGLE});
    position: absolute;
    bottom: 0;
    left: 0;
    width: 16px;
    height: 16px;
    transform: rotate(90deg);;
  }
  ::before {
    content: "";
    background-image: url(${Images.ICON_CURSOR_TRIANGLE});
    z-index: 10;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    height: 16px;
  }
`;

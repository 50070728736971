import styled from 'styled-components';

export const ManagementHeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 92px;
  padding: 30px;
  background: transparent;
  z-index: 100;
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  border-bottom: 1px solid rgba(43, 106, 230, 0.5);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

  .actions,
  .logo {
    cursor: pointer;
  }
  .address-user {
    display: flex;
    /* margin-bottom: 30px; */
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    font-weight: 500;

    .point{
      font-size: 18px;
      font-weight: 300;
    }
    .small-text {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .logo-user {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 2px solid #fff;
      border-radius: 50%;
      overflow: hidden;
    }

    .logo-default {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      box-shadow: 0px 0px 8px 2px #2b37bd;
      background: linear-gradient(180deg, #2773da 0%, #68d8ff 100%),
        radial-gradient(63% 63% at 50% 50%, #2773da 0%, rgba(0, 0, 0, 0) 100%);
      .icon-user {
        margin-left: 8px;
        margin-top: 6px;
        font-size: 32px;
      }
    }
  }

  .avatar-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    padding-top: 10px;
    z-index: 999;

    .bg-1 {
      background: linear-gradient(270deg, #052b86 0%, #03153f 100%);
      border-radius: 10px;
    }

    .bg-2 {
      background: linear-gradient(
        360deg,
        rgba(14, 82, 246, 0) 17.83%,
        rgba(39, 115, 218, 0.8) 138.32%
      );
      border-radius: 10px;
    }
    .bg-3 {
      background: linear-gradient(
        270deg,
        rgba(14, 82, 246, 0) 0%,
        rgba(14, 82, 246, 0.224) 8.87%,
        rgba(14, 82, 246, 0.8) 46.6%,
        rgba(49, 106, 217, 0.336) 84.3%,
        rgba(72, 138, 251, 0) 100%
      );
      border-radius: 10px;
    }

    .content-dropdown {
      filter: drop-shadow(0px 0px 10px #00d1ff);
      border: 1px solid #fff;
      border-radius: 10px;
      max-width: 424px;
      min-width: 214px;
      height: 174px;
      box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
      padding: 20px;

      .public-address {
        // color: #2773da;
        color: #d9e6ff;
        text-align: left;
        font-size: 16px;
        font-weight: 500;

        a {
          color: #d9e6ff;
        }

        &:hover {
          font-weight: 700;
        }
      }

      .divider {
        border-bottom: 1px solid #d9d9d9;
        margin: 8px 0px;
      }

      .logout {
        max-width: 385px;
        min-width: 175px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        text-align: left;
        flex-shrink: 0;
        border-radius: 9px;
        // background: #fff;
        // color: black;
        color: #fff;
        margin-bottom: 10px;
        font-size: 16px;
        cursor: pointer;

        img {
          margin-right: 10px;
        }
        &:hover {
          font-weight: 700;
        }
      }

      .user {
        cursor: default;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      right: 12px;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
    }
  }

  .address-user:hover .avatar-dropdown {
    display: block;
  }

  @media (max-width: 767.98px) {
    width: 100%;
    top: 20px;
    padding: 0 15px;
  }
`;

import queryString from "query-string";

import { useLocation, useNavigate } from "react-router-dom";

import Storage from "../utils/storage";
import Request from "../utils/request";
import { useAppDispatch } from "../stores";
import { getUserInfoAction } from "../stores/screens/auth/auth.action";
import Confirmable from "../components/confirmable";
import { removeLocalLogout } from "../utils/auth";

export const useSSOLogin = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth_token = queryString.parse(search)?.authToken;
  const public_address = queryString.parse(search)?.publicAddress;

  const hookLoginSSO = async () => {
    try {
      const { payload } = await dispatch(
        getUserInfoAction({ accessToken: auth_token })
      );
      const data = payload?.data?.web3_session_data;
      if (data !== null) {
        const session = JSON.parse(data);
        const idSession = session.idSession;
        Storage.set("openlogin_store", idSession);
        Storage.set("SSO_ADAPTER", idSession);
        localStorage.setItem("Web3Auth-cachedAdapter", session?.cachedAdapter);
        Storage.set("ACCESS_TOKEN", auth_token);
        Storage.set("PUBLIC_ADDRESS", public_address);
        Request.setAccessToken(auth_token);
        return { payload, accessToken: auth_token };
      } else {
        removeLocalLogout();
        await Confirmable.open({
          content: "Login session has expired, Please login in again!",
          hideCancelButton: true,
        });
        navigate('/login');
      }
    } catch (e) {}
  };

  return { hookLoginSSO, auth_token, public_address };
};

export default useSSOLogin;

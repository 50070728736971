import styled from 'styled-components';

export const SlideWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;

  .slide {
    position: absolute;
    transition: opacity 0.5s ease;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
  }

  .count {
    width: 60px;
    height: 36px;
    background-color: #2b6ae680;
    color: #fff;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 99;
  }

  .slide.active {
    opacity: 1;
  }

  .item-slide {
    width: 80%;
    height: 80%;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: rgb(241 237 237) 0px 1px 4px, rgb(255 254 254) 0px 0px 0px 5px;
  }

  .btn-prev {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    img {
      fill: red;
    }
  }
  .btn-next {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    img {
    }
  }
`;

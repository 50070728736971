import { AxiosClient } from '../../../configs/axios/axios';
import { LOCAL_STORAGE_KEY } from '../../../constants';
import Storage from '../../../utils/storage';

const apiVersion = process.env.REACT_APP_VERSION;

const StatisticalsApi = {
  getInteractions: async (params) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisement-statisticals/interactions`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
        params,
      }
    );

    return result;
  },
  getBudgetAds: async (params) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisement-statisticals/prices`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
        params,
      }
    );

    return result;
  },
  getBuildings: async () => {
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisement-statisticals/buildings`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
      }
    );

    return result;
  },
};

export default StatisticalsApi;

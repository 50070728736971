import OtpInput from 'react-otp-input';

import { OTPInputStyled } from './styled';

export const OTPInputComponent = ({ otp, setOtp }) => {
  return (
    <OTPInputStyled>
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={4}
        renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} />}
        containerStyle="otp-style"
        inputStyle="input-text"
      />
    </OTPInputStyled>
  );
};

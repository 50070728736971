import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../stores';
import {
  getViewedAction,
  getClickAdsAction,
  getBudgetAdsAction,
} from '../../stores/screens/statistics/statistical.action';
import { Images } from '../../theme';
import actions from '../../stores/screens/statistics/statistical.reducer';
import { numberWithCommas } from '../../utils/number';
import { Wrapper, Container } from './styled';
import Filter from './components/filter';
import Total from './components/total';
import Chart from './components/chart';
import { CHART_TIME, option, TIME_TYPE } from './constant';
import moment from 'moment';

const formatterTooltip = (title, marker, value, type = '') => {
  const styleTitle = `style='font-size:14px; font-weight: 500;'`;
  const styleValue = `style='font-size:16px; font-weight: 400;'`;

  return `<span ${styleTitle}>${title}</span> <br /> ${marker} <span ${styleValue}>${numberWithCommas(
    value
  )} ${type}</span>`;
};

const formatter = (params, timeType, date, type = '') => {
  const data = params[0];
  const title = () => {
    if (timeType === 'DAILY') {
      return `${moment(date).format('MMM DD, YYYY')} ${data.axisValue}`;
    }
    if (timeType === 'WEEKLY') {
      return moment(date).days(`${data.axisValue}`).format('MMM DD, YYYY');
    }

    if (timeType === 'MONTHLY') {
      return moment(date).date(+data.axisValue).format('MMM DD, YYYY');
    }

    return '';
  };
  return formatterTooltip(title(), data.marker, data.value, type);
};
const Statistics = () => {
  const dispatch = useAppDispatch();
  const {
    viewedAdvertisement,
    clickAdsAdvertisement,
    budgetAds,
    filter,
    isLoading,
  } = useAppSelector((state) => state.statistics);

  const renderTimeX = (budgetAds) => {
    switch (budgetAds) {
      case CHART_TIME.MONTHLY:
        const date = moment(filter.date);
        const daysInMonth = date.daysInMonth();
        const dates = [];

        for (let day = 1; day <= daysInMonth; day++) {
          dates.push(day);
        }
        return dates;
      default:
        return TIME_TYPE[budgetAds];
    }
  };

  const viewOption = useMemo(() => {
    return {
      ...option,
      xAxis: {
        ...option.xAxis,
        data: renderTimeX(viewedAdvertisement.timeType),
      },
      series: [
        {
          data: viewedAdvertisement.interactions.map((m) => ({
            value: isLoading ? 0 : m || 0,
            itemStyle: {
              color: '#156DF2',
              borderRadius: 4,
            },
          })),
          type: 'bar',
          barWidth: viewedAdvertisement.timeType !== 'WEEKLY' ? '35%' : '25%',
        },
      ],
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#3C4254',
        borderColor: 'rgba(117, 123, 140, 1)',
        textStyle: {
          color: '#ffffff',
          fontFamily: 'Noto Sans JP',
        },
        formatter: (params) =>
          formatter(params, viewedAdvertisement.timeType, filter.date),
        padding: 16,
      },
    };
  }, [viewedAdvertisement.interactions]);

  const budgetOption = useMemo(() => {
    return {
      ...option,
      xAxis: {
        ...option.xAxis,
        data: renderTimeX(budgetAds.timeType),
      },
      series: [
        {
          data: budgetAds.prices.map((m) => ({
            value: isLoading ? 0 : m || 0,
            itemStyle: {
              color: '#FA2256',
              borderRadius: 4,
            },
          })),
          type: 'bar',
          barWidth: budgetAds.timeType !== 'WEEKLY' ? '35%' : '25%',
        },
      ],
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#3C4254',
        borderColor: 'rgba(117, 123, 140, 1)',
        textStyle: {
          color: '#ffffff',
          fontFamily: 'Noto Sans JP',
        },
        formatter: (params) =>
          formatter(params, budgetAds.timeType, filter.date, 'MCC point'),
        padding: 16,
      },
    };
  }, [budgetAds.prices]);

  const clickOption = useMemo(() => {
    return {
      ...option,
      xAxis: {
        ...option.xAxis,
        data: renderTimeX(clickAdsAdvertisement.timeType),
      },
      series: [
        {
          data: clickAdsAdvertisement.interactions.map((m) => ({
            value: isLoading ? 0 : m || 0,
            itemStyle: {
              color: '#30E0A1',
            },
          })),
          type: 'line',
          symbol: 'circle',
          symbolSize: 10,
          showSymbol: false,
          selectedMode: 'single',
          lineStyle: {
            color: '#30E0A1',
            width: 4,
            shadowBlur: 10,
            shadowColor: 'rgba(48, 224, 161, 1)',
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            opacity: 1,
          },
          z: 1,
        },
      ],
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#3C4254',
        borderColor: 'rgba(117, 123, 140, 1)',
        textStyle: {
          color: '#ffffff',
          fontFamily: 'Noto Sans JP',
        },
        formatter: (params) =>
          formatter(params, clickAdsAdvertisement.timeType, filter.date, ''),
        padding: 16,
      },
    };
  }, [clickAdsAdvertisement.interactions]);

  useEffect(() => {
    if (filter.date) {
      dispatch(
        getViewedAction({
          type: 'VIEW',
          time: viewedAdvertisement.timeType,
          ...filter,
        })
      );
    }
  }, [
    filter.advertisementId,
    filter.building,
    filter.date,
    filter.isChange,
    viewedAdvertisement.timeType,
  ]);

  useEffect(() => {
    if (filter.date) {
      dispatch(
        getClickAdsAction({
          type: 'CLICK',
          time: clickAdsAdvertisement.timeType,
          ...filter,
        })
      );
    }
  }, [
    filter.advertisementId,
    filter.building,
    filter.date,
    filter.isChange,
    clickAdsAdvertisement.timeType,
  ]);

  useEffect(() => {
    if (filter.date) {
      dispatch(getBudgetAdsAction({ time: budgetAds.timeType, ...filter }));
    }
  }, [
    filter.advertisementId,
    filter.building,
    filter.date,
    filter.isChange,
    budgetAds.timeType,
  ]);

  useEffect(() => {
    return () => {
      dispatch(actions.setBudgetAdsTimeType('DAILY'));
      dispatch(actions.setViewedAdsTimeType('DAILY'));
      dispatch(actions.setClickAdsTimeType('DAILY'));
    };
  }, []);

  return (
    <Container>
      <div className="dp-flex justify-between title">
        <h2>Statistics</h2>
      </div>
      <Wrapper>
        <Filter />

        <h3 className="title">Viewed the advertisement</h3>
        <Total
          src={Images.VIEWED}
          title="Total View Ads"
          value={numberWithCommas(viewedAdvertisement.total)}
        />
        <Chart
          title="People"
          option={viewOption}
          onChangeTab={(value) => dispatch(actions.setViewedAdsTimeType(value))}
          value={viewedAdvertisement.timeType}
        />

        <h3 className="title">Number of ads clicks</h3>
        <Total
          src={Images.CLICK}
          title="Total Click Ads"
          value={numberWithCommas(clickAdsAdvertisement.total)}
        />
        <Chart
          title="Click"
          value={clickAdsAdvertisement.timeType}
          option={clickOption}
          onChangeTab={(value) => dispatch(actions.setClickAdsTimeType(value))}
        />

        <h3 className="title">Budget for ads</h3>
        <Total
          src={Images.BUDGET}
          title="Total Budget Ads"
          value={`${numberWithCommas(budgetAds.total)} MCC point`}
        />
        <Chart
          value={budgetAds.timeType}
          title="MCC point"
          option={budgetOption}
          onChangeTab={(value) => dispatch(actions.setBudgetAdsTimeType(value))}
        />
      </Wrapper>
    </Container>
  );
};

export default Statistics;

import styled from 'styled-components';

export const FilterWrapper = styled.div`
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;

  &.mt-30 {
    margin-top: 30px;
  }

  .select {
    background: transparent;
    width: 100%;
    height: 40px;
    border-color: #156df2;
    margin-right: 16px;
    color: #fff;

    .ant-select-selector {
      background-color: unset;
      height: 40px;
      border-color: #156df2;
      color: #fff;
      display: flex;
      align-items: center;
    }

    .ant-select-clear {
      background: transparent;
      margin-right: 25px;
    }

    &.h-56 {
      height: 56px;

      .ant-select-selector {
        height: 56px;
      }
    }
  }

  .date-picker {
    background: transparent;
    border-color: #156df2;
    height: 40px;

    &.h-56 {
      height: 56px;
    }

    input {
      color: #fff;
    }

    .ant-picker-clear {
      background: transparent;
      margin-right: 30px;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    width: 200px;

    &.building {
      width: 250px;
    }

    &.w-50 {
      width: 48%;
    }

    &.w-30 {
      width: 27%;
    }
  }

  .btn-search {
    margin-top: 25px;

    &.btn-search-2{
      margin-top: auto;
    }
  }

  .error {
    color: red;
    font-size: 12px;
    margin: 0;
    margin-top: 2px;
  }

  .required {
    color: red;
  }
`;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useSSOLogin from '../../../hooks/ssoLogin';
import { Images } from '../../../theme';
import Misc from '../../../utils/misc';
import { ManagementHeaderStyled } from './styled';

import { UserOutlined } from '@ant-design/icons';
import { CHAIN_LIST } from '../../../constants/chains';
import { useWeb3Auth } from '../../../contexts/web3auth';
import { oneDecimalPlace } from '../../../utils/number';

const ManagementHeader = ({ accessToken, publicAddress, userInfo }) => {
  const navigate = useNavigate();
  const { public_address } = useSSOLogin();
  const { handleLogout } = useWeb3Auth();

  const [balanceUser, setBalanceUser] = useState(0);

  useEffect(() => {
    if (userInfo?.balance) {
      setBalanceUser(userInfo?.balance);
    }
  }, [userInfo]);

  return (
    <ManagementHeaderStyled>
      <div onClick={() => navigate('/')} className="logo">
        <img src={Images.LOGO_HEADER} alt="Logo MCC" />
      </div>
      <div className="address-user">
        <div>
          <span className="point">
            {oneDecimalPlace(balanceUser)}
          </span>{' '}
          <span className="small-text">MCC Point</span>
        </div>
        <div className="logo-user">
          {userInfo?.avatar_url ? (
            <img
              width={48}
              height={48}
              src={userInfo?.avatar_url}
              alt="logo-user"
            />
          ) : (
            <div className="logo-default">
              <UserOutlined className="icon-user" />
            </div>
          )}
          <div className="avatar-dropdown">
            <div className="bg-1">
              <div className="bg-2">
                <div className="bg-3">
                  <div className="content-dropdown">
                    <div className="public-address">
                      <a
                        href={`${CHAIN_LIST[1].blockExplorerUrls}address/${
                          publicAddress || public_address
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {Misc.trimPublicAddress(
                          publicAddress || public_address,
                          6
                        )}
                      </a>
                    </div>
                    <div className="divider"></div>
                    <div
                      className="logout user"
                    >
                      <img src={Images.ICON_SETTING} alt="icon-setting"/>
                      {userInfo?.user_name.length > 30 ? userInfo.user_name.substring(0, 30) + '...' : (userInfo?.user_name || '...')}
                    </div>
                    <div
                      className="logout"
                      onClick={() => {
                        navigate('/management');
                      }}
                    >
                      <img src={Images.ICON_AD} alt="icon-setting"/>
                      Advertise
                    </div>
                    <div
                      className="logout"
                      onClick={() => {
                        handleLogout();
                        navigate('/');
                      }}
                    >
                      <img src={Images.ICON_LOGOUT} alt="icon-logout"/>
                      Log Out
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ManagementHeaderStyled>
  );
};

export default ManagementHeader;

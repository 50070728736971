import React from 'react';

import { ModalStyled } from './styled';

const BaseModal = ({ children, ...props }) => (
  <ModalStyled centered footer={null} {...props}>
    {children}
  </ModalStyled>
);
export default BaseModal;

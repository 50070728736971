import React from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { notification } from "antd";

import { loginAction } from "../../stores/screens/auth/auth.action";
import { useAppDispatch } from "../../stores";
import { Images, Videos } from "../../theme";
import Storage from "../../utils/storage";
import useJoinGame from "../../hooks/joinGame";
import { useWeb3Auth } from "../../contexts/web3auth";

import CardLogin from "../../components/card-login";
import ButtonImage from "../../components/button-img";
import Section from "../../components/section";
import Confirmable from "../../components/confirmable";
import Header from "../../layouts/Header";
import MaskLoading from "../../components/mask-loading";
import useModalInvalidEmail from "../../hooks/useModalInvalidEmail";

import { Content, EmailInValidStyled } from "./styled";
import Misc from "../../utils/misc";
import { openMetamaskBrowser } from "../../utils/web3";

export default function Login({ children, className, ...props }) {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleJoinGame } = useJoinGame();
  const {
    handleConnectWallet,
    handleSocialConnect,
    setUserId,
    accessToken,
    currentAccount,
    getIdToken,
    getAccounts,
    getUserInfo,
    getAppPubKey,
    handleLogout,
    userInfo,
  } = useWeb3Auth();
  const { open: openModalInvalidEmail } = useModalInvalidEmail();

  const loginWallet = async () => {
    try { 
      if (Misc.isMobile && !window?.ethereum) {
        if (Misc.isMobile && Misc.checkNotMetamaskBrowser()) {
          const ok = await Confirmable.open({
            content:
              "The feature is not available. Do you want to open Metamask Browser to use this feature?",
          });
          if (ok) {
            openMetamaskBrowser();
          } else {
            const error = { code: 4001 };
            throw error;
          }
        }
        
      } else if (!Misc.isMobile && !window?.ethereum) {
        return notification.error({
          message: 'Missing Metamask extension',
          duration: 5,
        });
      }
      MaskLoading.open({});
      await handleConnectWallet();
      const idToken = await getIdToken();
      const accounts = await getAccounts();
      const idSession = Storage.get("openlogin_store");
      const cachedAdapter = localStorage.getItem("Web3Auth-cachedAdapter");
      const dataObj = { idSession, cachedAdapter };
      const web3SessionData = JSON.stringify(dataObj);
      const { payload } = await dispatch(
        loginAction({
          external_wallet: true,
          id_token: idToken,
          public_address: accounts?.[0],
          referral_code: queryString.parse(search)?.ref || undefined,
          web3_session_data: web3SessionData,
        })
      );
      if (payload?.status) {
        MaskLoading.close();
        Storage.set("IS_CHECK", payload?.data?.access_token);
        if (payload?.data?.is_inactive) {
          Storage.set("USER_ID", payload?.data?.user_id);
          setUserId(payload?.data?.user_id);
          openModalInvalidEmail();
        } else {
          const ok = await Confirmable.open({
            content: "Registration successful",
            hideCancelButton: true,
            isShowIconSuccess: true,
            acceptButtonText: "Go to login",
            hideIconClose: true,
            onLogout: handleLogout
          });

          if (ok) {
            handleLogout();
            navigate("/login");
          }
        }
      } else {
        MaskLoading.close();
        if (payload?.error !== "ERR_USER_BLOCKED") {
          if (payload?.error === "BAD_REQUEST") {
            const ok = await Confirmable.open({
              content: (
                <EmailInValidStyled>
                  Wallet <span>{accounts?.[0]}</span> already exists.
                </EmailInValidStyled>
              ),
              hideCancelButton: true,
              hideIconClose: true,
              width: 475,
              acceptButtonText: 'Go to login',
              isShowIconExclamation: true,
              onLogout: handleLogout
            });
            if (ok) {
              handleLogout();
              navigate("/login");
            }
          } else {
            const ok = await Confirmable.open({
              content: payload?.message,
              hideCancelButton: true,
            });
            if (ok) {
              handleLogout();
              window.location.reload();
            }
          }
        }
      }
      MaskLoading.close();
    } catch (error) {
      MaskLoading.close(); 
      if(error?.code !== 4001 && error?.code !== 5111) {
        const ok = await Confirmable.open({
          content: error?.message || "Something went wrong",
          hideCancelButton: true,
        });
        if (ok && !error?.keepLogin) {
          handleLogout();
          window.location.reload();
        }
      } else {
        handleLogout();
      }
    }
  };

  const loginSocial = async () => {
    try {
      const provider = await handleSocialConnect();
      if( provider ) {
        MaskLoading.open({});
      }
      const userInfo = await getUserInfo();
      const appPubKey = await getAppPubKey();
      const accounts = await getAccounts(provider);
      const idSession = Storage.get("openlogin_store");
      const cachedAdapter = localStorage.getItem("Web3Auth-cachedAdapter");
      const dataObj = { idSession, cachedAdapter };
      const web3SessionData = JSON.stringify(dataObj);
      const { payload } = await dispatch(
        loginAction({
          aggregate_verifier: userInfo?.aggregateVerifier,
          app_pub_key: appPubKey,
          public_address: accounts?.[0],
          id_token: userInfo?.idToken,
          verifier_id: userInfo?.verifierId,
          referral_code: queryString.parse(search)?.ref || undefined,
          web3_session_data: web3SessionData,
        })
      );
      if (payload.status) {
        MaskLoading.close();
        Storage.set("IS_CHECK", payload?.data?.access_token);
        if (payload?.data?.is_inactive) {
          Storage.set("USER_ID", payload?.data?.user_id);
          setUserId(payload?.data?.user_id);
          openModalInvalidEmail();
        } else {
          const ok = await Confirmable.open({
            content: "Registration successful",
            hideCancelButton: true,
            hideIconClose: true,
            isShowIconSuccess: true,
            acceptButtonText: "Go to login",
            onLogout: handleLogout
          });

          if (ok) {
            handleLogout();
            navigate(search?.includes('source') ? `/login${search}` : "/login");
          }
        }
      } else {
        MaskLoading.close();
        if (payload?.error !== "ERR_USER_BLOCKED") {
          if (payload?.error === "BAD_REQUEST") {
            const ok = await Confirmable.open({
              content: (
                <EmailInValidStyled>
                  Email <span>{userInfo?.verifierId}</span> already exists.
                </EmailInValidStyled>
              ),
              hideCancelButton: true,
              hideIconClose: true,
              isShowIconExclamation: true,
              acceptButtonText: "Go to login",
              onLogout: handleLogout
            });
            if (ok) {
              handleLogout();
              navigate(search?.includes('source') ? `/login${search}` : "/login");
            }
          } else {
            const ok = await Confirmable.open({
              content: payload?.message,
              hideCancelButton: true,
            });
            if (ok) {
              handleLogout();
              window.location.reload();
            }
          }
        }
      }
      MaskLoading.close();
    } catch (error) {
      MaskLoading.close();
      if (error?.message !== "User closed the modal") {
        const ok = await Confirmable.open({
          content: error?.message?.includes("+")
            ? error?.message?.replace(/\+/g, " ")
            : error?.message || "Something went wrong",
          hideCancelButton: true,
        });
        if (ok) {
          handleLogout();
          window.location.reload();
        }
      }
    }
  };

  const formLogin = () => {
    return (
      <>
        <div className="logo-login">
          {/* <img src={Images.LOGO_LOGIN} alt="Logo MCC" /> */}
          <div className="title">Register an Account</div>
        </div>
        <div className="form-login">
          <CardLogin
            title="Continue with email"
            button={
              <ButtonImage
                height={58}
                fontSize={14}
                fontWeight={700}
                textTransform={"initial"}
                onClick={loginSocial}
                type="blue"
              >
                Connect your Email
              </ButtonImage>
            }
            image={Images.ICON_EMAIL}
            positionImage="left"
          />
          {/* {!search?.includes('source') && (
            <CardLogin
              title="Continue with wallet"
              button={
                <ButtonImage
                  height={58}
                  fontSize={14}
                  fontWeight={700}
                  textTransform={'initial'}
                  onClick={loginWallet}
                  type="green"
                >
                  Connect your MetaMask
                </ButtonImage>
              }
              image={Images.METAMASK_ICON}
              positionImage="left"
            />
          )} */}
        </div>
      </>
    );
  };

  const formLogged = () => {
    return (
      <div className="form-logged">
        <div onClick={handleJoinGame} className="start-game"></div>
      </div>
    );
  };

  return (
    <Content {...props} className={classNames(className)}>
      <Header
        accessToken={accessToken}
        publicAddress={currentAccount}
        userInfo={userInfo}
        onLogout={handleLogout}
      />
      <Section className="login-top">
        <div className="video-top">
          <video
            id="video"
            autoPlay
            loop
            muted
            playsInline
            type="video/mp4"
            src={Videos.VIDEO_HOME}
          />
        </div>

        {!(accessToken && Storage.get("ACCESS_TOKEN"))
          ? formLogin()
          : formLogged()}
      </Section>
    </Content>
  );
}

import { createSlice } from '@reduxjs/toolkit';

import {
  getViewedAction,
  getClickAdsAction,
  getBudgetAdsAction,
  getBuildingsAction
} from './statistical.action';

// const chunkData = (array) => {
//   const chunkArray = array.reduce((resultArray, item, index) => {
//     const chunkIndex = Math.floor(index / 4)
//     if (!resultArray[chunkIndex]) {
//       resultArray[chunkIndex] = [] // start a new chunk
//     }
//     resultArray[chunkIndex].push(item)
//     return resultArray
//   }, []);

//   return chunkArray.map((chunk) => chunk.reduce((accumulator, currentValue) => accumulator + currentValue, 0));
// };

const initialState = {
  isLoading: false,
  error: {},
  viewedAdvertisement: {
    timeType: 'DAILY',
    interactions: [],
    total: 0
  },
  clickAdsAdvertisement: {
    timeType: 'DAILY',
    interactions: [],
    total: 0
  },
  budgetAds: {
    timeType: 'DAILY',
    prices: [],
    total: 0
  },
  buildings: [],
  filter: {
    advertisementId: null,
    building: null,
    date: null,
    isChange: false
  }
};

const { actions, reducer } = createSlice({
  name: 'statistics_slice',
  initialState,
  reducers: {
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    setViewedAdsTimeType: (state, { payload }) => {
      state.viewedAdvertisement.timeType = payload;

    },
    setClickAdsTimeType: (state, { payload }) => {
      state.clickAdsAdvertisement.timeType = payload;
    },
    setBudgetAdsTimeType: (state, { payload }) => {
      state.budgetAds.timeType = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getViewedAction.pending, (state, action) => {
        state.isLoading = true;
        state.viewedAdvertisement.interactions = []
      })
      .addCase(getViewedAction.fulfilled, (state, action) => {
        state.isLoading = false;
        const { data } = action.payload;

        const dataMapping = data.interactions.map((m) => ( m.count))
        state.viewedAdvertisement.interactions = dataMapping;
        state.viewedAdvertisement.total = data.total;
      })
      .addCase(getViewedAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getClickAdsAction.pending, (state, action) => {
        state.isLoading = true;
        state.clickAdsAdvertisement.interactions = []
      })
      .addCase(getClickAdsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        const { data } = action.payload;

        const dataMapping = data.interactions.map((m) => ( m.count))
        state.clickAdsAdvertisement.interactions = dataMapping;
        state.clickAdsAdvertisement.total = data.total;

      })
      .addCase(getClickAdsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getBudgetAdsAction.pending, (state, action) => {
        state.isLoading = true;
        state.budgetAds.interactions = []
      })
      .addCase(getBudgetAdsAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        const dataMapping = data.prices.map((m) => ( m.price))
        state.budgetAds.prices = dataMapping;
        state.budgetAds.total = data.total;
        state.isLoading = false;
      })
      .addCase(getBudgetAdsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getBuildingsAction.pending, (state, action) => {
        state.buildings = []
      })
      .addCase(getBuildingsAction.fulfilled, (state, action) => {
        state.buildings = action.payload.data;
      })
      .addCase(getBuildingsAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      
  },
});
export { reducer };
export default actions;

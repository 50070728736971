import styled from "styled-components";
import { Button } from "antd";
import "antd/es/button/style/css";

import { Colors } from "../../theme";

export const ButtonStyled = styled(Button)`
  &.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border-radius: 4px;
    transition: background-color 0.2s;
    height: 40px;
    font-weight: bold !important;

    &.ant-btn-primary {
      background-color: ${({ theme }) => Colors.alpha(theme.primary, 1)};
      color: white;
      border: none;

      &:hover {
        background-color: ${({ theme }) => Colors.alpha(theme.primary, 1)};
        color: white;
      }

      &:active {
        background-color: ${({ theme }) => Colors.alpha(theme.primary, 1)};
      }

      &:focus {
        background-color: ${({ theme }) => Colors.alpha(theme.primary, 1)};
        color: white;

        &:hover {
          background-color: ${({ theme }) => Colors.alpha(theme.primary, 1)};
        }

        &:active {
          background-color: ${({ theme }) => Colors.alpha(theme.primary, 1)};
        }
      }
    }

    &.ant-btn-warning {
      background-color: #ffbf43;
      color: #452e00;
      border: none;

      &:hover {
        background-color: #ffbf43;
        color: #452e00;
      }

      &:active {
        background-color: #ffbf43;
      }

      &:focus {
        background-color: #ffbf43;
        color: #452e00;

        &:hover {
          background-color: #ffbf43;
        }

        &:active {
          background-color: #ffbf43;
        }
      }
    }

    &.ant-btn-basic {
      background-color: #e1e1e1;
      color: #3c492f;
      border: none;

      &:hover {
        background-color: #e1e1e1;
        color: #3c492f;
        border: none;
      }

      &:active {
        background-color: #e1e1e1;
        color: #3c492f;
        border: none;
      }

      &:focus {
        background-color: #e1e1e1;
        color: #3c492f;
        border: none;

        &:hover {
          background-color: #e1e1e1;
          color: #3c492f;
          border: none;
        }

        &:active {
          background-color: #e1e1e1;
          color: #3c492f;
          border: none;
        }
      }
    }

    &:disabled,
    &:disabled:hover {
      background: ${({ theme }) => Colors.alpha(theme.primary, 0.5)};
    }

    &.ant-btn-sm {
      padding: 0 10px;
      font-size: 13px;
    }
  }
`;

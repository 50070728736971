import styled from 'styled-components';
import { Modal } from 'antd';

export const CompleteModal = styled(Modal)`
  background: linear-gradient(270deg, #052b86 0%, #03153f 100%);
  border-radius: 20px;
  width: 500px !important;

  .ant-modal-content {
    color: #fff;
    background: transparent;
    padding: 50px 30px 30px;
  }

  .ant-modal-body{
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-bottom: 30px;
    }

    h1 {
      margin-bottom: 10px;
      font-size: 32px;
    }

    h3 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
    }
  }
`;

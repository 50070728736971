import React from 'react';

import { Images } from '../../theme'
import { CardRankingUserStyled } from './styled';

const CardRankingUser = ({ title, className, children, ...props }) => {
  return (
    <CardRankingUserStyled backgroundImage={Images.BG_RANKING_USER} className={className}>
      <div className='bg-image'>
        <div className='header'>
          <img src={Images.BG_HEADER_RANKING} alt="Header top tank" />
          <div className='title-ranking'>{title}</div>
        </div>
        {children}
      </div>
    </CardRankingUserStyled>
  );
};
export default CardRankingUser;

import styled from "styled-components";
import { Colors } from "../../../theme";

export const Content = styled.div`
  .home-communities {
    position: relative;
    background: rgb(3, 21, 63);
    background: linear-gradient(
      90deg,
      rgba(3, 21, 63, 1) 0%,
      rgba(5, 43, 134, 1) 100%
    );
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
  }

  .content {
    position: relative;
    width: 100%;
    overflow: hidden;

    img.bg-img {
      width: 100%;
    }

    .top {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1300px;
      margin: 0 auto;
      
      .description {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
        padding: 0px 50px;
      }

      .btn-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
      }
    }
  }
  

  @media (max-width: 767.98px) {
    .home-communities {
      .body {
        padding-top: 25px;
        padding-bottom: 0px;
      }

      .content {
        overflow: initial;
        height: auto;
        margin-top: 30px;

        img.bg-img {
          display: none;
        }

        img.bg-img_mb {
          width: 100%;
        }

        .top {
          position: relative;
          top: 0px;
          bottom: 0px;

          .description {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 30px;
            padding: 0px 15px;
          }

          .btn-actions {
            gap: 15px;
            margin-bottom: 30px;

            .btn-image {
              height: 45px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .home-communities {
      .body .content img.bg-img_mb {
        display: none;
      }
    }
  }
`;

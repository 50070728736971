import React from 'react';

import { NotFoundStyled } from './styled';
import { Images } from '../../theme';
import Button from '../../components/button-img';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <NotFoundStyled>
      <div className='content'>
        <div className='images'>
          <img src={Images.NOT_FOUND_IMG} alt='Not Found' />
        </div>
        <div className='text'>Opps! Page not found</div>
        <Link to={'/'}>
          <Button className='text-dark' src={Images.GENERATE_BTN} height={50}>
            Back to Home
          </Button>
        </Link>
      </div>
    </NotFoundStyled>
  );
};
export default NotFound;

import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const FilterWrapper = styled.div`
  margin-bottom: 10px;
  flex-wrap: wrap;
  /* gap: 20px; */
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 16px;
  &.mt-30 {
    margin-top: 30px;
  }
  &.mt-10 {
    margin-top: 10px;
  }
  &.gap-0-20 {
   gap: 0px 20px;
  }

  .select {
    background: transparent;
    width: 100%;
    height: 40px;
    border-color: #156df2;
    margin-right: 16px;
    color: #fff;

    .ant-select-selector {
      background: rgba(0, 0, 0, 0.21);
      height: 40px;
      border-color: #156df2;
      color: #fff;
      display: flex;
      align-items: center;
    }

    

    .ant-select-clear {
      background: transparent;
      margin-right: 25px;
    }

    &.h-56 {
      height: 40px;

      .ant-select-selector {
        height: 40px;
      }
    }
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: rgba(186, 186, 186, 0.21);
  }
  .ant-select-multiple .ant-select-selection-item{
    background-color: #2A3653;
    border: none !important;
  }
  .ant-select-selection-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
  }
  .ant-select-selection-overflow{
    overflow: hidden;
    max-width: 100%;
    flex-wrap: nowrap;
  }

  .building-select{
   .ant-select-clear {
      margin-right: 0px;
    }
  }
  .date-picker {
    background: rgba(0, 0, 0, 0.21);
    border-color: #156df2;
    height: 40px;

    &.h-56 {
      height: 40px;
    }

    input {
      color: #fff;
    }

    .ant-picker-clear {
      background: transparent;
      margin-right: 30px;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    /* width: 200px; */
    margin-bottom: 12px;

    &.w-50 {
      width: 48%;
    }

    &.w-30 {
      width: 32%;
    }
    &.w-27 {
      width: 27%;
    }
    &.w-25 {
      width: 25%;
    }
  }

  .btn-search {
    margin-top: 25px;
  }

  .error {
    color: red;
    font-size: 12px;
    margin: 0;
    margin-top: 2px;
  }

  .required {
    color: red;
  }
`;

export const AdsType = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background: rgba(186, 186, 186, 0.21);
  border-color: #156df2;
  margin-right: 16px;
  color: #fff;
  border: 1px solid #156df2;
  padding: 0 11px;
  align-items: center;
`;

export const ButtonViewBuilding = styled(Button)`
  margin: 0 0 0 auto;
  padding: 0;
  height: 25px;
  color: #FF9A00;
  &[disabled] {
    color: #757B8C;
    &:hover{
     color: #757B8C;
    }
  }
  &:hover, &:focus{
     color: #FF9A00;
  }
  span{
    text-decoration: underline;
  }
}
`;

export const ImageBuilding = styled.img`
  height: auto;
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : "0px")};
  left: ${({ left }) => (left ? `${left}px` : "0px")};
  z-index: ${({ index }) => (index ? `${index}` : "")};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
`

export const PreviewModalWrapper = styled(Modal)`
  background: transparent;
  width: ${({ width }) => (width ? `${width}px` : "800px")};
  height: ${({ height }) => (height ? `${height}px` : "600px")};
  
  .ant-modal-content {
    .ant-modal-body {
      padding: 0px;
    }
    .ant-modal-close-x {
      /* display: none; */
    }
  }
  .ant-modal-content {
    background: transparent;
    border-radius: 20px;
    box-shadow: unset;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
  }

  .ant-modal-close {
    top: -30px;
    right: -50px;

    .ant-modal-close-x {
      width: 44px;
      height: 44px;
      line-height: 30px;
    }
  }

  video,
  .preview {
    border-radius: 10px;
  }
  .preview {
    box-shadow: rgb(241 237 237) 0px 1px 4px, rgb(255 254 254) 0px 0px 0px 5px;
    background-color: #000;
  }
  .preview-modal {
    width: ${({ width }) => (width ? `${width}px` : "800px")};
    height: ${({ height }) => (height ? `${height}px` : "600px")};
  }

  .anticon {
    color: #fff;
  }
`;

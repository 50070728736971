import styled from "styled-components";

export const CardStyled = styled.div`
  position: relative;
  width: 284px;
  max-width: 100%;
  height: 391px;
  background: transparent;

  .bg-card {
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .wrapper {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;

    .title {
      display: flex;
      justify-content: center;
      padding-top: 40px;
      font-size: 18px;
      font-weight: 700;
    }

    .content {
      width: 100%;
      height: 57%;
      display: flex;
      align-items: center;

      .image {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .button {
      display: flex;
      justify-content: center;
    }

    .sub-title {
      font-size: 10px;
      font-weight: 700;
      width: 60%;
      text-align: center;
    }
  }

  @media screen and (max-width: 968px) {
    height: 350px;
  }

  @media screen and (max-height: 850px) {
    height: 350px;
  }

  @media screen and (max-width: 968px) and (min-width: 575px) {
    height: 264px;

    .wrapper {
      .title {
        padding-top: 24px;
        font-size: 14px;
      }
      .content {
        height: 53%;
        .image {
          img {
            width: 100px;
            height: 100px;
          }
        }
      }

      .button {
        .btn-image {
          height: 50px;

          img {
            width: 142px;
          }
        }
      }

      .sub-title {
        width: 70%;
      }
    }
  }

  @media (max-width: 575px) {
    width: 185px;
    height: 260px;

    .wrapper {
      .title {
        font-size: 14px;
      }
      .content {
        height: 45%;
        .image {
          img {
            width: 100px;
            height: 100px;
          }
        }
      }

      .button {
        .btn-image {
          height: 50px;

          img {
            width: 142px;
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';
export const CardRankingUserStyled = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .bg-image {
    position: relative;
    width: 1000px;
    height: auto;
    max-width: 100%;
    /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
    background-image: url(${props => props.backgroundImage});
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      height: 80px;
      width: auto;
    }

    .title-ranking {
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      font-size: 26px;
      font-weight: 900;
    }
  }

  @media (max-width: 767.98px) {
    .header {
      > img {
        width: calc(100% - 40px);
        height: auto;
        min-height: 50px;
      }

      .title-ranking {
        font-size: 16px;
      }
    }
  }
`;

import styled from 'styled-components';

export const Filter = styled.div`
  margin-bottom: 20px;
  /* flex-wrap: wrap; */
  gap: 16px;
  align-items: flex-start;

  .select {
    background: rgba(4, 19, 56, 1);

    /* max-width: 180px; */
    /* width: 100%; */
    height: 40px;
    border-color: #156df2;
    color: #fff;

    .ant-select-selector {
      background-color: unset;
      height: 40px;
      border-color: #156df2;
      color: #fff;
      /* display: flex; */
      align-items: center;
    }

    .ant-select-clear {
      background: transparent;
      margin-right: 25px;
    }

    &.h-56 {
      height: 56px;

      .ant-select-selector {
        height: 56px;
      }
    }
  }

  .date-picker {
    background: rgba(4, 19, 56, 1);
    border-color: #156df2;
    height: 40px;

    &.h-56 {
      height: 56px;
    }

    input {
      color: #fff;
    }

    .ant-picker-clear {
      background: transparent;
      margin-right: 30px;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    width: 180px;
  }

  .btn-search{
    margin-top: auto;
  }
`;

import { createSlice } from '@reduxjs/toolkit';

import {
  createAdvertiseAction,
  getDetailAdvertiseAction,
  listBuildingAction,
  tableAdsBookingAction,
  tablePricingPlanAction,
  getHistoriesAdvertiseAction,
  getHistoriesAdvertiseDetailAction
} from './advertise.action';

const initialState = {
  isLoading: false,
  error: {},
  tablePricing: [],
  tableAdvertise: [],
  tableAdvertiseLoading: false,
  listBuilding: [],
  detailAdvertise: [],
  advertiseHistories: {
    data: [],
    paginate: {
      limit: 8,
      page: 1,
      totalPage: 1,
      totalCount: 0
    }
  },
  advertiseHistoryDetail: {
    data: [],
    fee_percent: 0,
    discount_percent: 0,
    advertisement_name: ''
  },
  feePercent: 0,
  discountPercent: 0,
  daySelected: 0,
  paramsSearch: {}
};

const { actions, reducer } = createSlice({
  name: 'advertise_slice',
  initialState,
  reducers: {
    resetTableAdvertise: (state) => {
      state.tableAdvertise = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tablePricingPlanAction.pending, (state) => {
        state.isLoading = true;
        state.daySelected = 0;
        state.paramsSearch = {};
      })
      .addCase(tablePricingPlanAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tablePricing = action.payload.data;
        state.daySelected = action.payload.daySelected;
        state.paramsSearch = action.payload.paramsSearch;
      })
      .addCase(tablePricingPlanAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(tableAdsBookingAction.pending, (state) => {
        state.tableAdvertiseLoading = true;
        state.feePercent = 0;
        state.discountPercent = 0;
      })
      .addCase(tableAdsBookingAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tableAdvertise = action.payload.data.results;
        state.feePercent = action.payload.data.fee_percent ?? 0;
        state.discountPercent = action.payload.data.discount_percent ?? 0;
        state.tableAdvertiseLoading = false;
      })
      .addCase(tableAdsBookingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.tableAdvertiseLoading = false;
      })
      .addCase(listBuildingAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listBuildingAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listBuilding = action.payload.data;
      })
      .addCase(listBuildingAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getDetailAdvertiseAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDetailAdvertiseAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detailAdvertise = action.payload.data;
      })
      .addCase(getDetailAdvertiseAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createAdvertiseAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAdvertiseAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.detailAdvertise = action.payload.data;
      })
      .addCase(createAdvertiseAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getHistoriesAdvertiseAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistoriesAdvertiseAction.fulfilled, (state, action) => {
        state.isLoading = false;
        const paginate = {
          page: action.payload.page,
          totalCount: action.payload.totalCount,
          totalPage: action.payload.totalPage,
          limit: action.payload.limit,
        }
        state.advertiseHistories = {
          ...state.advertiseHistories,
          data: action.payload.data,
          paginate
        }        
      })
      .addCase(getHistoriesAdvertiseAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getHistoriesAdvertiseDetailAction.pending, (state) => {
        state.isLoading = true;
        state.advertiseHistoryDetail = initialState.advertiseHistoryDetail;
      })
      .addCase(getHistoriesAdvertiseDetailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.advertiseHistoryDetail ={
          data: action.payload.data.result,
          fee_percent: action.payload.data.fee_percent,
          discount_percent: action.payload.data.discount_percent,
          advertisement_name: action.payload.data.advertisement_name
        }
      })
      .addCase(getHistoriesAdvertiseDetailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export { reducer };
export default actions;

import { DatePicker, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ButtonImage from '../../../../../components/button-img';
import { ADS_TYPE_OPTION, STATUS_ADS } from '../../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../../stores';
import { Images } from '../../../../../theme';
import {
  AdsType,
  ButtonViewBuilding,
  FilterWrapper,
  ImageBuilding,
  PreviewModalWrapper,
} from './styled';

import BG_MAP from '../../../../../assets/images/bg_map.png';
import { listRequestManagementAction } from '../../../../../stores/screens/requestManagement/management.action';
import { listBuildingAction } from '../../../../../stores/screens/advertise/advertise.action';

const AdvertiseFilter = ({
  onSearch,
  setPaginate,
  paginate,
  advertiseOption,
  error,
  resetSelectedItem,
}) => {
  const { listBuilding } = useAppSelector((state) => state.advertise);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [advertiseOptions, setAdvertiseOptions] = useState([]);
  const [imageBuilding, setImageBuilding] = useState([]);
  const [viewBuildingModal, setViewBuildingModal] = useState(false);
  const refSelectAdvertisement = useRef();
  const refSelectBuilding = useRef();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setBuildingOptions(
      listBuilding &&
        listBuilding.map((building) => ({
          value: building?.id,
          label: building?.name,
          frame_types: building.frame_types,
        }))
    );
  }, [listBuilding]);

  useEffect(() => {
    setAdvertiseOptions(
      advertiseOption &&
        advertiseOption?.map((item) => ({
          value: item?.id,
          label: item?.name,
          type: item?.type,
          aspectRatio: item?.aspect_ratio,
        }))
    );
  }, [advertiseOption]);

  // const ads_type_options = useMemo(() => {
  //   console.log("paginate.buildingId",paginate.buildingId)
  //   console.log("listBuilding", listBuilding)
  //   if (listBuilding && paginate.buildingId) {
  //     const findBuilding = listBuilding.find(
  //       (f) => f.id === paginate.buildingId
  //     );
  //     const options =
  //       !!findBuilding &&
  //       findBuilding.frame_types.map((m) => {
  //         const findOption = ADS_TYPE_OPTION.find((f) => f.value === m);
  //         return findOption;
  //       });

  //     // return options.sort((a, b) => a.index - b.index) || ADS_TYPE_OPTION;
  //   }

  //   return [];
  // }, [paginate.buildingId, listBuilding]);

  const ads_type_options = useMemo(() => {
    if (
      listBuilding &&
      paginate.buildingId &&
      Array.isArray(paginate.buildingId)
    ) {
      const allFrameTypes = [];

      paginate.buildingId.forEach((buildingId) => {
        const findBuilding = listBuilding.find((f) => f.id === buildingId);
        if (findBuilding && findBuilding.frame_types) {
          findBuilding.frame_types.forEach((type) => {
            if (!allFrameTypes.includes(type)) {
              allFrameTypes.push(type);
            }
          });
        }
      });

      const options = allFrameTypes
        .map((m) => {
          return ADS_TYPE_OPTION.find((f) => f.value === m);
        })
        .filter((option) => option !== undefined);

      return options.sort((a, b) => a.index - b.index) || ADS_TYPE_OPTION;
    }

    return [];
  }, [paginate.buildingId, listBuilding]);

  const disabledStartDate = (current) => {
    const today = moment().startOf('day');

    if (paginate.endDate) {
      return (
        current &&
        !(
          current <= moment(paginate.endDate, 'YYYY-MM-DD').endOf('day') &&
          current >= today
        )
      );
    } else {
      return current && current < today;
    }
  };

  const disabledEndDate = (current) => {
    const today = moment().startOf('day');

    if (paginate.startDate) {
      return current && current < moment(paginate.startDate, 'YYYY-MM-DD');
    } else {
      return current && current < today;
    }
  };

  const resetSelect = () => {
    setTimeout(() => {
      const activeOptions = document.querySelectorAll(
        '.ant-select-item.ant-select-item-option.ant-select-item-option-active'
      );
      activeOptions.forEach((option) => {
        option.classList.remove('ant-select-item-option-active');
      });
    }, 10);
  };

  const handleSetImageBuilding = (id) => {
    if (id.length) {
      const res = id.map((id_item) => {
        const itemBuilding =
          listBuilding && listBuilding.find((item) => item.id === id_item);
        return {
          imageBuilding: itemBuilding?.link_map,
          top: itemBuilding?.position_y * 8,
          left: itemBuilding?.position_x * 8,
        };
      });
      setImageBuilding(res);
    }
  };

  // useEffect(() => {
  //   if (refSelectBuilding.current) {
  //     refSelectBuilding.current.scrollTo(0);
  //   }
  //   if (refSelectAdvertisement.current) {
  //     refSelectAdvertisement.current.scrollTo(0);
  //   }
  // }, [paginate]);

  useEffect(() => {
    resetSelect();
  }, [resetSelectedItem]);

  const handleSelectChangeBuid = (value) => {
    dispatch(listRequestManagementAction({ status: STATUS_ADS.APPROVED, buildingId: value  }))
  };

  const handleSelectChangeAds = (value) => {
    dispatch(listBuildingAction({ advertisementId: value }))
  };

  return (
    <FilterWrapper className={`dp-flex mt-10 justify-start gap-0-20`}>
      <div className="form-group w-25">
        <label>
          Advertisement<span className="required">*</span>
        </label>

        <Select
          ref={refSelectAdvertisement}
          value={paginate?.advertisementId}
          options={advertiseOptions}
          listHeight={256}
          listItemHeight={32}
          className={`select h-56`}
          placeholder="Select advertisement"
          popupClassName="custom-option"
          onChange={(e) => {
            const type = advertiseOptions?.filter((item) => item.value === e)[0]
              ?.type;
            const itemSelected = advertiseOptions?.find(
              (obj) => obj?.value === e
            );
            const aspectRatio = itemSelected ? itemSelected?.aspectRatio : null;
            setPaginate({
              ...paginate,
              type: type,
              advertisementId: e,
              aspectRatio,
              buildingId: null,
              category: null,
            });
            handleSelectChangeAds(e);
          }}
          allowClear={true}
          suffixIcon={<img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />}
          autoClearSearchValue={true}
          onClear={() => {
            resetSelect();
          }}
        />
        {!paginate.type && error?.type && (
          <p className="error">Advertisement is required</p>
        )}
      </div>
      <div className={`form-group w-25`}>
        <label>
          Advertisement Type<span className="required">*</span>
        </label>
        <AdsType style={{ color: !!paginate.type ? '#fff' : '#bfbfbf' }}>
          {paginate?.type || 'Ads Type'}
        </AdsType>
        {!paginate.type && error?.type && (
          <p className="error">Ads Type is required</p>
        )}
      </div>
      <div className={`form-group w-25`}>
        <div className={`dp-flex align-end`}>
          <label>
            Building<span className="required">*</span>
          </label>
          <ButtonViewBuilding
            type="text"
            onClick={() => {
              setViewBuildingModal(true);
            }}
            disabled={paginate?.buildingId?.length === 0 || !paginate?.buildingId}
          >
            View building on the map
          </ButtonViewBuilding>
        </div>
        <Select
          ref={refSelectBuilding}
          value={paginate?.buildingId ?? []}
          options={buildingOptions}
          listHeight={256}
          listItemHeight={32}
          className={`select h-56  building-select`}
          placeholder={'Select building'}
          popupClassName="custom-option"
          onChange={(e) => {
            setPaginate({ ...paginate, buildingId: e, category: null });
            handleSetImageBuilding(e);
            // handleSelectChangeBuid(e)
          }}
          mode="multiple"
          allowClear={true}
          suffixIcon={<img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />}
          onClear={() => {
            resetSelect();
            setImageBuilding([]);
          }}
          disabled={!paginate?.advertisementId}
        />
        {!paginate.buildingId && error?.buildingId && (
          <p className="error">Building is required</p>
        )}
      </div>
      <div className={`form-group w-25`}>
        <label>
          Advertisement Frame<span className="required">*</span>
        </label>
        <Select
          disabled={paginate?.buildingId?.length === 0 || !paginate?.buildingId}
          value={paginate?.category ?? []}
          options={ads_type_options}
          className={`select h-56 building-select`}
          placeholder={'Select Ads Type'}
          popupClassName="custom-option"
          onChange={(e) => {
            setPaginate({ ...paginate, category: e });
          }}
          mode="multiple"
          allowClear={true}
          suffixIcon={<img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />}
          onClear={() => {
            resetSelect();
          }}
        />
        {!paginate.category && error?.category && (
          <p className="error">Ads Type is required</p>
        )}
      </div>

      <div className={`form-group w-25`}>
        <label>
          Start date<span className="required">*</span>
        </label>
        <DatePicker
          value={paginate?.startDate ? moment(paginate?.startDate) : null}
          className={`date-picker h-56`}
          popupClassName="date-picker-panel-custom"
          placeholder={'YYYY-MM-DD'}
          onChange={(e) => {
            setPaginate({
              ...paginate,
              startDate: e ? moment(e).format('YYYY-MM-DD') : null,
            });
          }}
          disabledDate={disabledStartDate}
          suffixIcon={<img src={Images.ICON_CALENDAR} alt="calendar" />}
          allowClear
        />
        {!paginate.startDate && error?.startDate && (
          <p className="error">Start date is required</p>
        )}
      </div>

      <div className={`form-group w-25`}>
        <label>
          End date<span className="required">*</span>
        </label>
        <DatePicker
          defaultPickerValue={moment()}
          value={paginate?.endDate ? moment(paginate?.endDate) : null}
          className={`date-picker h-56`}
          popupClassName="date-picker-panel-custom"
          placeholder={'YYYY-MM-DD'}
          onChange={(e) => {
            setPaginate({
              ...paginate,
              endDate: e ? moment(e).format('YYYY-MM-DD') : null,
            });
          }}
          disabledDate={disabledEndDate}
          suffixIcon={<img src={Images.ICON_CALENDAR} alt="calendar" />}
          allowClear
        />
        {!paginate.endDate && error?.endDate && (
          <p className="error">End date is required</p>
        )}
      </div>
      <ButtonImage
        onClick={onSearch}
        type="action"
        height={40}
        textTransform="unset"
        fontWeight={300}
        fontSize={14}
        className={`btn-search btn-search-2`}
      >
        Search
      </ButtonImage>

      {viewBuildingModal && imageBuilding && (
        <PreviewModalWrapper
          open={viewBuildingModal && imageBuilding}
          onCancel={() => {
            setViewBuildingModal(false);
          }}
          footer={null}
          centered
          closeIcon={<img src={Images.ICON_CLOSE_2} alt="close-icon" />}
          width="800px"
          height="800px"
        >
          <ImageBuilding className="preview" src={BG_MAP} alt="img-building" />
          {imageBuilding.length &&
            imageBuilding.map((img, index) => (
              <ImageBuilding
                key={index}
                src={img.imageBuilding}
                top={img.top}
                left={img.left}
                index={index + 10}
                width="50px"
              />
            ))}
        </PreviewModalWrapper>
      )}
    </FilterWrapper>
  );
};

export default AdvertiseFilter;

import styled from 'styled-components';
import { Colors } from '../../theme';

export const ContentStyled = styled.div`
  width: 100%;
  flex: 1;
  background: linear-gradient(270deg, #052b86 0%, #03153f 100%);

  .group-title {
    text-align: center;
    margin: 20px auto;
    padding: 0 20px;

    .title {
      font-weight: 900;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    .created-at {
      font-size: 20px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .event-content {
    font-size: 16px;
    word-break: break-word;
    img {
      max-width: 100%;
      height: auto;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const BackgroundImage = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;

  .background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    background-image: url(${({ url }) => url});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Container = styled.div`
  max-width: 1300px;
  margin: 120px auto;

  @media (max-width: 1300px) {
    padding: 24px;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }

  @media (max-width: 575px) {
    margin-bottom: 10px;
    margin-top: 90px;
  }
`;

import styled from "styled-components";
import { Colors } from "../../../theme";

export const Content = styled.div`
  .home-top {
    position: relative;

    .video-top {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      background-image: linear-gradient(360deg, #052b86 0%, #03153f 100%);

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .let-play {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .title {
      text-align: center;
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 900;
      color: #fff;
      text-shadow: 0px 4px 4px #00000040;
    }

    .group-join-game {
      .group-blue {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 20px;
      }

      .group-green {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
      @media (max-width: 767.98px) {
        .group-blue {
          display: block;
          .btn-image {
            display: flex;
          }
        }
      }

    }
  }

  @media (max-width: 767.98px) {
    .home-top {
      .title {
        font-size: 28px;
      }
      .group-join-game {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 10px;
      }
    }
  }

  @media (min-width: 768px) {
    .home-top {
      .title {
        font-size: 36px;
      }
    }
  }
`;

import styled from "styled-components";
import { Colors } from '../../theme'

export const ContainerStyled = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
`
export const ContentStyled = styled.div`
  width: 100%;
  background: linear-gradient(270deg, #052B86 0%, #03153F 100%);
  padding-top: 120px;
  padding-bottom: 50px;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
  }

  .still-questions {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 15px;
  }

  .btn-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  @media (max-width: 767.98px) {
    .still-questions {
      font-size: 16px;
      margin-top: 10px;
    }

    .wrapper {
      margin-top: 20px;
    }
  }
`;
import React from 'react'
import classNames from 'classnames'

import { SectionStyled } from './styled'

export default ({ children, className, ...props }) => (
  <SectionStyled {...props} className={classNames(className)}>
    {children}
  </SectionStyled>
)

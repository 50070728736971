/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

function ScrollToTop({ children }) {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

export default ScrollToTop;

import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { CardStyled } from './styled';

const CardEvent = ({ data, children, ...props }) => {
  const navigate = useNavigate();

  return (
    <CardStyled
      {...props}
      backgroundImage={data?.background_url || ''}
      onClick={() => navigate(`/event-detail/${data.id}`)}
    >
      <div className='bg-image'>
        <div className='background' />
        <img
          src={data?.background_url || ''}
          alt="MEDIA MGA"
        />
      </div>
      <div className='content'>
        <div className='date-time'>
          <div className='month'>{data?.created_at ? moment(data.created_at).format('MMM') : ''}</div>
          <div className='date'>{data?.created_at ? moment(data.created_at).format('D') : ''}</div>
        </div>
        <div className='event-name'>{data?.title || ''}</div>
      </div>
    </CardStyled>
  );
};

export default CardEvent;

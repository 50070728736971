import { Modal } from 'antd';
import styled from 'styled-components';

export const ManagementContent = styled.div`
  min-height: calc(100vh - 125px);
  border: 1px solid rgba(43, 106, 230, 0.5);
  padding: 10px;

  .title {
    margin-bottom: 20px;
  }

  .btn-group {
    gap: 10px;
  }

  .ellipsis {
    white-space: nowrap;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-action {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 8px;

    &.detail {
      background-color: #27a376;
    }

    &.delete {
      background-color: #e03137;
    }
  }

  .input {
    background: #00000036;
    width: 300px;
    height: 40px;
    border-color: #156df2;
    margin-right: 16px;
    input {
      background-color: unset;
      color: #fff;

      &::placeholder {
        color: #757b8c;
      }
    }
  }

  .select {
    background: #00000036;
    width: 200px;
    height: 40px;
    border-color: #156df2;
    margin-right: 16px;
    color: #fff;

    .ant-select-selector {
      background-color: unset;
      height: 40px;
      border-color: #156df2;
      color: #fff;
      display: flex;
      align-items: center;
    }

    .ant-select-selection-placeholder {
      color: #757b8c;
    }

    .ant-select-clear {
      background: transparent;
      margin-right: 25px;
    }
  }

  .status {
    width: 100px;
    height: 25px;
    border-radius: 20px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;

    &__approved {
      color: #18a320;
      background-color: #eaf7eb;
    }
    &__rejected {
      color: #e03137;
      background-color: #feeaea;
    }
    &__requested {
      color: #1768ec;
      background-color: #eaf3fc;
    }
  }
`;

export const ModalPreview = styled(Modal)`
  background: transparent;
  /* min-width: 50%; */

  .ant-modal-content {
    background: transparent;
    border-radius: 20px;
    box-shadow: unset;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .ant-modal-close {
    top: -30px;
    right: -50px;

    .ant-modal-close-x {
      width: 44px;
      height: 44px;
      line-height: 30px;
    }
  }

  video,
  .preview {
    border-radius: 10px;
  }
  .preview {
    box-shadow: rgb(241 237 237) 0px 1px 4px, rgb(255 254 254) 0px 0px 0px 5px;
    background-color: #ececec;
  }

  .anticon {
    color: #fff;
  }

  .video-content {
    position: relative;
    box-shadow: rgb(241 237 237) 0px 1px 4px, rgb(255 254 254) 0px 0px 0px 5px;
    border-radius: 6px;
    .controls {
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(8, 9, 13, 0),
        rgba(8, 9, 13, 0.9)
      );

      .name-video {
        position: absolute;
        color: #fff;
        top: 0;
        left: 3%;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .current-time {
        position: absolute;
        color: #c7c5c5;
        top: 0;
        left: calc(100% - 144px);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .total-time {
        position: absolute;
        color: #c7c5c5;
        top: 0;
        left: calc(100% - 70px);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .temp {
        position: absolute;
        color: #c7c5c5;
        top: 0;
        left: calc(100% - 79px);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .range {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 20px);
        // -webkit-appearance: none;
        background: #d9d9d9;
        border-radius: 5px;
        height: 2px;
        cursor: pointer;

        &::-webkit-slider-thumb {
          // -webkit-appearance: none;

          height: 4px;
          background: red;
          border-radius: 5px;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          height: 4px;
          background: red;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      .btn-control {
        position: absolute;
        background: transparent;
        border: none;
        cursor: pointer;

        &.btn-play {
          bottom: 15px;
          left: 15px;
        }

        &.btn-prev {
          bottom: 15px;
          left: 50px;
        }
        &.btn-next {
          bottom: 15px;
          left: 90px;
        }

        &.btn-mute {
          bottom: 15px;
          right: 50px;
        }
        &.btn-full {
          bottom: 15px;
          right: 15px;
        }
      }
    }
  }
`;

import React, { Component } from 'react'
import classNames from 'classnames'

import { PageStyled } from './styled'

export default class extends Component {
  render() {
    const { children, sidebar, className, ...props } = this.props

    return (
      <PageStyled {...props} ref={(ref) => { this._div = ref }} className={classNames('page', className)}>
        {children}
      </PageStyled>
    )
  }
}

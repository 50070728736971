import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Storage from '../utils/storage';
import { useAppDispatch } from '../stores';
import {
  getAccountCheckIpAction
} from '../stores/screens/accounts/accounts.action';

import MaskLoading from '../components/mask-loading';
import { ADVERTISE_PATHNAME } from '../pages/Statistics/constant';

function Init({ children }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const paramsUrl = new URLSearchParams(search);
  const source = paramsUrl.get("source")

  useEffect(() => {
    init()
  }, []);

  const checkPathname = (pathname) => {
    return ADVERTISE_PATHNAME.some(path => pathname.includes(path));
  }

  const init = async () => {
    if (source === 'MCCBET') {
      MaskLoading.open({});
      const { payload } = await dispatch(getAccountCheckIpAction());

      if (payload.data === false) {
        navigate('/country-blocked');
        return
      }
      MaskLoading.close();
    }

    if (Storage.get('ACCESS_TOKEN') && Storage.get('PUBLIC_ADDRESS')) {
      if(!checkPathname(pathname)){
        navigate('/');
      }
    }
  }

  return children
}

export default Init
import { Modal } from 'antd';
import 'antd/es/modal/style/css';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
  background: linear-gradient(270deg, #052b86 0%, #03153f 100%);
  border-radius: 20px;
  width: ${({ smallModal }) => smallModal ? "476px" : "961px"} !important;

  .ant-modal-wrap {
    background: linear-gradient(270deg, #052b86 0%, #03153f 100%);
    border-radius: 20px;
    z-index: 1000;
    padding: 20px;
    .ant-modal {
      padding: 0;
      max-width: 100%;
      max-height: 100%;
      overflow-y: auto;
    }
    .ant-modal-close {
      display: none;
    }
    img,
  // div,
  p,
  span,
  b,
  i,
  ul,
  li,
  ol,
  a,
  .ant-modal-content {
      max-width: 100%;
      overflow: hidden;
    }

    //style scroll modal
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .ant-modal-content {
    background: transparent;
  }

  .ant-modal-header {
    background: transparent;
    border: unset;

    .ant-modal-title {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .anticon {
    color: #fff;
  }

  .modal-delete {
    color: #fff;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .btn-group {
      width: 100%;
      justify-content: center;
      display: flex;
      gap: 10px;
    }
  }

  .detail-modal {
    .detail-table {
      max-height: 500px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: unset;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #2b6ae680;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #2b6ae680;
      }

      .ant-table,
      .ant-table-content {
        background: transparent;
      }
    }
  }
`;

import React, { useEffect } from 'react';
import { useWeb3Auth } from '../../contexts/web3auth';
import Header from '../Header';
import ScrollToTop from '../../utils/scrollToTop';
import Footer from '../Footer';
import { Outlet } from 'react-router';
import MaskLoading from '../../components/mask-loading';
import Confirmable from '../../components/confirmable';
import Aos from 'aos';
import { useLocation } from 'react-router-dom';

const MainLayout = () => {
  const { accessToken, currentAccount, userInfo } = useWeb3Auth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isIframe = queryParams.get('receiveMsg') ?? queryParams.get('source');

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="App">
      <Header
        accessToken={accessToken}
        publicAddress={currentAccount}
        userInfo={userInfo}
      />
      <ScrollToTop>
        <Outlet />
      </ScrollToTop>
      {!isIframe && <Footer />}
      <MaskLoading ref={(ref) => MaskLoading.setInstance(ref)} />
      <Confirmable ref={(ref) => Confirmable.setInstance(ref)} />
    </div>
  );
};
export default MainLayout;

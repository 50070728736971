import styled from 'styled-components';

export const Total = styled.div`
  background: rgba(4, 19, 56, 1);
  padding: 16px;
  align-items: center;
  display: flex;
  gap: 16px;
`;

export const TotalDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-family: Noto Sans JP;
`;
import styled from "styled-components";
import { Pagination } from 'antd'
import 'antd/es/pagination/style/css'

export const PaginationStyled = styled(Pagination)`
  &.ant-pagination {
    text-align: center;
    padding: 20px;
  }

  .ant-pagination-item {
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.20);
    border: none;
    &-active {
      border: 1px solid #2b6ae6;
    }
    a {
      color: #f1f1f1;
    }
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    .ant-pagination-item-link {
      border-radius: 6px;
      background-color: #2b6ae6;
      border: none;
      color: #fff;
    
    }
  }
  .ant-pagination-item-ellipsis {
    color: #fff !important;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.20);
  }
`;

import styled from "styled-components";
import { Colors } from "../../theme";

export const ContainerStyled = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0 100px;
  overflow: hidden;

  @media (max-width: 767.98px) {
    padding: 0 10px;
  }

  @media (min-width: 768px) {

  }
`;

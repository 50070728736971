import React from 'react';
import EventExample from '../../assets/images/icons/event-example.png';
import { Images } from '../../theme';
import { TitleStyled } from './styles';

const TitleSection = ({ children, ...props }) => {
  return (
    <TitleStyled {...props} backgroundImage={EventExample}>
      <img src={Images.ICON_UNION} alt='Left Title' />
      {children}
      <img
        style={{ transform: 'rotate(180deg)' }}
        src={Images.ICON_UNION}
        alt='Right Title'
      />
    </TitleStyled>
  );
};
export default TitleSection;

import { Form, Modal } from 'antd';
import styled from 'styled-components';

export const FormRegisterStyled = styled(Form)`
  &.form-register {
    width: 100%;
    height: 100%;
    .title {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      font-family: "Inter";
    }

    .sub-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 8px;
      font-family: "Inter";
    }

    .redirect-register {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      margin-bottom: 35px;

      .register-redirect-link {
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .action-box {
      margin-top: 16px;
      display: flex;
      justify-content: center;

      .btn-login {
        width: 100%;
        border-radius: 99999px;
        background: #f9fafb;
        border: initial !important;
        height: 48px;

        &.ant-btn:disabled {
          background: #f9fafb;
        }
      }
    }

    .error {
      color: red;
    }
  }
`;

export const ModalVerifyCodeAuthStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 10px 0px #00d1ff;
    background-color: #1f2582;
  }

  .ant-modal-body {
    background: linear-gradient(
      270deg,
      rgba(14, 82, 246, 0) 0%,
      rgba(14, 82, 246, 0.22) 8.87%,
      rgba(14, 82, 246, 0.8) 46.6%,
      rgba(49, 106, 217, 0.34) 84.3%,
      rgba(72, 138, 251, 0) 100%
    );
    color: #fff;
  }
`;

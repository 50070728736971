import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';

import { useAppDispatch, useAppSelector } from '../../stores';
import { getEventsByIdAction } from '../../stores/screens/events/events.action';

/** component */
import Page from '../../components/page';
import EditorViewer from '../../components/ckeditor-view';

/** asset */
import { BackgroundImage, Container, ContentStyled } from './styled';
import LoadingSkeleton from '../../components/loading-skeleton';

function EventDetail() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { eventDetails, isLoading } = useAppSelector((state) => state.events);

  useEffect(() => {
    fetchEventDetails();
  }, []);

  const fetchEventDetails = () => {
    dispatch(getEventsByIdAction({ id }));
  };

  return (
    <Page>
      <ContentStyled className='content'>
        <Container>
          {isLoading ? (
            <LoadingSkeleton loading={isLoading} active />
          ) : (
            <>
              <BackgroundImage url={eventDetails?.background_url}>
                {/* <div className='background' /> */}
                <img
                  src={eventDetails?.background_url}
                  alt={eventDetails?.title}
                />
              </BackgroundImage>
              <div className='group-title'>
                <h1 className='title'>{eventDetails?.title}</h1>
                <p className='created-at'>
                  {eventDetails?.created_at
                    ? moment(eventDetails?.created_at).format('YYYY/MM/DD')
                    : ''}
                </p>
              </div>
              <div
                className='event-content'
              // dangerouslySetInnerHTML={{ __html: eventDetails?.description }}
              >
                <EditorViewer
                  id="editor"
                  dataContent={eventDetails?.description}
                />
              </div>
            </>
          )}
        </Container>
      </ContentStyled>
    </Page>
  );
}

export default EventDetail;

import { Link, useLocation } from 'react-router-dom';
import { Images } from '../../../theme';
import { SidebarWrapper } from './styled';
import { useState } from 'react';

const SideBar = () => {
  const { pathname } = useLocation();
  const [showSubMenu, setShowSubMenu] = useState(true);

  const itemLink = [
    {
      name: 'Request Management',
      to: '/management',
    },
    {
      name: 'Advertise',
      to: '/advertise',
    },
    {
      name: 'Statistics',
      to: '/statistics',
    },
  ];

  const toggleBtn = () => {
    setShowSubMenu(!showSubMenu);
  };

  return (
    <SidebarWrapper>
      <div className="sub-menu" onClick={toggleBtn}>
        <img src={Images.ICON_ADV} alt="icon-adv" />
        <span>Advertisement</span>
        {!showSubMenu ? (
          <img
            src={Images.ICON_DROPDOWN}
            className="icon-down"
            alt="icon-down"
          />
        ) : (
          <img
            src={Images.ICON_DROPDOWN}
            className="icon-down up"
            alt="icon-down"
          />
        )}
      </div>
      {showSubMenu && (
        <ul>
          {itemLink.map((item, index) => {
            return (
              <div
                className={`dp-flex ${
                  pathname.includes(item.to) ? 'activated' : ''
                }`}
                key={index}
              >
                <div
                  className={`${pathname.includes(item.to) ? 'border' : ''}`}
                ></div>
                <li key={index}>
                  <Link relative="path"className={pathname === item.to ? 'disabled' : ''} to={item?.to}>{item.name} </Link>
                </li>
              </div>
            );
          })}
        </ul>
      )}
    </SidebarWrapper>
  );
};

export default SideBar;

import Modal from '../../components/modal';
import styled from 'styled-components';

export const Content = styled.div`
  .register-top {
    position: relative;

    .video-top {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      background-image: linear-gradient(360deg, #052b86 0%, #03153f 100%);

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .logo-login {
      position: absolute;
      left: 0;
      right: 0;
      top: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
      }
    }

    .form-register-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 28%;
      display: flex;
      justify-content: center;
      gap: 61px;
    }
  }
  @media screen and (max-width: 1024px) {
    .register-top {
      .logo-login {
        top: 35%;
      }
    }
  }
  @media screen and (max-width: 820px) {
    .register-top {
      .logo-login {
        top: 23%;
      }
    }
  }
  @media screen and (min-width: 575px) and (max-width: 968px) {
    .form-register-container {
      gap: 8px !important;
      bottom: initial;
      top: 28%;
    }
  }
  @media screen and (min-height: 575px) and (max-height: 850px) {
    .form-register-container {
      gap: 8px !important;
      bottom: initial;
      top: 28%;
    }
  }
  @media screen and (max-width: 414px) {
    .register-top {
      .logo-login {
        top: 20%;
      }
    }
  }
`;

export const ModalOpenLoginStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 32px;
  }

  .ant-modal-body {
    input {
      background-color: #f9fafb;
      border-color: #d9d9d9;
      border-radius: 99999px;
      color: black;
      padding: 12px 24px;
      line-height: 125%;

      &::placeholder {
        color: #999999;
      }

      &:focus {
        border-color: #1890ff;
      }
    }

    .ant-btn:hover {
      background-color: #d1d5db;
      border-color: #999999;
      color: black;
    }
  }
`;

export const IconCloseModalStyled = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  span {
    font-size: 20px;
    opacity: 0.2;
    margin-right: 5px;
  }
`;

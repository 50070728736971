import styled from 'styled-components';

export const OTPInputStyled = styled.div`
  margin-top: 30px;

  .otp-style {
    gap: 15px;
    justify-content: center;
    padding: 0px 50px;

    .input-text {
      border-radius: 10px;
      font-size: 40px;
      font-weight: 700;
      padding: 10px;
      text-transform: uppercase;
    }

    @media (max-width: 575px) {
      gap: 10px;
      padding: 0px 20px;
    }
  }
`;

import styled from "styled-components";

export const DivStyled = styled.div`
  .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners,
  .ck-editor .ck-content,
  .ck-editor:hover .ck-content,
  .ck-editor:active .ck-content,
  .ck-editor:focus-visible .ck-content,
  .ck-editor .ck-content:focus-visible {
    background-color: transparent;
    height: auto;
    border: none;
    padding: 0;
  }

  .image .ck-placeholder,
  .ck .ck-widget:hover .ck-widget__selection-handle,
  .ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected.ck-widget_with-selection-handle .ck-widget__selection-handle, .ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected.ck-widget_with-selection-handle .ck-widget__selection-handle:hover, .ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected:hover.ck-widget_with-selection-handle .ck-widget__selection-handle, .ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected:hover.ck-widget_with-selection-handle .ck-widget__selection-handle:hover,
  .ck-editor .ck-editor__top {
    display: none;
  }
`;

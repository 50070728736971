import { createSlice } from '@reduxjs/toolkit';

import {
  getAccountCheckIpAction
} from './accounts.action';

const initialState = {
  isLoading: false,
  error: null,

  checkIp: null
};

const { actions, reducer } = createSlice({
  name: 'accounts_slice',
  initialState,
  reducers: {
    resetState: (state) => {
      state.checkIp = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountCheckIpAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAccountCheckIpAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkIp = action.payload.data;
      })
      .addCase(getAccountCheckIpAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export { reducer };
export default actions;

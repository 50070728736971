import styled from "styled-components";

import { Images, Colors } from "../../theme";

export const CardStyled = styled.div`
  width: 100%;
  max-width: 100%;

  .content-question {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .question,
  .answer {
    margin-bottom: 20px;

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border: 2px solid #fff;
      border-radius: 50%;
      box-shadow: 0px 0px 10px 0px #48d0fb;
    }
  }

  .question {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    > img {
      order: 1;
    }

    .content-question {
      width: 800px;
      max-width: 100%;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 20px;
      padding: 30px 20px;
      text-align: justify;
      font-size: 20px;
      font-weight: 700;
      background: linear-gradient(
        270deg,
        rgba(0, 179, 255, 0) 0%,
        rgba(72, 208, 251, 0.224) 8.87%,
        rgba(0, 224, 255, 0.8) 46.6%,
        rgba(72, 208, 251, 0.336) 84.3%,
        rgba(72, 208, 251, 0) 100%
      );
      box-shadow: 0px 0px 10px 0px #48d0fb;
    }

    .content-text {
      order: 2;
      max-width: calc(100% - 100px);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      .anticon-plus-circle {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .answer {
    display: none;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 20px;

    > img {
      order: 2;
    }

    .content-text {
      order: 1;
      width: 820px;
      max-width: calc(100% - 100px);
      color: #fff;
      border: 2px solid #fff;
      border-radius: 20px;
      padding: 30px 20px;
      text-align: justify;
      font-size: 16px;
      line-height: 1.2;
      font-weight: 400;
      white-space: pre-wrap;
      background: linear-gradient(
        270deg,
        rgba(14, 82, 246, 0) 0%,
        rgba(14, 82, 246, 0.224) 8.87%,
        rgba(14, 82, 246, 0.8) 46.6%,
        rgba(49, 106, 217, 0.336) 84.3%,
        rgba(72, 138, 251, 0) 100%
      );
      box-shadow: 0px 0px 10px 0px #00d1ff;
    }
  }

  &.faq-item:first-child,
  &.show {
    .question .content-text .anticon-plus-circle {
      display: none;
    }

    .answer {
      display: flex;
    }
  }

  @media (max-width: 767.98px) {
    .question,
    .answer {
      flex-direction: column;
      gap: 15px;

      > img {
        order: 1;
        width: 50px;
        height: 50px;
        border: 1px solid #fff;
      }

      .content-text {
        order: 2;
        max-width: 100%;
        gap: 15px;
      }
    }

    .question {
      .content-question {
        font-size: 16px;
      }
    }

    .answer {
      justify-content: flex-start;
      align-items: flex-end;

      .content-text {
        font-size: 14px;
      }
    }

    .question .content-question,
    .answer .content-text {
      padding: 15px 12px;
      border: 1px solid #fff;
      border-radius: 10px;
    }
  }
`;

import styled from "styled-components";
import { Colors } from "../../../theme";

export const Content = styled.div`
  .home-ranking {
    position: relative;
    color: #fff;
    background: rgb(3, 21, 63);
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;

    .box-top-ranking {
      margin-top: 50px;
    }

    .content-ranking {
      padding: 0px 50px 20px 50px;
    }

    .item-ranking {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #d9d9d919;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        .avatar-user {
          width: 70px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-shadow: 0px 0px 8px 2px #2b37bd;
          background: linear-gradient(180deg, #2773da 0%, #68d8ff 100%),
            radial-gradient(
              63% 63% at 50% 50%,
              #2773da 0%,
              rgba(0, 0, 0, 0) 100%
            );
        }

        .avatar-user > img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border: 7px solid rgb(3, 21, 63);
          border-radius: 50%;
        }

        .avatar-user > .icon-user {
          font-size: 30px;
        }

        .address {
          font-size: 26px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .right {
        &.point {
          font-size: 26px;
          font-weight: 700;
          color: #ffa70e;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  @media (max-width: 767.98px) {
    .home-ranking {
      .body {
        padding-top: 25px;
        padding-bottom: 25px;

        .box-top-ranking {
          margin-top: 30px;
        }

        .content-ranking {
          padding: 0px 20px 20px 20px;
        }

        .item-ranking {
          padding: 10px 0;

          .left {
            gap: 12px;

            > img {
              width: 22px;
            }

            .avatar-user {
              width: 54px;
              height: 54px;
            }

            .avatar-user > img {
              width: 50px;
              height: 50px;
              border: 2px solid rgb(3, 21, 63);
            }

            .address {
              font-size: 14px;
              .point {
                font-size: 14px;
                font-weight: 700;
                color: #ffa70e;
              }
            }
          }

          .right {
            display: none;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .home-ranking {
      .body .item-ranking .left .address .point {
        display: none;
      }
    }
  }
`;

import { createSlice } from '@reduxjs/toolkit';

import {
  getPointsTopAction,
} from './points.action';

const initialState = {
  isLoading: false,
  error: null,
  listPointsTop: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
};

const { actions, reducer } = createSlice({
  name: 'points_slice',
  initialState,
  reducers: {
    resetListPointsAction: state => {
      state.listPointsTop = {
        items: [],
        limit: 10,
        page: 1,
        totalCount: 0,
        totalPage: 0,
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPointsTopAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPointsTopAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listPointsTop = {
          items: action.payload.data,
          limit: action.payload.limit,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
          totalPage: action.payload.totalPage,
        }
      })
      .addCase(getPointsTopAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export { reducer };
export default actions;

import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Input, notification } from 'antd';
import * as Yup from 'yup';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch } from '../../../stores';
import { verifyEmailAuthAction } from '../../../stores/screens/auth/auth.action';

import Button from '../../../components/button';
import FormControl from '../../../components/form-control';
import MaskLoading from '../../../components/mask-loading';
import { ModalVerifyCode } from '../../../components/modal-verify-code';
import Confirmable from '../../../components/confirmable';

import { FormRegisterStyled, ModalVerifyCodeAuthStyled } from './styled';

const schema = Yup.object().shape({
  code: Yup.string().required('This field is required').min(4).max(4),
});

export const FormRegister = ({
  trigger,
  isValid,
  handleSubmit = () => {},
  control,
  errors,
  getValues = () => {},
  isCheckParam,
  navigate,
}) => {
  const {
    control: controlVerifyCode,
    formState: { errors: errorVerifyCode, isValid: isValidVerifyCode },
    handleSubmit: handleSubmitVerifyCode,
    trigger: triggerVerifyCode,
    getValues: getValueVerifyCode,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const [isOpenPopupVerifyCode, setIsOpenPopupVerifyCode] = useState({
    isShow: false,
    data: null,
  });
  const [counter, setCounter] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isOpenPopupVerifyCode.isShow) {
      reset();
    }
  }, [isOpenPopupVerifyCode.isShow, reset]);

  const onSubmit = (data) => {
    trigger();
    if (isValid) {
      requestVerifyEmail(data);
    }
  };

  const requestVerifyEmail = async (data) => {
    MaskLoading.open({});

    const { payload } = await dispatch(verifyEmailAuthAction({ params: data }));
    if (payload?.status) {
      const time = payload?.data?.expiredAt - moment.utc().unix() - 1;
      setCounter(time);
      setIsOpenPopupVerifyCode({
        isShow: true,
        data: data.email,
      });
    } else {
      if (payload?.error === 'NOT_FOUND') {
        if (isCheckParam?.includes('source')) {
          Confirmable.open({
            content: 'The account has not been registered.',
            hideIconClose: true,
            hideCancelButton: true,
            width: 400,
            acceptButtonText: 'Register now',
            onOk: () => {
              navigate(`/register${isCheckParam}`);
            },
          });
        } else {
          notification.error({
            message: payload?.message,
            duration: 5,
          });
        }
      } else {
        if (payload.error === 'FORBIDDEN') {
          notification.error({
            message: 'Your account is locked',
            duration: 5,
          });
        } else {
          notification.error({
            message: payload?.message,
            duration: 5,
          });
        }
      }
    }
    MaskLoading.close();
  };

  return (
    <>
      <FormRegisterStyled
        className="form-register"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="title">Login</div>
        <div className="redirect-register">
          <Link to={isCheckParam?.includes('source') ? `/register${isCheckParam}` : `/register`} className="register-redirect-link">
            Click here{' '}
          </Link>
          <span>for new registration</span>
        </div>
        <FormControl>
          <div className="sub-title">Email</div>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                id="email"
                name="email"
                placeholder="E.g.: name@example.com"
                onChange={onChange}
                value={value || ''}
              ></Input>
            )}
          />
          {errors?.email && <p className="error">{errors.email.message}</p>}
        </FormControl>
        <div className="action-box">
          {/* <ButtonImage
            onClick={() => onSubmit(getValues())}
            src={Images.GENERATE_BTN}
            height={50}
            className="btn-login"
          >
            Login
          </ButtonImage> */}
          <Button
            onClick={() => onSubmit(getValues())}
            className="btn-login"
            disabled={!isValid}
          >
            Login
          </Button>
        </div>
      </FormRegisterStyled>
      <ModalVerifyCodeAuthStyled
        onCancel={() => setIsOpenPopupVerifyCode(false)}
        open={isOpenPopupVerifyCode.isShow}
        width={535}
        footer={null}
        centered
        closable={false}
      >
        <ModalVerifyCode
          data={isOpenPopupVerifyCode}
          controlVerifyCode={controlVerifyCode}
          errorVerifyCode={errorVerifyCode}
          isValidVerifyCode={isValidVerifyCode}
          handleSubmitVerifyCode={handleSubmitVerifyCode}
          triggerVerifyCode={triggerVerifyCode}
          getValueVerifyCode={getValueVerifyCode}
          setCounter={setCounter}
          counter={counter}
          isCheckParam={isCheckParam}
          closeModal={() => {
            setIsOpenPopupVerifyCode(false);
            window.close();
          }}
        ></ModalVerifyCode>
      </ModalVerifyCodeAuthStyled>
    </>
  );
};

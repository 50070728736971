import { createAsyncThunk } from '@reduxjs/toolkit';
import StatisticalsApi from './statistical.api';



export const getViewedAction = createAsyncThunk(
  'getViewed',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await StatisticalsApi.getInteractions(params);
      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const getClickAdsAction = createAsyncThunk(
  'clickAdsAdvertisement',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await StatisticalsApi.getInteractions(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const getBudgetAdsAction = createAsyncThunk(
  'budgetAds',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await StatisticalsApi.getBudgetAds(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const getBuildingsAction = createAsyncThunk(
  'getBuildings',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await StatisticalsApi.getBuildings(params);
      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);
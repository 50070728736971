import React from 'react'

/** Components */
import Container from '../../components/container'

/** asset */
import { Images } from '../../theme'
import { FooterStyled } from './styled'
import { Link } from 'react-router-dom'

function Footer(...props) {
  return (
    <FooterStyled {...props}>
      <Container>
        <div className='top'>
          <div className='logo' data-aos="fade-right">
            <img src={Images.LOGO_FOOTER} alt="Logo MGA Footer" />
          </div>

          <div className='menu-categories' data-aos="fade-left">
            <div className='menu-items'>
              <div className='title'>
                About Us
              </div>
              <div className='list-link'>
                <Link to={'faqs-list'}>FAQS</Link>
              </div>
            </div>
            <div className='menu-items'>
              <div className='title'>
                Services
              </div>
              <div className='list-link'>
                <p onClick={() => window.open(process.env.REACT_APP_SITE_MCC_URL, '_blank')}>NFT Marketplace</p>
                <p onClick={() => window.open(process.env.REACT_APP_SITE_MCC_BET_URL, '_blank')}>MCC BET</p>
              </div>
            </div>
            <div className='menu-items'>
              <div className='title'>
                Communities
              </div>
              <div className='list-link icon'>
                <img src={Images.ICON_DISCORD} alt="Icon discord" />
                <img src={Images.ICON_TELEGRAM} alt="Icon discord" />
              </div>
            </div>
          </div>
        </div>

        <div className='bottom'>
          © 2023 MCC. All rights reserved
        </div>
      </Container>
    </FooterStyled>
  )
}

export default Footer

import { createAsyncThunk } from '@reduxjs/toolkit';

import GENERATE_API from './generate.api';

export const generateVRCodeAction = createAsyncThunk(
  'GENERATE_VR_CODE',
  async ({ params, callback }, { dispatch, rejectWithValue }) => {
    try {
      const result = await GENERATE_API.generateVRCode(params);
      if (callback) {
        callback(result);
      }
      return result;
    } catch (err) {
      return rejectWithValue({ message: err?.message });
    }
  },
);

export const generateDeviceAction = createAsyncThunk(
  'GENERATE_DEVICE',
  async ({ params, callback }, { dispatch, rejectWithValue }) => {
    try {
      const result = await GENERATE_API.generateDevice(params);
      if (callback) {
        callback(result);
      }
      return result;
    } catch (err) {
      return rejectWithValue({ message: err?.message });
    }
  },
);


import React from "react";

import { Images } from "../../theme";
import { CardStyled } from "./styled";

const CardLogin = ({
  title,
  button,
  image,
  height,
  positionImage,
  subTitle,
  ...props
}) => (
  <CardStyled {...props} positionImage={positionImage}>
    <div className="wrapper">
      <div className="title">{title}</div>

      <div className="content">
        <div className="image">
          <img src={image} alt="Image login" />
        </div>
      </div>
      <div className="button">{button}</div>
      <div className="sub-title">{subTitle}</div>
    </div>

    <div className="bg-card">
      <img src={Images.BG_CARD_LOGIN} alt="Background card event" />
    </div>
  </CardStyled>
);
export default CardLogin;

import { AxiosClient } from '../../../configs/axios/axios';
import { LOCAL_STORAGE_KEY } from '../../../constants';
import Storage from '../../../utils/storage';
import axios from 'axios';

const apiVersion = process.env.REACT_APP_VERSION;

const ManagementApi = {
  preSignUrl: async (payloads) => {
    const {isThumbnail, ...rest} = payloads
    const result = await new AxiosClient().post(
      `${apiVersion}/advertisements/upload/pre-signed?isThumbnail=${payloads.isThumbnail}`,
      rest,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
      }
    );

    return result;
  },

  uploadToS3: async (params) => {
    const result = await axios({
      method: 'put',
      url: params.url,
      data: params?.file,
      headers: {
        'Content-Type': params?.file.type,
      },
    });
    return result;
  },

  listRequestManagement: async (params) => {
    const result = await new AxiosClient().get(`${apiVersion}/advertisements`, {
      headers: {
        'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
        'Content-Type': 'application/json',
      },
      params: params,
    });

    return result;
  },

  createRequest: async (payloads) => {
    const result = await new AxiosClient().post(
      `${apiVersion}/advertisements`,
      payloads,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
      }
    );

    return result;
  },

  deleteRequest: async (id) => {
    const result = await new AxiosClient().delete(
      `${apiVersion}/advertisements/${id}`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
      }
    );

    return result;
  },

  detailRequest: async (id) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisements/${id}`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
      }
    );

    return result;
  },
  getAspectRatios: async (id) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisements/aspect-ratios`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
      }
    );

    return result;
  },
};

export default ManagementApi;

import { CHAIN_LIST } from "../constants/chains";
import Storage from "./storage";
import Request from "./request";

const env = process.env.REACT_APP_ENV;

export const getNetworks = env => Object.values(CHAIN_LIST).filter(item => item.env?.includes(env));

export const chainOptions = getNetworks(env)?.map(item => ({ value: item.chainId, label: item.displayName }));

export const getNetworkSelected = (env, chainIdSelected) => {
  const networks = getNetworks(env);
  return chainIdSelected ? networks.find(item => item.chainId === +chainIdSelected) || networks[1] : networks[1];
};

export const removeLocalLogout = () => {
  localStorage.removeItem('openlogin_store');
  Storage.remove('ACCESS_TOKEN');
  Storage.remove('EXPIRED_TOKEN');
  Storage.remove('REFRESH_TOKEN');
  Storage.remove('USER_ID');
  Storage.remove('PUBLIC_ADDRESS');
  localStorage.removeItem('Web3Auth-cachedAdapter');
  Storage.remove("SSO_ADAPTER");
  Request.removeAccessToken();
  Storage.remove('ExpiredTimeVRcode');
  Storage.remove('VRcode');
  Storage.remove('ExpiredTimeDeviceCode');
  Storage.remove('DeviceCode');
  Storage.remove('IS_CHECK');
};

export const checkSessionTimeOut = () => {
  const sessionId = localStorage.getItem('openlogin_store');
  const cachedAdapter = localStorage.getItem('Web3Auth-cachedAdapter');
  const parseSession = JSON.parse(sessionId);
  const checkSession = parseSession?.sessionId;

  return !checkSession && cachedAdapter === 'openlogin'
};

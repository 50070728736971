
let isMobile = false;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  isMobile = true;
}
export default class Misc {
  static trimPublicAddress = (string, numberOfCharacter) =>
    string &&
    `${string.slice(0, numberOfCharacter)} . . . ${string.slice(
      string.length - numberOfCharacter,
      string.length
    )}`;

  static trimFormatNumber = (string) => {
    return (
      string &&
      `${Number(string)
        .toFixed(5)}`
    );
  };

  static checkPermission = (permission, path, history) => {
    // const pathName = path === '/' ? 'MANAGER_DASHBOARD' : path.replaceAll('-', '_').toUpperCase().slice(1, path.length)
    const pathName =
      path === '/'
        ? 'MANAGER_DASHBOARD'
        : path.split('/')[1].replaceAll('-', '_').toUpperCase();
    if (!permission?.includes(pathName)) {
      history.push('/');
    }
  };

  static isMobile = isMobile;

  static checkNotMetamaskBrowser = () => {
    // const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    const isOpera = false;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1+
    const isChrome = !!window.chrome;

    // Blink engine detection

    if (isOpera || isFirefox || isSafari || isIE || isEdge || isChrome) {
      return true;
    }

    return false;
  };
}

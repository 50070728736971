import { createSlice } from '@reduxjs/toolkit';

import {
  createRequestAction,
  detailRequestAction,
  listRequestManagementAction,
  getAspectRatiosAction
} from './management.action';

const initialState = {
  isLoading: false,
  error: {},
  listRequestManagement: {
    items: [],
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPage: 0,
  },
  requestDetail: {},
  createSuccess: false,
  aspectRatios: []
};

const { actions, reducer } = createSlice({
  name: 'request_management_slice',
  initialState,
  reducers: {
    resetListRequestAction: (state) => {
      state.listRequestManagement = {
        items: [],
        limit: 10,
        page: 1,
        totalCount: 0,
        totalPage: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listRequestManagementAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listRequestManagementAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listRequestManagement = {
          items: action.payload.data?.map((item, index) => ({...item, index})),
          limit: action.payload.limit,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
          totalPage: action.payload.totalPage,
        };
      })
      .addCase(listRequestManagementAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createRequestAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRequestAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createSuccess = action.payload.data;
      })
      .addCase(createRequestAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(detailRequestAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(detailRequestAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requestDetail = action.payload.data;
      })
      .addCase(detailRequestAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getAspectRatiosAction.pending, (state) => {
      })
      .addCase(getAspectRatiosAction.fulfilled, (state, action) => {
        state.aspectRatios = action.payload.data.map((m) => ({
          value: m.code,
          label: m.display
        }));
      })
      .addCase(getAspectRatiosAction.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export { reducer };
export default actions;

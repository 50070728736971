import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0;

      .modal-content {
        .body {
          padding: 15px 30px 30px 30px;

          .container {
            display: flex;
            gap: 12px;
            justify-content: center;
            .icon-success {
              svg {
                width: 28px;
                height: 28px;
                color: #075d0f;
              }
            }

            .icon-exclamation {
             svg {
              width: 28px;
              height: 28px;
             } 
            }

            .content {
              font-weight: 400;
              font-size: 16px;
              text-align: center;
            }
          }

          .action-box {
            padding-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            .action-button {
              cursor: pointer;
              margin-right: 15px;
              font-weight: bold;
              height: 35px;
              background: #e5e7eb;
              border: 0 solid #e5e7eb;
              box-sizing: border-box;
              border-radius: 9999px;
              padding: 0px 33px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
`;

export const IconCloseConfirmableStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px 0px 0px;
  cursor: pointer;
  span {
    font-size: 20px;
    opacity: 0.2;
    margin-right: 5px;
  }
`;

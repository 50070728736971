import { Form, notification } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import queryString from 'query-string';

import { useAppDispatch } from '../../stores';
import Storage from '../../utils/storage';
import Request from '../../utils/request';
import {
  authLoginAction,
  getUserInfoAction,
  verifyEmailAuthAction,
} from '../../stores/screens/auth/auth.action';
import { useWeb3Auth } from '../../contexts/web3auth';

import FormControl from '../form-control';
import MaskLoading from '../mask-loading';
import { OTPInputComponent } from '../otp-input';
import ButtonImage from '../button-img';
import { Images } from '../../theme';
import Confirmable from '../confirmable';

import { ModalVerifyCodeStyled } from './styled';

export const ModalVerifyCode = ({
  data,
  controlVerifyCode,
  errorVerifyCode,
  isValidVerifyCode,
  handleSubmitVerifyCode,
  triggerVerifyCode,
  getValueVerifyCode,
  setCounter,
  counter,
  isCheckParam,
  closeModal = () => {},
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    setAccessToken,
    setUserInfo,
    setUserId,
    setCurrentAccount,
    setExpiredToken,
    setRefreshToken,
  } = useWeb3Auth();

  const timerIdRef = useRef(null);

  const startCountdown = (time) => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
    }

    if (time) setCounter(time);
    const tick = () => {
      setCounter(prevCounter => {
        if (prevCounter <= 0) {
          clearInterval(timerIdRef.current);
          return 0;
        }
        return prevCounter - 1;
      });
    };
    timerIdRef.current = setInterval(tick, 1000);
  };

  useEffect(() => {
    startCountdown();
    return () => clearInterval(timerIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.isShow && counter === 0) {
      generateCode();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.isShow]);

  // useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  const generateCode = () => {
    if (counter) return;
    getLoginCode();
  };

  const getLoginCode = async () => {
    try {
      dispatch(
        verifyEmailAuthAction({
          params: { email: data?.data },
          callback: receiveResponseLoginCode,
        })
      );
    } catch (error) {
      notification.error({
        message: error?.error,
        duration: 5,
      });
    }
  };
  const receiveResponseLoginCode = (result) => {
    const time = result?.data?.expiredAt - moment.utc().unix() - 1
    setCounter(time);
    startCountdown(time)
  };

  const onSubmit = (data) => {
    triggerVerifyCode();
    if (isValidVerifyCode) {
      requestVerifyCode(data);
    }
  };

  const setData = (data, user) => {
    setAccessToken(data.access_token);
    setExpiredToken(data.expired_at);
    setRefreshToken(data.refresh_token);
    setUserInfo(user);
    setUserId(data.user_id);
    setCurrentAccount(data.public_address);
    Storage.set('PUBLIC_ADDRESS', data.public_address);
    Storage.set('ACCESS_TOKEN', data.access_token);
    Storage.set('USER_ID', data.user_id);
    Storage.set('EXPIRED_TOKEN', data.expired_at);
    Storage.set('REFRESH_TOKEN', data.refresh_token);
    Request.setAccessToken(data.access_token);
    navigate('/');
  };

  const requestVerifyCode = async (value) => {
    MaskLoading.open({});
    const source = queryString.parse(isCheckParam)?.source;
    const gsid = queryString.parse(isCheckParam)?.gsid;
    const receiveMsg = isCheckParam?.includes('receiveMsg')
      ? queryString.parse(isCheckParam).receiveMsg
      : Storage.get('IS_IFRAME');

    const { payload } = await dispatch(
      authLoginAction({
        codeLogin: value.code.toUpperCase(),
        email: data.data,
        source: isCheckParam?.includes('source') ? source : undefined,
        gsid: isCheckParam?.includes('gsid') ? gsid : undefined,
        receiveMsg: receiveMsg ? Boolean(receiveMsg) : undefined,
      })
    );
    if (payload?.status) {
      if (receiveMsg) {
        Storage.remove('IS_IFRAME');
        const extToken = payload.data?.access_token;
        window.parent.postMessage({ token: extToken }, '*');

        return;
      } else if (isCheckParam?.includes('source')) {
        Confirmable.open({
          content: (
            <div style={{ textAlign: 'start', marginTop: '20px' }}>
              Login successful. <br /> Please close this window to continue
              using the application
            </div>
          ),
          hideCancelButton: true,
          isShowIconSuccess: true,
          acceptButtonText: 'Close',
          width: 515,
          onOk: closeModal,
        });
      } else {
        const user = await dispatch(
          getUserInfoAction({ accessToken: payload?.data?.access_token })
        );
        setData(payload?.data, user?.payload?.data);
      }
      Storage.remove('ExpiredTimeVRcode');
      Storage.remove('VRcode');
      Storage.remove('ExpiredTimeDeviceCode');
      Storage.remove('DeviceCode');
      Storage.remove('ExpiredTimeLoginCode');
    } else {
      if (payload?.error === 'CODE_LOGIN_BY_EMAIL_NOT_EXISTS') {
        notification.error({
          message: 'Invalid code',
          duration: 5,
        });
      } else if (payload?.error === 'CODE_LOGIN_BY_EMAIL_OVER_TIME') {
        notification.error({
          message: 'Code Login by email over time',
          duration: 5,
        });
      } else if (payload?.error === 'INVALID_PARAMS') {
        notification.error({
          message: 'Invalid code',
          duration: 5,
        });
      } else if (payload?.error === 'ERROR_SYSTEM_RETRY') {
        Confirmable.open({
          content: 'System error, please try again!',
          hideCancelButton: true,
          isShowIconExclamation: true,
          acceptButtonText: 'Close',
          onOk: closeModal,
        });
      } else if (
        payload?.error === "ERR_USER_BLOCKED_BY_WRONG_PASSWORD_TRANSACTION"
      ) {
        notification.error({
          message:
            "Your wallet has been locked due to an incorrect transaction password.",
          duration: 5,
        });
      }  else {
        notification.error({
          message: payload?.message,
          duration: 5,
        });
      }
    }
    MaskLoading.close();
  };

  return (
    <ModalVerifyCodeStyled>
      <Form onSubmit={handleSubmitVerifyCode(onSubmit)}
        onKeyDown={(e) => { 
          if (e.key === 'Enter') { 
            e.preventDefault();
            handleSubmitVerifyCode(onSubmit)();
          } 
        }}
      >
        <FormControl>
          <div className="title">Login</div>
          <div className="sub-title">
            Enter the following code to continue login:
          </div>
          <div className="box-generate-code">
            <Controller
              name="code"
              control={controlVerifyCode}
              render={({ field: { onChange, value } }) => (
                <OTPInputComponent
                  otp={value || ''}
                  setOtp={onChange}
                ></OTPInputComponent>
              )}
            />
            {errorVerifyCode?.code && (
              <p className="error">{errorVerifyCode.code.message}</p>
            )}
          </div>
        </FormControl>
        <div className="action-box">
          <ButtonImage
            onClick={() => generateCode()}
            src={Images.CANCEL_BTN}
            height={50}
            className="btn-submit"
            type="blue"
          >
            <div className={`generate ${counter && 'disabled'}`}>
              Generate &nbsp;<span>{counter ? `(${counter}s)` : ''}</span>
            </div>
          </ButtonImage>
          <ButtonImage
            onClick={() => onSubmit(getValueVerifyCode())}
            src={Images.GENERATE_BTN}
            height={50}
            className="btn-submit"
          >
            Submit
          </ButtonImage>
        </div>
        <div className="content-verify">
          Please check your email {data?.data} to get the code
        </div>
      </Form>
    </ModalVerifyCodeStyled>
  );
};

import React from 'react';
import { Images } from '../../../theme';
import { PricingPlanWrapper } from './styled';
import { useAppSelector } from '../../../stores';

const PricingPlan = () => {
  const { tablePricing } = useAppSelector((state) => state.advertise);

  const roundPoint = (point) => {
    return Math.round(point * 10 * 10) / 100;
  };

  return (
    <PricingPlanWrapper>
      <h3 className="title">Pricing plan</h3>

      <div className="position-relative">
        <div className="dp-flex justify-center img-bg">
          <img src={Images.TABLE_FRAME_2} alt="frame" className="table-frame" />
          <img
            src={Images.LIGHT_DOT}
            alt="frame"
            className="light-dot light-dot-01"
          />
          <img
            src={Images.LIGHT_DOT}
            alt="frame"
            className="light-dot light-dot-02"
          />
          <img
            src={Images.LIGHT_DOT}
            alt="frame"
            className="light-dot light-dot-03"
          />
        </div>
        <div className="point-content">
          <table className="table-pricing">
            <thead>
              <tr>
                <th>Ads Type</th>
                {[...Array(24).keys()].map((hour) => (
                  <th key={hour} className="txt-center">
                    {hour.toString().padStart(2, '0')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tablePricing &&
                tablePricing.map((item) => (
                  <tr
                    key={item.category}
                    className={`${item?.category?.toLowerCase()}`}
                  >
                    <td>{item?.category?.toLowerCase()}</td>
                    {[...Array(24).keys()].map((hour) => (
                      <td key={hour} className="txt-center">
                        {roundPoint(
                          item?.prices?.find((price) => price.hour === hour)
                            .price ?? 0
                        )}
                        <br />
                        point
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </PricingPlanWrapper>
  );
};

export default PricingPlan;

import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseOutlined } from '@ant-design/icons';

import * as api from '../../api';
import Storage from '../../utils/storage';
import { useWeb3Auth } from '../../contexts/web3auth';

import { Images, Videos } from '../../theme';
import Header from '../../layouts/Header';
import Section from '../../components/section';
import CardLogin from '../../components/card-login';
import ButtonImage from '../../components/button-img';
import { FormRegister } from './form-register';
import queryString from 'query-string';

import { Content, IconCloseModalStyled, ModalOpenLoginStyled } from './styled';

const schema = Yup.object().shape({
  email: Yup.string().required('This field is required').email('Email invalid'),
});

export default function Register({ children, className, ...props }) {
  const { accessToken, currentAccount, handleLogout, userInfo } = useWeb3Auth();

  const [isOpenPopupLogin, setIsOpenPopupLogin] = useState(false);

  const { search } = useLocation();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    getValues,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const handleAsGuest = () => {
    api.loginAsGuest().then((response) => {
      if (response) {
        navigate('/', { state: { randomKey: response.data } });
      }
    });
  };

  useEffect(() => {
    if (!isOpenPopupLogin) {
      reset();
    }
  }, [isOpenPopupLogin, reset]);

  useEffect(() => {
    if (search?.includes('receiveMsg')) {
      Storage.set('IS_IFRAME', queryString.parse(search).receiveMsg);
    }
  }, [search]);

  return (
    <Content {...props} className={classNames(className)}>
      <Header
        accessToken={accessToken}
        publicAddress={currentAccount}
        userInfo={userInfo}
        onLogout={handleLogout}
      />
      <Section className="register-top">
        <div className="video-top">
          <video
            id="video"
            autoPlay
            loop
            muted
            playsInline
            type="video/mp4"
            src={Videos.VIDEO_HOME}
          />
        </div>

        <div className="logo-login">
          {/* <img src={Images.LOGO_LOGIN} alt="Logo MCC" /> */}
          <div className="title">Sign in an Account</div>
        </div>

        <div className="form-register-container">
          <CardLogin
            title="Login as email"
            button={
              <ButtonImage
                height={58}
                fontSize={14}
                fontWeight={700}
                textTransform={'initial'}
                onClick={() => setIsOpenPopupLogin(true)}
                type="blue"
              >
                Continue
              </ButtonImage>
            }
            image={Images.ICON_EMAIL}
            positionImage="left"
          />

          {/* {(!search?.includes('ref') && !search?.includes('source')) && (
            <CardLogin
              title="Continue as guest"
              subTitle="*Limited functions"
              button={
                <ButtonImage
                  height={58}
                  fontSize={14}
                  fontWeight={700}
                  textTransform={'initial'}
                  onClick={handleAsGuest}
                  type="brown"
                >
                  Play as guest
                </ButtonImage>
              }
              image={Images.USER_ICON}
              positionImage="left"
            />
          )} */}
        </div>
      </Section>

      <ModalOpenLoginStyled
        onCancel={() => setIsOpenPopupLogin(false)}
        open={isOpenPopupLogin}
        width={392}
        footer={null}
        getContainer={false}
        closable={false}
        centered
      >
        <IconCloseModalStyled onClick={() => setIsOpenPopupLogin(false)}>
          <CloseOutlined />
        </IconCloseModalStyled>
        <FormRegister
          control={control}
          errors={errors}
          isValid={isValid}
          handleSubmit={handleSubmit}
          trigger={trigger}
          getValues={getValues}
          isCheckParam={search}
          navigate={navigate}
        ></FormRegister>
      </ModalOpenLoginStyled>
    </Content>
  );
}

import { createSlice } from '@reduxjs/toolkit';

import {
  loginAction,
  getUserInfoAction,
  verifyEmailAction,
  retrieveAccountAction,
  refreshTokenAction,
  verifyEmailAuthAction,
  authLoginAction,
  refreshTokenLoginAction,
  redirectMccBetAction
} from './auth.action';

const initialState = {
  isLoading: false,
  error: null,

  auth: null,
  userInfo: null,
  verifyEmail: null,
  retrieveAccount: null,
  refreshToken: null,
  verifyEmailAuth: null,
  authToken: null,
  refreshTokenLogin: null,
  tokenMccBet: null,
};

const { actions, reducer } = createSlice({
  name: 'events_slice',
  initialState,
  reducers: {
    resetState: (state) => {
      state.auth = null;
      state.userInfo = null;
      state.verifyEmail = null;
      state.retrieveAccount = null;
      state.refreshToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload.data;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getUserInfoAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserInfoAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userInfo = action.payload.data;
      })
      .addCase(getUserInfoAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(verifyEmailAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verifyEmailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.verifyEmail = action.payload.data;
      })
      .addCase(verifyEmailAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(retrieveAccountAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(retrieveAccountAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.retrieveAccount = action.payload.data;
      })
      .addCase(retrieveAccountAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(refreshTokenAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(refreshTokenAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.refreshToken = action.payload.data;
      })
      .addCase(refreshTokenAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(verifyEmailAuthAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verifyEmailAuthAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.verifyEmailAuth = action.payload.data;
      })
      .addCase(verifyEmailAuthAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(authLoginAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(authLoginAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.authToken = action.payload.data;
      })
      .addCase(authLoginAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(refreshTokenLoginAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(refreshTokenLoginAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.refreshTokenLogin = action.payload.data;
      })
      .addCase(refreshTokenLoginAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(redirectMccBetAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(redirectMccBetAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tokenMccBet = action.payload.data;
      })
      .addCase(redirectMccBetAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });;
  },
});

export { reducer };
export default actions;

import styled from 'styled-components';

export const TitleStyled = styled.div`
  display: flex;
  font-size: 32px;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #fff;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 767.98px) {
    font-size: 16px;
    gap: 10px;

    >img {
      width: 100px;
    }
  }

  @media (min-width: 768px) {
    gap: 1rem;
  }
`

import React, { useEffect, useMemo, useState } from 'react';
import { AdvertiseTableWrapper, TooltipWrapper } from './styled';
import { Tooltip, Empty, Checkbox } from 'antd';
import { totalAmount } from '../../../utils/number';
import { useAppSelector } from '../../../stores';

const AdvertiseTable = ({
  dataTable,
  tablePricing,
  handleShowModalDetail,
  isCreate,
  setTotalAmount,
  setTotalTime,
  renderTimeFrame,
  resetSelectedItem,
  setItemsSelectedFooter,
}) => {
  const [itemsSelected, setItemsSelected] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const { tableAdvertiseLoading } = useAppSelector((state) => state.advertise);
  const rowData = useMemo(() => {
    if (dataTable && dataTable.length) {
      return dataTable?.flatMap((item) => {
        const newArr = item?.advertisement_frame_timeline_useds?.map(
          (item2) => ({
            ...item2,
            building: item.building,
          })
        );

        return newArr;
      });
    }
    return [];
  }, [dataTable]);

  const convertString = (string) => {
    return string ? string.toLowerCase() : '';
  };

  const setItem = (id, hour, category, price, building) => {
    const selectedSet = new Set(
      itemsSelected.map((obj) => JSON.stringify(obj))
    );

    const itemString = JSON.stringify({ id, hour, category, building, price });
    if (selectedSet.has(itemString)) {
      selectedSet.delete(itemString);
    } else {
      selectedSet.add(itemString);
    }

    const updatedItems = [...selectedSet].map((str) => JSON.parse(str));

    setItemsSelected(updatedItems);
    setItemsSelectedFooter(updatedItems);
  };

  const selectedRow = (event, item) => {
    const { checked } = event.target;

    if (checked) {
      const selectedSet = new Set(
        itemsSelected.map((obj) => JSON.stringify(obj))
      );
      const usedHours = item?.time_frame_used.map((item) => item.time_frame);

      const newItem = [];
      for (let i = 0; i <= 23; i++) {
        if (!usedHours.includes(i)) {
          const newItemObject = {
            id: item?.advertisement_frame?.id,
            hour: i,
            category: item?.advertisement_frame?.category,
            building: item?.building?.name,
            price: tablePricing?.filter((cate) => cate?.category === item?.advertisement_frame?.category)[0]?.prices?.find((price) => price?.hour === i)?.price ?? 0
          };
          if (!selectedSet.has(JSON.stringify(newItemObject))) {
            newItem.push(newItemObject);
          }
        }
      }

      setItemsSelected((prevItemsSelected) =>prevItemsSelected.concat(newItem));
      setItemsSelectedFooter((prevItemsSelected) =>
        prevItemsSelected.concat(newItem)
      );
    } else {
      setItemsSelected(itemsSelected.filter((obj) => obj.id !== item?.advertisement_frame?.id));
      setItemsSelectedFooter(itemsSelected.filter((obj) => obj.id !== item?.advertisement_frame?.id));
    }
  };

  const handleCheckboxChange = (event, item) => {
    const updatedCheckboxes = { ...checkboxes };
    updatedCheckboxes[item?.advertisement_frame?.id] = event.target.checked;
    setCheckboxes(updatedCheckboxes);
  };

  useEffect(() => {
    if (isCreate) {
      setTotalTime(itemsSelected.length ?? 0);
      const tmpItemSelected = itemsSelected;
      const amount = totalAmount(tmpItemSelected);
      setTotalAmount(amount);
      renderTimeFrame?.(tmpItemSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsSelected, isCreate]);

  useEffect(() => {
    setItemsSelected([]);
    setItemsSelectedFooter && setItemsSelectedFooter([]);
    setCheckboxes({});
  }, [resetSelectedItem]);

  return (
    <AdvertiseTableWrapper>
      <div className={`box-table ${isCreate ? 'h-337' : ''}`}>
        <table className="table-advertise">
          <thead>
            <tr>
              <th
                style={{
                  // width: '300px',
                  borderRight: '1px solid #156df280',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <div className="t-header">Building</div>
              </th>
              <th
                style={{
                  width: '100px',
                  borderRight: '1px solid #156df280',
                  borderLeft: '1px solid #156df280',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <div className="t-header">Ads Type</div>
              </th>
              {isCreate && (
                <th
                  style={{
                    width: '30px',
                    borderRight: '1px solid #156df280',
                    borderLeft: '1px solid #156df280',
                  }}
                >
                  <div className="t-header">All</div>
                </th>
              )}
              {[...Array(24).keys()].map((hour) => (
                <th key={hour} className="txt-center" style={{ width: '40px' }}>
                  <div className="t-header">
                    <span className="color-yellow">{hour.toString().padStart(2, '0')}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          {!tableAdvertiseLoading && (
            <tbody>
              {rowData?.length ? (
                rowData?.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        borderRight: '1px solid #156df280',
                        paddingLeft: '5px',
                        fontSize: '14px',
                      }}
                    >
                      {item?.building?.name}_{item?.building?.building_code}_
                      {item?.advertisement_frame?.code_frame}
                    </td>
                    <td
                      style={{
                        borderRight: '1px solid #156df280',
                        textTransform: 'capitalize',
                        paddingLeft: '5px',
                        fontSize: '14px',
                      }}
                    >
                      {convertString(item?.advertisement_frame?.category)}
                    </td>
                    {isCreate && (
                      <td
                        style={{
                          borderRight: '1px solid #156df280',
                          textTransform: 'capitalize',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={
                            checkboxes[item?.advertisement_frame?.id] || false
                          }
                          onChange={(event) => {
                            handleCheckboxChange(event, item);
                            selectedRow(event, item);
                          }}
                        ></Checkbox>
                      </td>
                    )}
                    {[...Array(24).keys()].map((hour) => {
                      const frameUsed = item?.time_frame_used?.find(
                        (item) => item?.time_frame === hour
                      );
                      const isSold = frameUsed ? true : false;
                      const ownerExists = frameUsed && frameUsed.owner;
                      const tooltipContent = (
                        <RenderTooltip
                          item={item}
                          hourItem={hour}
                          tablePricing={tablePricing}
                          isSold={isSold}
                          ownerExists={ownerExists}
                          handleShowModalDetail={handleShowModalDetail}
                        />
                      );

                      return (
                        <td key={hour} className="center">
                          {!isCreate ? (
                            <Tooltip
                              placement="topRight"
                              color="#051847"
                              overlayInnerStyle={{
                                border: "1px solid #156DF280",
                                borderRadius: "10px",
                                width: "fit-content",
                              }}
                              title={tooltipContent}
                              autoAdjustOverflow={true}
                              destroyTooltipOnHide={true}
                              zIndex={11}
                            >
                              <div
                                className={`frame-chosen ${
                                  !frameUsed
                                    ? ""
                                    : ownerExists
                                    ? "frame-not-used"
                                    : "frame-used"
                                }`}
                              ></div>
                            </Tooltip>
                          ) : (
                            <div
                              className={`frame-chosen ${
                                itemsSelected.some((obj) => {
                                  return (
                                    obj.id === item?.advertisement_frame?.id &&
                                    obj.hour === hour
                                  );
                                }) && "frame-selected"
                              } ${
                                !frameUsed
                                  ? ""
                                  : ownerExists
                                  ? "frame-not-used"
                                  : "frame-used"
                              }`}
                              onClick={() => {
                                if (isSold) return;
                                const price =
                                  tablePricing
                                    ?.filter(
                                      (cate) =>
                                        cate?.category ===
                                        item?.advertisement_frame?.category
                                    )[0]
                                    ?.prices?.find(
                                      (price) => price?.hour === hour
                                    )?.price ?? 0;
                                setItem(
                                  item?.advertisement_frame?.id,
                                  hour,
                                  item?.advertisement_frame?.category,
                                  price,
                                  item?.building?.name
                                );
                              }}
                            ></div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="26">
                    {isCreate ? (
                      <div className="no-data">No data</div>
                    ) : (
                      <div className="no-data-2">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </AdvertiseTableWrapper>
  );
};

const RenderTooltip = ({
  item,
  hourItem,
  tablePricing,
  isSold,
  handleShowModalDetail,
  ownerExists,
}) => {
  return (
    <TooltipWrapper>
      <div className="row">
        <span className="title">Position:</span>
        <span className="name">
          {item?.building?.name}_{item?.building?.building_code}_
          {item?.advertisement_frame?.code_frame}
        </span>
      </div>
      <div className="row">
        <span className="title">Ads Type:</span>
        <span className="type">
          {item?.advertisement_frame?.category?.toLowerCase()}
        </span>
      </div>
      <div className="row">
        <span className="title">Time:</span>
        <span
          className="btn-view"
          onClick={() =>
            handleShowModalDetail(item?.advertisement_frame?.id, hourItem)
          }
        >
          View
        </span>
      </div>
      <div className="row">
        <span className="title">Price:</span>
        <span className="price">
          {tablePricing
            ?.filter(
              (cate) => cate?.category === item?.advertisement_frame?.category
            )[0]
            .prices?.find((price) => price?.hour === hourItem)?.price ?? 0}{' '}
          MCC Point
        </span>
      </div>
      <div className="row">
        <span className="title">Status:</span>
        {isSold ? (
          <span className="sold">
            {ownerExists ? 'Bought by yourself' : 'Sold out'}
          </span>
        ) : (
          <span className="available">Available</span>
        )}
      </div>
    </TooltipWrapper>
  );
};

export default AdvertiseTable;

import { AxiosClient } from '../../../configs/axios/axios';

const apiVersion = process.env.REACT_APP_VERSION;

const POINTS_API = {
  getPointsTop: async (params) => {
    const response = await new AxiosClient().get(`${apiVersion}/points/top/${params.limit}`);

    return response;
  },
};

export default POINTS_API;

import React from 'react';
import classNames from 'classnames';

/** component */
import Section from '../../section';
import Container from '../../container';
import TitleSection from '../../title-section';
import ButtonImage from '../../button-img';

/** asset */
import { Images, Videos } from '../../../theme';
import { Content } from './styled';

export default ({ children, className, ...props }) => (
  <Content {...props} className={classNames(className)}>
    <Section className='home-communities'>
      <div className='body'>
        <TitleSection className='title' data-aos="fade-down">COMMUNITIES</TitleSection>

        <div className='content'>
          <Container className='top'>
            <div className='description' data-aos="fade-down">
              Join our community on Discord and Telegram to connect and share
              with like-minded individuals. Let's build an engaging and
              knowledgeable environment together!
            </div>
            <div className='btn-actions' data-aos="fade-up">
              <ButtonImage type='blue' name='Telegram' height={70}>
                <img src={Images.ICON_TELEGRAM} alt='Button Telegram' />
                Telegram
              </ButtonImage>
              <ButtonImage name='Discord' height={70}>
                <img src={Images.ICON_DISCORD} alt='Button Discord' />
                Discord
              </ButtonImage>
            </div>
          </Container>

          <img
            src={Images.BG_COMMUNITIES}
            className='bg-img'
            alt='MCC COMMUNITIES'
          />
          <img
            src={Images.BG_MB_COMMUNITIES}
            className='bg-img_mb'
            alt='MCC COMMUNITIES'
          />
        </div>
      </div>
    </Section>
  </Content>
);

import * as api from '../api';
import Storage from '../utils/storage';

export const useJoinGame = () => {
  const handleAsGuest = () => {
    redirectToGameServer({ access_token: undefined });
  };

  const handleJoinGame = async () => {
    redirectToGameServer({
      access_token: Storage.get('ACCESS_TOKEN'),
    });
  };

  const redirectToGameServer = ({ access_token }) => {
    const gameServerUrl = api.buildGameServerRedirectUrl({
      access_token,
    });
    window.location = gameServerUrl;
  };

  return { handleAsGuest, handleJoinGame };
};

export default useJoinGame;

import { AxiosClient } from '../../../configs/axios/axios';
import { LOCAL_STORAGE_KEY } from '../../../constants';
import Storage from '../../../utils/storage';

const apiVersion = process.env.REACT_APP_VERSION;

const AdvertiseApi = {
  tablePricingPlan: async (params) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisement-placements/plan-prices`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
        params: params,
      }
    );

    return result;
  },

  tableAdsBooking: async (params) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisement-frames`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
        params: params,
      }
    );

    return result;
  },

  listBuilding: async (params) => {
    const result = await new AxiosClient().get(`${apiVersion}/buildings`, {
      headers: {
        'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
        'Content-Type': 'application/json',
        // 'TimeZone': getTimeZone()
      },
      params: params,
    });

    return result;
  },

  getDetailAdvertise: async (params) => {
    const { id, timeFrame, ...rest } = params;
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisement-frames/${id}/time-frame/${timeFrame}`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
        params: rest,
      }
    );

    return result;
  },

  createAdvertise: async (payloads) => {
    const result = await new AxiosClient().post(
      `${apiVersion}/advertisement-placements`,
      payloads,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
      }
    );

    return result;
  },

  getHistoriesAdvertise: async (params) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisement-placements`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        },
        params
      }
    );

    return result;
  },
  getHistoriesAdvertiseDetail: async ({id, transactionId}) => {
    const result = await new AxiosClient().get(
      `${apiVersion}/advertisement-placements/advertisement/${id}/transaction/${transactionId}`,
      {
        headers: {
          'x-access-token': Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
          'Content-Type': 'application/json',
        }
      }
    );

    return result;
  },
};



export default AdvertiseApi;

import styled from 'styled-components';
import { Table } from 'antd';
import 'antd/es/table/style/css';

export const TableStyled = styled(Table)`
  .ant-table-content {
    background: #051847;
    color: #fff;
    padding-bottom: 10px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 9px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: unset;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #2b6ae680;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #2b6ae680;
  }

  .ant-table-thead > tr > th {
    background: #2b6ae680;
    color: #fff;
    border: unset;

    &:first-child {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: unset;
  }

  .ant-table-tbody > tr > td {
    border-bottom-color: #2b6ae680;
  }

  .ant-pagination-item {
    background: #051847;
    border: unset;
    border-radius: 10px;
    a {
      color: #fff !important;
    }
  }

  .ant-pagination-item-active {
    background: #2b6ae680;
    color: #fff;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    border-radius: 10px;
    background: #051847;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background: #051847;
    border-radius: 10px;
    color: #fff;
    border-color: #2b6ae6;
  }

  .ant-pagination-prev.ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-next.ant-pagination-disabled .ant-pagination-item-link {
    border-color: #323b49;
    color: #a0aec0;
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: transparent;
  }

  .ant-empty-normal {
    color: #fff;
  }

  .anticon{
    color: inherit;
  }
`;

let accessToken = null

class Request {
  static create(options) {
    return new Request(options)
  }

  static setAccessToken(token) {
    accessToken = token
  }

  static getAccessToken() {
    return accessToken
  }

  static removeAccessToken() {
    accessToken = null
  }

  constructor(options) {
    this._options = options
  }

}

export default Request

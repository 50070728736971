import React from 'react';
import { Space } from 'antd';

import { Total, TotalDescription } from './styled';


const TotalComponent = ({ src, title, value }) => {

  return (
    <Space>
      <Total>
        <img src={src} style={{ width: 35 }} alt="icon-down" />
        <TotalDescription>
          <span style={{ fontSize: 14, color: '#757B8C' }}>{title}</span>
          <span style={{ fontSize: 20, fontWeight: 500 }}>{value}</span>
        </TotalDescription>
      </Total>
    </Space>
  );
};

export default TotalComponent;

import styled from "styled-components";
import { Colors } from '../../theme'

export const ContentStyled = styled.div`
  width: 100%;
  flex: 1;
  background: linear-gradient(270deg, #052B86 0%, #03153F 100%);
`;

export const Container = styled.div`
  max-width: 1300px;
  margin: 120px auto;
  @media (max-width: 1300px) {
    padding: 24px;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }

  @media (max-width: 575px) {
    margin-bottom: 10px;
    margin-top: 90px;
  }
`;

export const EventStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px 16px;
  margin: 50px auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    margin: 22px auto;
    margin-bottom: 0;
  }
`
import styled from 'styled-components';
import { Images } from '../../theme';
import { Input } from 'antd';

export const ChatBotStyled = styled.div`
  position: fixed;
  bottom: 45px;
  right: 100px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #0168ff;
  z-index: 1000;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid #fff;
    transform: scale(0.9);
  }
  &::after {
    position: absolute;
    content: '';
    
    transform: scale(0.5);
    background: url(${props => props.isOpen ? Images.ICON_CLOSE : Images.MESSAGE_ICON});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 70px;
    height: 70px;
  }

  @media (max-width: 575px) {
    right: 24px;
  }
`;
export const ChatBoxStyled = styled.div`
  position: fixed;
  bottom: 130px;
  right: 100px;
  width: 356px;
  height: 516px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  /* cursor: pointer; */
  z-index: 1000;

  .input-chatbox::placeholder {
    font-weight: 500;
  }

  @media (max-width: 575px) {
    right: 24px;
    width: calc(100% - 24px - 20px);
  }
`;
export const ChatBoxHeaderStyled = styled.div`
  font-size: 18px;
  font-weight: 700;
  background-color: #264498;
  padding: 18px 20px;
`;
export const ChatBoxContentStyled = styled.div`
  flex: 1;
  background-color: #f4f5fa;
  padding: 18px 20px;
  max-height: 450px;
  overflow: auto;
  /* cursor: pointer; */

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }

  .our-messages {
    border-radius: 10px 10px 10px 0px;
    background: #356af0;
    color: #fff;
    padding: 10px 14px;
    max-width: 80%;
    font-size: 14px;
    font-weight: 500;
  }

  .our-messages-box {
    max-width: 80%;
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 10px;
    border: 1px solid #b0d0ff;
    background: #fff;
    font-size: 12px;
    font-weight: 400;
    color: #356af0;
    cursor: pointer;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        &.active {
          background: #e1ebff;
        }
        padding: 12px 14px;
      }

      li + li {
        border-top: 1px solid #b0d0ff;
      }

      .view-more {
        text-align: center;
        font-weight: 500;
        color: #356af0;
      }
    }
  }
  .your-messages {
    clear: both;
    border-radius: 10px 10px 0px 10px;
    background: #e1ebff;
    color: #356af0;
    padding: 10px 14px;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    float: right;
  }

  .answer,
  .your-messages {
    margin: 5px 0;
    white-space: pre-wrap;
    line-height: 1.2;
  }

  .answer {
    clear: both;
    border-radius: 10px 10px 10px 0px;
    background: #356af0;
    color: #fff;
    padding: 10px 14px;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    float: left;
  }
`;

export const ChatBoxFooterStyled = styled.div`
  background: #fff;
  box-shadow: 0px -2px 5px 0px rgba(134, 123, 123, 0.1);
  padding: 18px 20px;

  form {
    display: flex;
  }

  .btn-send {
    cursor: pointer;
  }
`;

export const InputStyled = styled(Input)`
  flex: 1;
  border: none;
  outline: none;
  color: #000;
  :focus,
  :active {
    outline: none;
  }
`;

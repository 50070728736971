import Aos from 'aos';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import Confirmable from '../../components/confirmable';
import MaskLoading from '../../components/mask-loading';
import { useWeb3Auth } from '../../contexts/web3auth';
import ScrollToTop from '../../utils/scrollToTop';
import ManagementHeader from './Header';
import { Content, ManagementWrapper } from './styled';
import SideBar from './SideBar';
import Storage from './../../utils/storage';
import { LOCAL_STORAGE_KEY } from '../../constants';

const ManagementLayout = () => {
  const { accessToken, currentAccount, userInfo } = useWeb3Auth();
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    if (!Storage.get(LOCAL_STORAGE_KEY.ACCESS_TOKEN)) {
      navigate('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManagementWrapper>
      <ManagementHeader
        accessToken={accessToken}
        publicAddress={currentAccount}
        userInfo={userInfo}
      />
      <div className="dp-flex">
        <SideBar />
        <Content>
          <ScrollToTop>
            <Outlet />
          </ScrollToTop>
        </Content>
      </div>

      <MaskLoading ref={(ref) => MaskLoading.setInstance(ref)} />
      <Confirmable ref={(ref) => Confirmable.setInstance(ref)} />
    </ManagementWrapper>
  );
};
export default ManagementLayout;

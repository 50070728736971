export const CHAIN_LIST = {
  1: {
    env: ['production'],
    chainId: 1,
    displayName: 'Ethereum Mainnet',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://etherscan.io/'],
    rpcUrls: [process.env.REACT_APP_RPC_URL_ETHEREUM, 'https://rpc.ankr.com/eth', 'https://ethereum.publicnode.com'],
  },
  11155111: {
    env: ['develop', 'local', 'staging'],
    chainId: 11155111,
    displayName: 'Sepolia Testnet',
    currency: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
    rpcUrls: [process.env.REACT_APP_RPC_URL_ETHEREUM, 'https://eth-sepolia-public.unifra.io', 'https://rpc2.sepolia.org'],
  },
  137: {
    env: ['production'],
    chainId: 137,
    displayName: 'Polygon Mainnet',
    currency: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
    blockExplorerUrls: ['https://polygonscan.com/'],
    rpcUrls: [process.env.REACT_APP_RPC_URL_POLYGON, 'https://rpc.ankr.com/polygon', 'https://polygon-rpc.com'],
  },
  80001: {
    env: ['develop', 'local', 'staging'],
    chainId: 80001,
    displayName: 'Mumbai Testnet',
    currency: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    rpcUrls: [
      process.env.REACT_APP_RPC_URL_POLYGON,
      'https://rpc.ankr.com/polygon_mumbai',
      'https://polygon-testnet.public.blastapi.io',
    ],
  },
};

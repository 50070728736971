import { createAsyncThunk } from '@reduxjs/toolkit';

import ManagementApi from './management.api';
import { parseParamsToQueryString } from '../../../utils/common';

export const listRequestManagementAction = createAsyncThunk(
  'ListRequestManagement',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const stringified = parseParamsToQueryString(params)
      const response = await ManagementApi.listRequestManagement(stringified);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const preSignUrlAction = createAsyncThunk(
  'PreSignUrl',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      return await ManagementApi.preSignUrl(params);
    } catch (error) {
      return rejectWithValue({ message: error?.message, error: error?.error });
    }
  }
);

export const createRequestAction = createAsyncThunk(
  'CreatedRequest',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      return await ManagementApi.createRequest(params);
    } catch (error) {
      console.log("error", error)
      return rejectWithValue({ message: error?.message, error: error?.error });
    }
  }
);

export const uploadToS3Action = createAsyncThunk(
  'PreSignUrl',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      return await ManagementApi.uploadToS3(params);
    } catch (error) {
      return rejectWithValue({ message: error?.message, error: error?.error });
    }
  }
);

export const deleteRequestAction = createAsyncThunk(
  'DeleteRequest',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      return await ManagementApi.deleteRequest(params);
    } catch (error) {
      return rejectWithValue({ message: error?.message, error: error?.error });
    }
  }
);

export const detailRequestAction = createAsyncThunk(
  'DetailRequest',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      return await ManagementApi.detailRequest(params);
    } catch (error) {
      return rejectWithValue({ message: error?.message, error: error?.error });
    }
  }
);

export const getAspectRatiosAction = createAsyncThunk(
  'getAspectRatios',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      return await ManagementApi.getAspectRatios(params);
    } catch (error) {
      return rejectWithValue({ message: error?.message, error: error?.error });
    }
  }
);

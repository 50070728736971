import styled from 'styled-components';
import { Images } from '../../theme';

export const Content = styled.div`
  .login-top {
    position: relative;

    .video-top {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
      background-image: linear-gradient(360deg, #052b86 0%, #03153f 100%);

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .logo-login {
      position: absolute;
      left: 0;
      right: 0;
      top: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
      }
    }

    .form-login {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 28%;
      display: flex;
      justify-content: center;
      gap: 61px;
    }

    .form-logged {
      position: absolute;
      left: 0;
      right: 0;
      top: 80%;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;

      .start-game {
        cursor: pointer;
        width: 19%;
        height: 12%;
        background-image: url(${Images.START_GAME});
      }

      .title {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        color: #fff;
      }
    }
  }

  @media (max-width: 968px) {
    .form-login {
      gap: 8px !important;
      bottom: initial;
      top: 28%;
    }
  }
  @media screen and (min-width: 575px) and (max-width: 968px) {
    .form-login {
      gap: 8px !important;
      bottom: initial;
      top: 28%;
    }
  } 
  @media screen and (min-height: 575px) and (max-height: 850px) {
    .form-login {
      gap: 8px !important;
      bottom: initial;
      top: 28%;
    }
  }
`;

export const EmailInValidStyled = styled.div`
  span {
    font-weight: 600;
  }
`

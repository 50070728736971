import React from 'react'
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames'

/** component */
import Section from '../../section'
import Container from '../../container'
import TitleSection from '../../title-section'
import CardFQA from '../../card-faq'
import ButtonImage from '../../button-img'

/** asset */
import { Content } from './styled'

export default ({ children, className, ...props }) => {
  const navigate = useNavigate();

  const listFAQs = [
    {
      type: 'Q_A',
      question: 'What is Meta Casino City?',
      answer: 'Meta Casino City (MCC) is a virtual casino city that exists in the metaverse, a shared online world where users can interact with each other and with digital objects. Metaverse casino games use blockchain technology to allow players to bet and win cryptocurrency.'
    }, {
      type: 'A_Q',
      question: 'Can I play Meta Casino City for free?',
      answer: 'Yes, Meta Casino City is totally free to join with various sets of free items. However, to fully enjoy the casino games or buy items on Marketplace, you will need to connect your wallet and deposit enough amount of crytocurrency.'
    }
  ]

  const renderListFAQs = listFAQs.map((item, index) => (
    <CardFQA
      key={index}
      type={index % 2 === 0 ? 'Q_A' : 'A_Q'}
      question={item.question}
      answer={item.answer}
    />
  ))

  return (
    <Content {...props} className={classNames(className)}>
      <Section className="home-faqs">
        <Container>
          <div className="body">
            <TitleSection className="title" data-aos="fade-down">FAQS</TitleSection>

            <div className='wrapper'>
              <div className='description' data-aos="fade-down">Everything You Need to Know</div>
              {renderListFAQs}
            </div>

            <div className='btn-actions' data-aos="zoom-in">
              <ButtonImage type="green" name="See more" height={70} onClick={() => navigate('faqs-list')}>
                See more
              </ButtonImage>
            </div>
          </div>
        </Container>
      </Section>
    </Content>
  )
}
import { createAsyncThunk } from '@reduxjs/toolkit';

import AdvertiseApi from './advertise.api';
import moment from 'moment';
import { parseParamsToQueryString } from '../../../utils/common';

export const tablePricingPlanAction = createAsyncThunk(
  'TablePricingPlan',
  async (params, { dispatch, rejectWithValue }) => {
    const daySelected =  moment(params?.endDate).diff(moment(params?.startDate), 'day') + 1
    const paramsSearch =  params;
    const stringified = parseParamsToQueryString(params)
    try {
      const response = await AdvertiseApi.tablePricingPlan(stringified);

      return {
        ...response,
        daySelected,
        paramsSearch
      };
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const tableAdsBookingAction = createAsyncThunk(
  'TableAdsBooking',
  async (params, { dispatch, rejectWithValue }) => {
    const stringified = parseParamsToQueryString(params)
    try {
      const response = await AdvertiseApi.tableAdsBooking(stringified);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const listBuildingAction = createAsyncThunk(
  'ListBuilding',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AdvertiseApi.listBuilding(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const getDetailAdvertiseAction = createAsyncThunk(
  'GetDetailAdvertise',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AdvertiseApi.getDetailAdvertise(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const createAdvertiseAction = createAsyncThunk(
  'CreateAdvertise',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AdvertiseApi.createAdvertise(params);

      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const getHistoriesAdvertiseAction = createAsyncThunk(
  'getHistoriesAdvertise',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const response = await AdvertiseApi.getHistoriesAdvertise(params);
      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

export const getHistoriesAdvertiseDetailAction = createAsyncThunk(
  'getHistoriesAdvertiseDetail',
  async ({id, transactionId}, { dispatch, rejectWithValue }) => {
    try {
      const response = await AdvertiseApi.getHistoriesAdvertiseDetail({id, transactionId});
      return response;
    } catch (err) {
      return rejectWithValue({ message: err?.message, error: err?.error });
    }
  }
);

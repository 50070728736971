import React, { useEffect, useRef, useState } from 'react';
import { Select, DatePicker } from 'antd';
import ButtonImage from './../../../components/button-img';
import { Images } from '../../../theme';
import { FilterWrapper } from './styled';
import { ADS_TYPE_OPTION } from '../../../constants';
import { useAppSelector } from '../../../stores';
import moment from 'moment';
// import BaseDatePicker from './../../../components/base-date-picker';

const AdvertiseFilter = ({
  onSearch,
  setPaginate,
  paginate,
  isCreate,
  advertiseOption,
  error,
  resetSelectedItem,
}) => {
  const { listBuilding, tableAdvertiseLoading } = useAppSelector((state) => state.advertise);
  const { aspectRatios } = useAppSelector((state) => state.requestManagement);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [advertiseOptions, setAdvertiseOptions] = useState([]);
  const refSelectAdvertisement = useRef();
  const refSelectBuilding = useRef();

  useEffect(() => {
    setBuildingOptions(
      listBuilding &&
        listBuilding.map((building) => ({
          value: building?.id,
          label: building?.name,
        }))
    );
  }, [listBuilding]);

  useEffect(() => {
    setAdvertiseOptions(
      advertiseOption &&
        advertiseOption?.map((item) => ({
          value: item?.id,
          label: item?.name,
          type: item?.type,
        }))
    );
  }, [advertiseOption]);

  const disabledStartDate = (current) => {
    const today = moment().startOf('day');

    if (paginate.endDate) {
      return (
        current &&
        !(current <= moment(paginate.endDate, 'YYYY-MM-DD').endOf('day') && current >= today)
      );
    } else {
      return current && current < today;
    }
  };

  const disabledEndDate = (current) => {
    const today = moment().startOf('day');

    if (paginate.startDate) {
      return current && current < moment(paginate.startDate, 'YYYY-MM-DD');
    } else {
      return current && current < today;
    }
  };

  const resetSelect = () => {
    setTimeout(() => {
      const activeOptions = document.querySelectorAll(
        '.ant-select-item.ant-select-item-option.ant-select-item-option-active'
      );
      activeOptions.forEach((option) => {
        option.classList.remove('ant-select-item-option-active');
      });
    }, 10);
  };

  useEffect(() => {
    if (refSelectBuilding.current) {
      refSelectBuilding.current.scrollTo(0);
    }
    if (refSelectAdvertisement.current) {
      refSelectAdvertisement.current.scrollTo(0);
    }
  }, [paginate]);

  useEffect(() => {
    resetSelect();
  }, [resetSelectedItem]);

  return (
    <FilterWrapper className={`dp-flex ${isCreate ? 'mt-30' : ''}`}>
      {isCreate && (
        <>
          <div className="form-group w-50">
            <label>
              Advertisement<span className="required">*</span>
            </label>

            <Select
              ref={refSelectAdvertisement}
              value={paginate?.advertisementId}
              options={advertiseOptions}
              listHeight={230}
              className={`select ${isCreate ? 'h-56' : ''}`}
              placeholder="Select advertisement"
              popupClassName="custom-option"
              onChange={(e) => {
                const type = advertiseOptions?.filter(
                  (item) => item.value === e
                )[0]?.type;

                setPaginate({ ...paginate, type: type, advertisementId: e });
              }}
              allowClear={true}
              suffixIcon={
                <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
              }
              autoClearSearchValue={true}
              onClear={() => {
                resetSelect();
              }}
            ></Select>
            {!paginate.type && isCreate && error?.type && (
              <p className="error">Advertisement is required</p>
            )}
          </div>
        </>
      )}

      <div className={`form-group ${isCreate ? 'w-50' : ''}`}>
        {isCreate && (
          <label>
            Ads Type<span className="required">*</span>
          </label>
        )}
        <Select
          value={paginate?.category ?? null}
          options={ADS_TYPE_OPTION}
          className={`select ${isCreate ? 'h-56' : ''}`}
          placeholder={isCreate ? 'Select Ads Type' : 'Ads Type'}
          popupClassName="custom-option"
          onChange={(e) => {
            setPaginate({ ...paginate, category: e });
          }}
          allowClear={true}
          suffixIcon={<img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />}
          onClear={() => {
            resetSelect();
          }}
        ></Select>
        {!paginate.category && isCreate && error?.category && (
          <p className="error">Ads Type is required</p>
        )}
      </div>

      <div className={`form-group building ${isCreate ? 'w-30' : ''}`}>
        {isCreate && (
          <label>
            Building<span className="required">*</span>
          </label>
        )}
        <Select
          ref={refSelectBuilding}
          value={paginate?.buildingId ?? null}
          options={buildingOptions}
          className={`select ${isCreate ? 'h-56' : ''}`}
          placeholder={isCreate ? 'Select building' : 'Building'}
          popupClassName="custom-option"
          onChange={(e) => {
            setPaginate({ ...paginate, buildingId: e });
          }}
          allowClear={true}
          suffixIcon={<img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />}
          onClear={() => {
            resetSelect();
          }}
        ></Select>
        {!paginate.buildingId && isCreate && error?.buildingId && (
          <p className="error">Building is required</p>
        )}
      </div>
      {!isCreate && (
        <div className={`form-group ${isCreate ? 'w-30' : ''}`}>
          <Select
            className="select"
            placeholder="Select Aspect ratio"
            value={paginate?.aspectRatio}
            options={aspectRatios}
            onChange={(type) => {
              setPaginate({ ...paginate, aspectRatio: type });
            }}
            allowClear={true}
            popupClassName="custom-option"
            suffixIcon={
              <img src={Images.ICON_DROPDOWN_WHITE} alt="icon-down" />
            }
            onClear={() => {
              resetSelect();
            }}
          ></Select>
        </div>
      )}

      <div className={`form-group ${isCreate ? 'w-30' : ''}`}>
        {isCreate && (
          <label>
            Start date<span className="required">*</span>
          </label>
        )}
        <DatePicker
          value={paginate?.startDate ? moment(paginate?.startDate) : moment()}
          className={`date-picker ${isCreate ? 'h-56' : ''}`}
          popupClassName="date-picker-panel-custom"
          placeholder={isCreate ? 'YYYY-MM-DD' : 'Start date'}
          onChange={(e) => {
            setPaginate({
              ...paginate,
              startDate: e ? moment(e).format('YYYY-MM-DD') : null,
            });
          }}
          disabledDate={disabledStartDate}
          suffixIcon={<img src={Images.ICON_CALENDAR} alt="calendar" />}
          allowClear={false}
        />
        {!paginate.startDate && isCreate && error?.startDate && (
          <p className="error">Start date is required</p>
        )}
      </div>

      <div className={`form-group ${isCreate ? 'w-30' : ''}`}>
        {isCreate && (
          <label>
            End date<span className="required">*</span>
          </label>
        )}
        <DatePicker
          defaultPickerValue={moment()}
          value={paginate?.endDate ? moment(paginate?.endDate) : moment()}
          className={`date-picker ${isCreate ? 'h-56' : ''}`}
          popupClassName="date-picker-panel-custom"
          placeholder={isCreate ? 'YYYY-MM-DD' : 'End date'}
          onChange={(e) => {
            setPaginate({
              ...paginate,
              endDate: e ? moment(e).format('YYYY-MM-DD') : null,
            });
          }}
          disabledDate={disabledEndDate}
          suffixIcon={<img src={Images.ICON_CALENDAR} alt="calendar" />}
          allowClear={false}
        />
        {!paginate.endDate && isCreate && error?.endDate && (
          <p className="error">End date is required</p>
        )}
      </div>

      <ButtonImage
        onClick={() => {
          onSearch();
        }}
        type="action"
        height={isCreate ? 56 : 40}
        textTransform="unset"
        fontWeight={300}
        fontSize={14}
        className={`btn-search ${tableAdvertiseLoading ? 'disabled' : ''} ${!isCreate ? 'btn-search-2' : ''}`}
      >
        Search
      </ButtonImage>
    </FilterWrapper>
  );
};

export default AdvertiseFilter;

import { createSlice } from '@reduxjs/toolkit';

import {
  generateVRCodeAction,
  generateDeviceAction
} from './generate.action';

const initialState = {
  isLoading: false,
  error: null,
  data: {},
  deviceData: null,
};

const { actions, reducer } = createSlice({
  name: 'generate_slice',
  initialState,
  reducers: {
    resetGenerateVRCodeAction: state => {
      state.data = {};
      state.deviceData = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(generateVRCodeAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(generateVRCodeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(generateVRCodeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      })

      .addCase(generateDeviceAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(generateDeviceAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deviceData = action.payload.data;
      })
      .addCase(generateDeviceAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      })
  },
});

export { reducer };
export default actions;

import React from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import classNames from 'classnames'

import { DivStyled } from './styled'

export default ({ dataContent, className, ...props }) => (
  <DivStyled
    className={classNames(className)}
  >
    <CKEditor
      {...props}
      editor={ClassicEditor}
      data={dataContent}
      config={{
        toolbar: false,
        image: {
          toolbar: ['imageTextAlternative', '|',
            'imageStyle:full',
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight'
          ],
          styles: [
            'full',
            'alignLeft',
            'alignCenter',
            'alignRight'
          ]
        }
      }}
      disabled
    />
  </DivStyled>
)

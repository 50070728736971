import React from 'react'
import classNames from 'classnames'

import { useAppSelector } from '../../../stores';

/** component */
import Section from '../../section'
import Container from '../../container'
import CardService from '../../card-services'
import TitleSection from '../../title-section'
import Line from '../../line'
import ButtonImage from '../../button-img'
import CardEvent from '../../card';
import SkeletonStyled from '../../loading-skeleton'

/** asset */
import { Images } from '../../../theme'
import { Content } from './styled'
import { Link } from 'react-router-dom';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children, className, handleSSO = () => { }, handleMccBET = () => { }, ...props }) => {
  const { isLoading, listEvents } = useAppSelector(state => state.events);
  const { isLoading: isLoadingAuth } = useAppSelector(state => state.auth);

  const renderListEvents = Array.isArray(listEvents?.items) ? listEvents.items.map((item, index) => (
    <CardEvent data={item} key={index} className="event-item" data-aos="fade-left" data-aos-duration={3000 * (index + 1) / 10} />
  )) : []

  const renderListLoading = Array.from({ length: 3 }, (x, index) => <SkeletonStyled loading={isLoading} active data-aos="fade-left" data-aos-duration={3000 * (index + 1) / 10} />)

  return (
    <Content {...props} className={classNames(className)}>
      <Section className="home-service">
        <Container>
          <div className="body">
            <TitleSection className="title" data-aos="fade-down">SERVICES</TitleSection>

            <div className="card-actions">
              <CardService
                data-aos="fade-right"
                title="MARKETPLACE"
                button={<ButtonImage onClick={() => !isLoadingAuth && handleSSO()} type="green">Explore Now</ButtonImage>}
                image={Images.NFT}
                positionImage='left'
              />

              <CardService
                data-aos="fade-left"
                title="MCC bet"
                button={<ButtonImage onClick={() => !isLoadingAuth && handleMccBET()} type="brown">Explore Now</ButtonImage>}
                image={Images.BET1}
                positionImage='right'
              />
            </div>

            <TitleSection className="title" data-aos="fade-down">Introducing Meta Casino City</TitleSection>
            <div className="description" data-aos="fade-down">
              <p>Explore Meta Casino City, a virtual casino world where players can experience the thrill of gambling in a fully immersive virtual environment.</p>
              <p>Casino City Metaverse offers a wide variety of casino games, including slots, blackjack, roulette, and poker. Players can also participate in live dealer games, where they can interact with real-life dealers in real time.</p>
              <p>VR, or Virtual Reality technology, creates a simulated reality than can transport users to, well, wherever they want to be, really. Everyone has their own fantasy when they put on the VR set. Some cherish the sandy beaches of the Maldives, others might go for a more high-adrenaline adventure like jumping from airplanes, but there are also those who dream of being inside one of the world's most renowned casinos like the Bellagio or the casino Monte Carlo. With virtual reality, everything is possible!</p>
            </div>

            <Line data-aos="flip-up" />
            <div className='box-event'>
              <div className='header'>
                <div className='title' data-aos="fade-right">Events</div>
                <Link to='/event-list'><div className='view-more' data-aos="fade-left">View more</div></Link>
              </div>
              <div className='wrapper-events'>
                {isLoading ? renderListLoading : renderListEvents}
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Content>
  )
}

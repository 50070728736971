import { Modal } from "antd";
import styled from "styled-components";

export const ImageWrapper = styled.div`
  z-index: 5;
  width: 100%;
  position: relative;
  height: 100%;
  .image {
    position: relative;
  }
  .icon {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    .icon-eye {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .icon:hover {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    content: "";
    position: absolute;
    .icon-eye {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  &.custom-preview {
    padding: 0px;
    .ant-modal-content {
      .ant-modal-body {
        padding: 0px;
      }
      .ant-modal-close {
        right: -50px;
        top: -30px;

        .ant-modal-close-x {
          width: 44px;
          height: 44px;
          line-height: 30px;
        }
      }
    }
    .custom-image {
      width: ${({ width }) => (width ? `${width}px` : "100%")};
      height: ${({ height }) => (height ? `${height}px` : "auto")};
      box-shadow: rgb(241 237 237) 0px 1px 4px, rgb(255 254 254) 0px 0px 0px 5px;
      border-radius: 6px;
    }
  }
`;

export const VideoWrapper = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 2;
  width: ${({ width }) => (width ? `${width}px` : "300px")};
  height: ${({ height }) => (height ? `${height}px` : "220px")};
  .custom-video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  .btn-preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border: none;
    width: 100%;
    height: 100%;
    opacity: 1;
    cursor: pointer;
    background: transparent;
  }

  .video {
    height: 100%;
    position: absolute;
    z-index: 5;
    border: 1px solid #FFF;
    border-radius: 4px;
    padding: 6px;
  }

  .btn-action {
    position: absolute;
    z-index: 6;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 8px;

    &.delete {
      background-color: #e03137;
      z-index: 100;
    }
    &.delete:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
      scale: 1.1;
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const PreviewModalVideoWrapper = styled(Modal)`
  background: transparent;
  width: ${({ width }) => (width ? `${width}px` : "800px")};
  height: ${({ height }) => (height ? `${height}px` : "600px")};
  .ant-modal-content {
    .ant-modal-body {
      padding: 0px;
    }
    .ant-modal-close {
      top: -30px;
      right: -50px;

      .ant-modal-close-x {
        width: 44px;
        height: 44px;
        line-height: 30px;
      }
    }
  }
  .ant-modal-content {
    background: transparent;
    border-radius: 20px;
    box-shadow: unset;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .ant-modal-close {
    top: -30px;
    right: -50px;

    .ant-modal-close-x {
      width: 44px;
      height: 44px;
      line-height: 30px;
    }
  }

  video,
  .preview {
    border-radius: 10px;
  }
  .preview {
    box-shadow: rgb(241 237 237) 0px 1px 4px, rgb(255 254 254) 0px 0px 0px 5px;
    background-color: #ececec;
  }
  .preview-modal {
    width: ${({ width }) => (width ? `${width}px` : "800px")};
    height: ${({ height }) => (height ? `${height}px` : "600px")};
  }

  .anticon {
    color: #fff;
  }

  .video-content {
    overflow: hidden;
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : "800px")};
    height: ${({ height }) => (height ? `${height}px` : "600px")};
    position: relative;
    box-shadow: rgb(241 237 237) 0px 1px 4px, rgb(255 254 254) 0px 0px 0px 5px;
    border-radius: 6px;
    .custom-video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .controls {
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(8, 9, 13, 0),
        rgba(8, 9, 13, 0.9)
      );

      .name-video {
        position: absolute;
        color: #fff;
        top: 0;
        left: 3%;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .current-time {
        position: absolute;
        color: #c7c5c5;
        top: 0;
        left: calc(100% - 144px);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .total-time {
        position: absolute;
        color: #c7c5c5;
        top: 0;
        left: calc(100% - 70px);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .temp {
        position: absolute;
        color: #c7c5c5;
        top: 0;
        left: calc(100% - 79px);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .range {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 20px);
        // -webkit-appearance: none;
        background: #d9d9d9;
        border-radius: 5px;
        height: 2px;
        cursor: pointer;

        &::-webkit-slider-thumb {
          // -webkit-appearance: none;

          height: 4px;
          background: red;
          border-radius: 5px;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          height: 4px;
          background: red;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      .btn-control {
        position: absolute;
        background: transparent;
        border: none;
        cursor: pointer;

        &.btn-play {
          bottom: 15px;
          left: 15px;
        }

        &.btn-prev {
          bottom: 15px;
          left: 50px;
        }
        &.btn-next {
          bottom: 15px;
          left: 90px;
        }

        &.btn-mute {
          bottom: 15px;
          right: 50px;
        }
        &.btn-full {
          bottom: 15px;
          right: 15px;
        }
      }
    }
  }
`;

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { DivStyled } from './styled';

const Loading = ({ size, color, className }) => (
  <DivStyled
    color={color}
    className={classnames(size, className, 'loading-spiner')}
  />
);
Loading.propTypes = {
  size: PropTypes.oneOf(['small', 'large', 'full-screen']),
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Loading;

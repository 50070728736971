import React from 'react';
import { useNavigate } from 'react-router-dom';

/** component */
import Page from '../../components/page';
import TitleSection from '../../components/title-section';
import Container from '../../components/container';
import CardFQA from '../../components/card-faq'
import ButtonImage from '../../components/button-img'

import { ContentStyled } from './styled';

function EventList() {
  const navigate = useNavigate();

  const listFAQs = [
    {
      type: 'Q_A',
      question: 'Do I need a VR headset to play Meta Casino City?',
      answer: `No. Meta Casino City can be access from browser or VR, so you do not need a VR headset. However, playing using VR headset can provide a more immersive experience.`
    }, {
      type: 'A_Q',
      question: 'Can I play Meta Casino City on my mobile device?',
      answer: `Yes, Meta Casino City is mobile-friendly. You can play directly from your mobile browser.`
    }, {
      type: 'A_Q',
      question: 'What type Meta Casino City is?',
      answer: `Meta Casino City is centralized. Centralized metaverse casinos are owned and operated by a single company.`
    }, {
      type: 'A_Q',
      question: 'What are the advantages of using cryptocurrency to play Meta Casino City?',
      answer: `There are several advantages to using cryptocurrency to play Meta Casino City, including:
      \n - Faster transactions: Cryptocurrency transactions are typically faster than fiat currency transactions.
      \n - Lower fees: Cryptocurrency transactions typically have lower fees than fiat currency transactions.
      \n - More privacy: Cryptocurrency transactions are more private than fiat currency transactions.`
    }, {
      type: 'A_Q',
      question: 'How can I stay safe when playing Meta Casino City?',
      answer: `Meta Casino City is pretty safe, but if you still worry, you should follow these tips:
      \n - Use strong passwords and enable two-factor authentication on your casino account.
      \n - Never give out your personal or financial information to anyone.
      \n - Be careful of scams.
      \n - Gamble responsibly.`
    }, {
      type: 'A_Q',
      question: 'What is the future of Meta Casino City?',
      answer: `The future of Meta Casino City is unsettled. As the metaverse continues to develop, we can expect to see more and more immersive and innovative features.`
    }, {
      type: 'A_Q',
      question: 'Is Meta Casino City a good investment?',
      answer: `Whether or not Meta Casino City is a good investment is a matter of personal opinion. Some people believe that the platform has the potential to become very successful, while others are more skeptical.
      There are a number of factors that could affect the success of Meta Casino City, including:
      \n - The adoption of the metaverse: If the metaverse becomes widely adopted, then Meta Casino City is likely to be successful.
      \n - If the metaverse is lightly regulated, then this could benefit Meta Casino City.
      \n - The competition: There are a number of other metaverse casino platforms in development. If these platforms are more successful than Meta Casino City, then this could hurt the value of MCC.`
    }, {
      type: 'A_Q',
      question: `What are the benefits of playing casino games in Meta Casino City?`,
      answer: `There are several benefits to playing casino games in Meta Casino City, including:
      \n - Immersive experience: Metaverse casino games offer a more immersive experience than traditional online casinos. Players can interact with each other and with the casino environment in a realistic way.
      \n - Transparency: Metaverse casino games use blockchain technology, which makes them transparent and fair. Players can see all of the bets and wins that have been placed on a game, and they can be sure that the games are not rigged.
      \n - Security: Metaverse casino games are secure and encrypted, so players can be confident that their personal and financial information is safe.`
    }, {
      type: 'A_Q',
      question: `What are the different types of casino games in Meta Casino City?`,
      answer: `There are a wide variety of casino games available, including:
      \n - Slots: Slots are the most popular type of casino game, and they are also widely available in the metaverse. Metaverse slots games offer a variety of different themes and features, so there is something for everyone.
      \n - Table games: Metaverse casinos also offer a variety of table games, such as blackjack, roulette, and poker. These games are more social than slots, and they offer a more challenging gaming experience.
      \n - Live dealer games: Live dealer games allow players to interact with a real-life dealer in real time. This provides the most immersive casino experience possible.`
    }, {
      type: 'A_Q',
      question: `How do I start playing casino games in Meta Casino City?`,
      answer: `To start playing casino games in Meta Casino City, you will need to:
      \n - Create a MCC account and connect with wallet.
      \n - Deposit cryptocurrency into your MCC Wallet.
      \n - Join in the city and get to Casino Tower.
      \n - Choose a game to play and place your bet.`
    }, {
      type: 'A_Q',
      question: `What are the risks of playing casino games in Meta Casino City?`,
      answer: `There are a few risks associated with playing casino games in Meta Casino City, including:
      \n - Addiction: Gambling can be addictive, and metaverse casino games are no exception. It is important to gamble responsibly and to set limits on how much money you are willing to lose.
      \n - Scams: There are some scams associated with metaverse casino games. Be careful of any casino that asks you to deposit money without providing any information about the games they offer or the terms and conditions of their service.
      \n - Volatility: Cryptocurrency prices can be volatile, so there is a risk of losing money if you deposit cryptocurrency into your casino account and the price of the cryptocurrency falls.`
    }, {
      type: 'A_Q',
      question: `How do I withdraw my winnings from casino games in Meta Casino City?`,
      answer: `To withdraw your winnings from Meta Casino City, you will need to transfer your cryptocurrency from your linked MCC account to your personal cryptocurrency wallet.`
    }, {
      type: 'A_Q',
      question: `Are casino games in Meta Casino City legal?`,
      answer: `The legality of metaverse casino games varies from country to country. Be sure to check the laws in your jurisdiction before playing.`
    }, {
      type: 'A_Q',
      question: `What is the difference between Meta Casino City's game and a traditional online casino game?`,
      answer: `The main difference between Meta Casino City's game and a traditional online casino game is that Meta Casino City's games are played in a virtual world. This allows players to interact with each other and with the casino environment in a more realistic way. Meta Casino City's games also use blockchain technology, which makes them more transparent and fair than traditional online casino games.`
    }, {
      type: 'A_Q',
      question: `How to create an account and log in?`,
      answer: `To create an account and log in to Meta Casino City (MCC), follow these steps:
      \n1. Go to the [MCC Website] and click on the LET'S PLAY button.
      \n2. Select one of the following options:
      \n - Continue with SNS: Log in using your account from a supported social media platform, such as Facebook, Twitter, or Google.
      \n - Continue with wallet: Log in using your MetaMask wallet.
      \n - Continue as guest: Play the game without creating an account. However, you will not be able to make in-game purchases.
      \n3. If you chose to continue with SNS or wallet, enter your account information and follow the on-screen instructions.
      \n4. Accept the terms of service and privacy policy (if required).
      \n5. Once you have registered an account, you will be able to enter the MCC metaverse and explore the world, interact with other players, and participate in activities and events.
      
      \nAdditional tips:
      \n - If you're login with an email, choose a strong password for your account and do not share it with anyone.
      \n - Keep your account information private.
      \n - If you are using a mobile device, make sure to keep the MetaMask app updated to the latest version.
      \n - If you have any problems registering an account or logging in, contact MCC support for help.`
    }, {
      type: 'A_Q',
      question: `How to play the game?`,
      answer: `Meta Casino City (MCC) is a virtual world where users can explore, interact with each other, and participate in activities and events.
      \n To play the game, you will need to register an account and log in. Once you are logged in, you will be able to enter the MCC metaverse.
      
      \nHere is a basic overview of how to play the game:
      \n1. Move around. You can move around MCC using the WASD keys on your keyboard or by clicking and dragging with your mouse.
      \n - Using the WASD keys: Press the W key to move forward, the A key to move left, the S key to move backward, and the D key to move right.
      \n - Using the mouse: Click and hold the left mouse button, then drag the mouse in the direction you want to move.
      \n - Using the Space key: Press the Space key to jump. To jump in a specific direction, press the Space key and the corresponding WASD key. Hold down the Space key longer to jump repeatedly.
      \n2. Interact with objects. To interact with objects in the game, move your avatar close to them and press the F key.
      \nThis will open a menu where you can perform various actions, such as picking up objects, opening doors, or talking to NPCs. To close the menu, press the Esc key.
      \n3. Participate in activities. There are many different activities to participate in in MCC, such as playing games, attending events, or visiting casinos. To find activities, you can browse the map or use the search bar.
      \n4. Interact with other players. MCC is a social world, so you can interact with other players by talking to them, sending them messages, or playing games together.
      
      \nHere are some additional tips for playing MCC:
      \n - You can use the map to navigate MCC and find places of interest.
      \n - You can use the search bar to find specific activities, events, or players.
      \n - You can use the chat feature to communicate with other players.
      \n - You can use the inventory system to store and manage your items or change your avatar's appearance.
      
      \nMCC is a complex game with many different features, so it is impossible to cover everything in a short guide. However, the above tips should give you a good starting point.`
    }, {
      type: 'A_Q',
      question: `How to make money in the game?`,
      answer: `There are a number of ways to make money in Meta Casino City (MCC). Here are a few examples:
      \n - Sell NFTs. You can create and sell your own NFTs in MCC. This could include things like digital art, wearables, or even virtual land.
      \n - Play games. There are many different games in MCC that offer rewards in the form of POINT. Some of these games are skill-based, while others are more like gambling games.
      \n - Provide services. You can offer services to other MCC users, such as building worlds, designing avatars, or creating experiences. You can charge for your services in POINT.
      \n - Invest in land. Land in MCC is a valuable asset, and you can make money by buying and selling it. You can also lease your land to other users, or rent it out for events.
      
      \nHere are some additional tips for making money in MCC:
      \n - Do your research. Before you invest any money in MCC, it is important to do your research and understand the risks involved.
      \n - Start small. Don't invest more money than you can afford to lose.
      \n - Be patient. It takes time to build a successful business in MCC. Don't expect to get rich quick.`
    }, {
      type: 'A_Q',
      question: `How to interact with other players?`,
      answer: `There are a number of ways to interact with other players in Meta Casino City (MCC). Here are a few examples:
      \n - Chat. You can chat with other players using the chat feature. To do this, press Enter on your keyboard to open the chat window. You can then type your message and press Enter again to send it.
      \n   + Normal chat: This is the most common type of chat and is used to communicate with other players who are nearby.
      \n     To send a normal chat message, simply press Enter on your keyboard to open the chat window, type your message, and press Enter again to send it.
      \n   + Group chat: This type of chat is used to communicate with a specific group of players.
      \n     To create a group chat, open the chat window and click on the ""Group chat"" tab. You can then add players to the group and start chatting.
      \n   + World chat: This type of chat is used to communicate with all players in the current world.
      \n     To send a world chat message, open the chat window click on the ""World chat"" tab, type your message, and press Enter again to send it.
      \n - Voice chat. You can also voice chat with other players using a microphone. To do this, click on the microphone icon in the chat window.
      \n - Emotes. You can use emotes to express yourself to other players. To do this, press the I key on your keyboard to open the emote menu. You can then select an emote and click on it to perform it.
      \n - Gestures. You can also use gestures to interact with other players. For example, you can wave, clap, or dance. To do this, press the G key on your keyboard to open the gesture menu. You can then select a gesture and click on it to perform it.
      \n - Activities. You can participate in activities with other players, such as playing games, attending events, or exploring worlds together. To find activities, you can browse the map or use the search bar.
      
      \nHere are some additional tips for interacting with other players in MCC:
      \n - Be respectful. Treat other players the way you want to be treated.
      \n - Be helpful. If you see someone who needs help, offer to assist them.
      \n - Be aware of your surroundings. Be careful not to bump into other players or run into objects.
      \nHave fun! MCC is a social world, so enjoy interacting with other players.
      
      \nHere are some specific examples of how you can interact with other players in MCC:
      \n - Join a club or community. There are many different clubs and communities in MCC. Joining a club or community is a great way to meet other players who share your interests.
      \n - Attend events. There are many different events happening in MCC all the time. Attending events is a great way to meet new people and have fun.
      \n - Play games with other players. There are many different games in MCC that you can play with other players. Playing games is a great way to bond with other players and make new friends.`
    }, {
      type: 'A_Q',
      question: `How to use the features in the game?`,
      answer: `Meta Casino City (MCC) is a virtual world where users can explore, interact with each other, and participate in activities and events. 
      \nTo use the features in MCC, you will need to create an account and log in. Once you are logged in, you will be able to enter the MCC metaverse.
      
      \nHere is a brief overview of some of the key features in MCC:
      \n - Movement: You can move around MCC using the WASD keys on your keyboard or by clicking and dragging with your mouse. You can also use the Space bar to jump.
      \n - Interaction: You can interact with objects and other players in MCC by clicking on them. This will open a menu where you can perform various actions, such as picking up objects, opening doors, or talking to NPCs.
      \n - Chat: You can chat with other players in MCC using the chat feature. To do this, press Enter on your keyboard to open the chat window. You can then type your message and press Enter again to send it.
      \n - Inventory: You can use the inventory system to store and manage your avatars, items,... To open the inventory, press the I key on your keyboard.
      
      \nIn addition to these basic features, there are a number of other features in MCC that you can use, such as:
      \n - The map: The map can be used to navigate MCC and find places of interest. To open the map, press the M key on your keyboard.
      \n - The marketplace: The marketplace can be used to buy and sell NFTs, such as digital art, wearables, and virtual land. To open the marketplace, press the P key on your keyboard.
      \n - The casino games are located at Casino Tower, which offers a variety of activities to attend to.`
    }, {
      type: 'A_Q',
      question: `How to participate in the activities in the game?`,
      answer: `Here are some tips on how to participate in the activities in Meta Casino City (MCC):
      \n - Use the map to find activities. The map can be used to find activities, events, and places of interest. To open the map, press the M key on your keyboard.
      \n - Use the search bar to find specific activities. The search bar can be used to find specific activities, events, or players. To open the search bar, press the / key on your keyboard.
      \n - Join clubs and communities. Clubs and communities are a great way to find and participate in activities. To find clubs and communities, open the map and click on the ""Community"" tab.
      \n - Attend events. There are many different events happening in MCC all the time. To find events, open the map and click on the ""Events"" tab.
      \n - Play games. There are many different games to play in MCC. To find games, open the map and click on the ""Games"" tab.
      
      \nHere are some specific examples of activities that you can participate in in MCC:
      \n - Visit the MCC BET. The MCC BET is a popular place to play casino games and gamble.
      \n - Attend a concert or event at one of the many venues in MCC. There are many different concerts and events happening in MCC all the time.
      \n - Start a business or organization in MCC. MCC is a virtual economy, so you can start a business or organization and earn money.
      
      \nHere are some additional tips for participating in the activities in MCC:
      \n - Be respectful. Treat other players the way you want to be treated.
      \n - Be helpful. If you see someone who needs help, offer to assist them.
      \n - Be aware of your surroundings. Be careful not to bump into other players or run into objects.
      
      \nHave fun! MCC is a social world, so enjoy participating in activities with other players.`
    }
  ]

  const renderListFAQs = listFAQs.map((item, index) => (
    <CardFQA
      key={index}
      className="faq-item"
      type={index % 2 === 0 ? 'Q_A' : 'A_Q'}
      question={item.question}
      answer={item.answer}
    />
  ))

  return (
    <Page>
      <ContentStyled className='content'>
        <Container>
          <TitleSection data-aos="fade-down">FAQs</TitleSection>

          <div className='wrapper'>
            {renderListFAQs}
          </div>

          {/* <div className='still-questions' data-aos="fade-down">Still have questions?</div>

          <div className='btn-actions' data-aos="fade-up">
            <ButtonImage type="green" name="Contact us" height={70} onClick={() => navigate('/contact-us')}>
              Contact us
            </ButtonImage>
          </div> */}
        </Container>
      </ContentStyled>
    </Page>
  );
}

export default EventList;

import { AxiosClient } from '../../../configs/axios/axios';

const ACCOUNTS_API = {
  getAccountCheckIp: async () => {
    const response = await new AxiosClient().get(`/accounts/check-ip`);

    return response;
  },
};

export default ACCOUNTS_API;

import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalGenerateVRStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    border: 2px solid #fff;
    box-shadow: 0px 0px 10px 0px #00d1ff;
    background-color: #1f2582;
  }

  .ant-modal-body {
    background: linear-gradient(
      270deg,
      rgba(14, 82, 246, 0) 0%,
      rgba(14, 82, 246, 0.22) 8.87%,
      rgba(14, 82, 246, 0.8) 46.6%,
      rgba(49, 106, 217, 0.34) 84.3%,
      rgba(72, 138, 251, 0) 100%
    );
    color: #fff;
    text-align: center;

    .title {
      font-size: 24px;
      padding-bottom: 10px;
      font-weight: 900;
      text-transform: uppercase;
    }
    .sub-title {
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 8px;
    }

    .action-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .btn-content {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 500;
      }
      .generate {
        color: #2b0c01;
        &.disabled {
          color: #fff;
        }
      }
    }
    
    @media (max-width: 575px) {
      padding: 16px;
      padding-bottom: 24px;

      .action-box {
        .btn-vr {
          height: 42px !important;
        }

        .btn-content {
          font-size: 13px;
        }

      }

      .sub-title {
        font-size: 13px;
      }
      
    }
  }

  .box-generate-vr {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    margin-bottom: 30px;
    padding: 0 50px;
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 16px;
    text-transform: uppercase;
    position: relative;
    &::before {
      opacity: 0.5;
      background: #000;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 9px;
    }
    .vr-code {
      z-index: 10;
      position: absolute;
      width: 100%;
      color: #fff;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
  }
    
  

  .ant-modal-close-icon {
    svg {
      fill: #6f7d95;
    }
  }
`;

import styled from 'styled-components';

export const PricingPlanWrapper = styled.div`
  .title {
    font-size: 16px;
    padding-left: 10px;
    border-left: 5px solid #1e90ff;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    height: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-left: 20px;
  }

  .light-dot {
    position: absolute;
    mix-blend-mode: color-dodge;
    width: 50px;
    height: 50px;

    &.light-dot-01 {
      top: -5px;
      right: -6px;
    }

    &.light-dot-02 {
      top: -23px;
      right: 15px;
    }

    &.light-dot-03 {
      bottom: -23px;
      left: -5px;
    }
  }

  .table-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    
  }

  .position-relative {
    width: 100%;
    height: 406px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
  }

  .img-bg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .point-content {
    width: calc(100% - 100px);
    position: absolute;
    z-index: 20;
    overflow: auto;
    padding-bottom: 5px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #2b6ae680;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #2b6ae680;
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .note{
    margin-bottom: 5px;
  }

  .table-pricing {
    color: #fff;
    width: 100%;

    table-layout: auto;

    thead {
      background-color: #2b6ae680;
    }
    tr {
      height: 80px;
    }

    th,
    td {
      width: 40px !important;
    }

    th {
      &:first-child {
        text-align: left;
        padding: 0 20px;
      }
    }

    td {
      &:first-child {
        padding: 0 20px;
        font-weight: normal;
        text-transform: capitalize;
      }

      font-weight: 100;
      border: 1px solid #2b6ae680;
    }

    .txt-center {
      text-align: center;
    }

    .standard {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 17.5%,
        rgba(255, 255, 255, 0.13) 100%
      );

      td {
        &:first-child {
          border-bottom: 1px solid #fff;
        }
      }
    }

    .premium {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 17.5%,
        rgba(253, 160, 0, 0.13) 100%
      );
      td {
        &:first-child {
          color: #fda000;
          border-bottom: 1px solid #fda000;
        }
      }
    }

    .professional {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 17.5%,
        rgba(241, 34, 34, 0.13) 100%
      );
      td {
        &:first-child {
          color: #f12222;
          border-bottom: 1px solid #f12222;
        }
      }
    }
  }
`;

import { AxiosClient } from '../../../configs/axios/axios';
import Storage from '../../../utils/storage';

const apiVersion = process.env.REACT_APP_VERSION;

const AUTH_API = {
  login: async (params) => {
    const response = await new AxiosClient().post(
      `${apiVersion}/auth/web3-auth`,
      params
    );

    return response;
  },

  getUserInfo: async (params) => {
    const response = await new AxiosClient().get(`${apiVersion}/user/initial`, {
      headers: {
        'x-access-token': `${params.accessToken}`,
      },
    });

    return response;
  },

  verifyEmail: async (params) => {
    const response = await new AxiosClient().post(
      `${apiVersion}/auth/request-verify`,
      params,
      {
        headers: {
          'x-access-token': Storage.get('IS_CHECK'),
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  },

  retrieveAccount: async (params) => {
    const response = await new AxiosClient().post(
      `${apiVersion}/auth/retrieve-account`,
      null,
      {
        headers: {
          'x-access-token': params || Storage.get('ACCESS_TOKEN'),
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  },

  refreshToken: async (params) => {
    const response = await new AxiosClient().post(
      `${apiVersion}/auth/refresh-auth-token`,
      params,
      {
        headers: {
          'x-access-token': Storage.get('ACCESS_TOKEN'),
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  },

  loginWallet: async (params) => {
    const response = await new AxiosClient().post(
      `accounts/signatureWallet`,
      params,
    );
    return response;
  },

  verifyEmailAuth: async (params) => {
    const response = await new AxiosClient().post(
      `${apiVersion}/auth/send-code`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  },

  authLogin: async (params) => {
    const response = await new AxiosClient().post(
      `${apiVersion}/auth/login`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  },

  refreshTokenLogin: async (params) => {
    const response = await new AxiosClient().post(
      `${apiVersion}/auth/refresh_token`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  },

  redirectMccBet: async (params) => {
    const response = await new AxiosClient().post(
      `${apiVersion}/mccwallet/authorization-tokens`,
      {},
      {
        headers: {
          'x-access-token': params || Storage.get('ACCESS_TOKEN'),
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  },

  triggerLogin: async (params) => {
    const response = await new AxiosClient().post(
      `${apiVersion}/auth/trigger-login`,
      params,
      {
        headers: {
          'x-access-token': Storage.get('ACCESS_TOKEN'),
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  },

};

export default AUTH_API;

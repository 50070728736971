import styled from "styled-components";
import { Colors } from "../../theme";

export const PageStyled = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: calc(100vh - 100px);
  background-color: #D9D9D9;
  display: flex;
  flex-direction: column;

  @media (min-width: 320px) {
  }

  @media (min-width: 1024px) {
  }
`;

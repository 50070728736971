import { useState, useEffect } from 'react';

const useVideoPlayer = (videoElement) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
    isFullScreen: false,
  });

  const [currentTime, setCurrentTime] = useState('00:00:00');
  const [totalTime, setTotalTime] = useState('00:00:00');

  useEffect(() => {
    if (!videoElement.current) {
      return;
    }
    const video = videoElement.current;

    const updateTime = () => {
      const totalTime = video.duration;
      const currentTime = video.currentTime;
      const formattedTotalTime = formatTime(totalTime);
      const formattedCurrentTime = formatTime(currentTime);

      setTotalTime(formattedTotalTime);
      setCurrentTime(formattedCurrentTime);
    };

    video.addEventListener('loadedmetadata', updateTime);
    video.addEventListener('timeupdate', updateTime);

    return () => {
      video.removeEventListener('loadedmetadata', updateTime);
      video.removeEventListener('timeupdate', updateTime);
    };
  }, [videoElement.current]);

  function formatTime(time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  };

  const handleOnTimeUpdate = () => {
    const progress =
      (videoElement.current.currentTime / videoElement.current.duration) * 100;
    setPlayerState({
      ...playerState,
      progress,
    });
  };

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value);
    videoElement.current.currentTime =
      (videoElement.current.duration / 100) * manualChange;
    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  const toggleFullScreen = () => {
    const video = videoElement.current;

    if (!playerState.isFullScreen) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
      // setPlayerState({
      //   ...playerState,
      //   isFullScreen: true,
      // });
    } else {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        setPlayerState({
          ...playerState,
          isFullScreen: false,
        });
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }
  };

  const handlePlayVideo = (e) => {
    if (e?.type === 'pause') {
      setPlayerState({
        ...playerState,
        isPlaying: false,
      });
    } else {
      setPlayerState({
        ...playerState,
        isPlaying: true,
      });
    }
  };

  const skipBackward = () => {
    videoElement.current.currentTime -= 15;
  };

  const skipForward = () => {
    videoElement.current.currentTime += 15;
  };

  useEffect(() => {
    if (!videoElement.current) {
      return;
    }
    playerState.isMuted
      ? (videoElement.current.muted = true)
      : (videoElement.current.muted = false);
  }, [playerState.isMuted, videoElement]);

  useEffect(() => {
    if (!videoElement.current) {
      return;
    }
    playerState.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  useEffect(() => {
    if (!videoElement.current) {
      return;
    }

    const updateProgress = () => {
      if (!videoElement.current) {
        return;
      }
      const progress =
        (videoElement.current.currentTime / videoElement.current.duration) *
        100;
      setPlayerState({
        ...playerState,
        progress,
      });
    };

    videoElement.current.addEventListener('timeupdate', updateProgress);

    // return () => {
    //   videoElement.current.removeEventListener('timeupdate', updateProgress);
    // };
  }, [playerState, videoElement]);

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
    toggleFullScreen,
    skipBackward,
    skipForward,
    currentTime,
    totalTime,
    setPlayerState,
    handlePlayVideo
  };
};

export default useVideoPlayer;

import styled from "styled-components";
import { Modal } from "antd";

export const DetailModalWrapper = styled(Modal)`
  background: linear-gradient(270deg, #052b86 0%, #03153f 100%);
  border-radius: 20px;
  width: 861px !important;
  max-height: 80%;
  overflow: auto;

  .ant-modal-content,
  .ant-modal-header {
    color: #fff;
    background: transparent;
  }

  .ant-modal-title {
    color: #fff;
    font-size: 20px;
  }

  .ant-modal-header {
    border: unset;
    padding: 30px;
  }

  .ant-modal-content {
  }

  .dp-flex {
    display: flex;
    gap: 6px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .ant-modal-body {
    padding-top: 0px;
  }

  .btn-group {
    justify-content: flex-end;
  }

  .required {
    color: red;
  }

  .error {
    color: red;
    font-size: 12px;
  }

  .text-12 {
    font-size: 12px;
  }
  .small-text {
    font-size: 10px;
    color: #757b8c;
  }

  .border-img {
    padding: 6px;
    border: 1px solid #FFF;
    border-radius: 4px;
    width: fit-content;
    .preview-image {
      /* width: 130px; */
      /* height: 150px; */
      width: 142px;
      position: relative;
      cursor: pointer;
      aspect-ratio: ${({ ratio }) => (ratio ? ratio : "4/3")};
      .image {
        width: 142px;
        height: 100%;
        position: absolute;
        z-index: 5;
        object-fit: cover;
        // border: 1px solid #156df2;
        cursor: pointer;
      }

      .image-slide {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 5;
        cursor: pointer;
      }

      .btn-action {
        position: absolute;
        z-index: 6;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 8px;

        &.delete {
          background-color: #e03137;
        }
        &.delete:hover {
          box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
          scale: 1.1;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .preview-video {
    width: 300px;
    position: relative;
    aspect-ratio: ${({ ratio }) => (ratio ? ratio : "4/3")};
    border: 1px solid #FFF;
    border-radius: 4px;

    .btn-preview {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      border: none;
      width: 100%;
      height: 100%;
      opacity: 1;
      cursor: pointer;
      aspect-ratio: 4 / 3;
      background: transparent;
    }

    .video {
      width: 200px;
      height: 100%;
      position: absolute;
      z-index: 5;
      border: 1px solid #FFF;
      border-radius: 4px;
      padding: 6px;
      video {
        object-fit: fill;
      }
    }

    .btn-action {
      position: absolute;
      z-index: 6;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 8px;

      &.delete {
        background-color: #e03137;
      }
    }
  }

  .custom {
    justify-content: center;
    align-items: center;
  }

  input::placeholder,
  textarea::placeholder,
  .ant-select-selection-placeholder {
    color: #757b8c;
  }

  .ant-input-disabled {
    background-color: #bababa36;
    color: #bdbdbd;
    cursor: text;
    border-color: #156df2;
  }
  .anticon {
    color: #fff;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: unset;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #2b6ae680;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #2b6ae680;
  }
`;

export const PreviewModalWrapper = styled(Modal)`
  background: transparent;
  width: ${({ width }) => (width ? `${width}px` : "800px")};
  height: ${({ height }) => (height ? `${height}px` : "600px")};
  .ant-modal-content {
    .ant-modal-body {
      padding: 0px;
    }
    .ant-modal-close-x {
      /* display: none; */
    }
  }
  .ant-modal-content {
    background: transparent;
    border-radius: 20px;
    box-shadow: unset;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .ant-modal-close {
    top: -30px;
    right: -50px;

    .ant-modal-close-x {
      width: 44px;
      height: 44px;
      line-height: 30px;
    }
  }

  video,
  .preview {
    border-radius: 10px;
  }
  .preview {
    box-shadow: rgb(241 237 237) 0px 1px 4px, rgb(255 254 254) 0px 0px 0px 5px;
    background-color: #ececec;
  }
  .preview-modal {
    width: ${({ width }) => (width ? `${width}px` : "800px")};
    height: ${({ height }) => (height ? `${height}px` : "600px")};
  }

  .anticon {
    color: #fff;
  }

  .video-content {
    overflow: hidden;
    position: relative;
    width: ${({ width }) => (width ? `${width}px` : "800px")};
    height: ${({ height }) => (height ? `${height}px` : "600px")};
    position: relative;
    box-shadow: rgb(241 237 237) 0px 1px 4px, rgb(255 254 254) 0px 0px 0px 5px;
    border-radius: 6px;
    .custom-video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .controls {
      position: absolute;
      width: 100%;
      height: 100px;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(8, 9, 13, 0),
        rgba(8, 9, 13, 0.9)
      );

      .name-video {
        position: absolute;
        color: #fff;
        top: 0;
        left: 3%;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .current-time {
        position: absolute;
        color: #c7c5c5;
        top: 0;
        left: calc(100% - 144px);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .total-time {
        position: absolute;
        color: #c7c5c5;
        top: 0;
        left: calc(100% - 70px);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .temp {
        position: absolute;
        color: #c7c5c5;
        top: 0;
        left: calc(100% - 79px);
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .range {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 20px);
        // -webkit-appearance: none;
        background: #d9d9d9;
        border-radius: 5px;
        height: 2px;
        cursor: pointer;

        &::-webkit-slider-thumb {
          // -webkit-appearance: none;

          height: 4px;
          background: red;
          border-radius: 5px;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          height: 4px;
          background: red;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      .btn-control {
        position: absolute;
        background: transparent;
        border: none;
        cursor: pointer;

        &.btn-play {
          bottom: 15px;
          left: 15px;
        }

        &.btn-prev {
          bottom: 15px;
          left: 50px;
        }
        &.btn-next {
          bottom: 15px;
          left: 90px;
        }

        &.btn-mute {
          bottom: 15px;
          right: 50px;
        }
        &.btn-full {
          bottom: 15px;
          right: 15px;
        }
      }
    }
  }
`;

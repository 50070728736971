import React, { useState } from 'react';
import { SlideWrapper } from './styled';
import { Images } from '../../../theme';
import { Button } from 'antd';

const SlideImages = ({ images, ...props }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  };

  const goToPreviousSlide = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
  };

  return (
    <SlideWrapper>
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? 'active' : ''}`}
          // style={{ backgroundImage: `url(${image})` }}
        >
          <img src={image} className="item-slide" alt="img" />
        </div>
      ))}
      <div className="count">
        {currentIndex + 1}/{images.length}
      </div>
      <Button type="text" onClick={goToPreviousSlide} className="btn-prev">
        <img src={Images.ICON_ARROW_LEFT} alt="prev" />
      </Button>
      <Button type="text" onClick={goToNextSlide} className="btn-next">
        <img src={Images.ICON_ARROW_RIGHT} alt="next" />
      </Button>
    </SlideWrapper>
  );
};

export default SlideImages;

import BigNumber from 'bignumber.js';

export function numberWithCommas(n, comma = ',') {
  if (!n) return 0;
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, comma);
}

export const totalAmount = (prices) => {
  const total = prices.reduce((accumulator, currentValue) => fixAmount(accumulator + currentValue.price), 0);
  return total;
}

export const feeAmount = (amount, feePercent) => {
  const res = sliceDecimalTwo((amount * feePercent)/100);
  return Number(res)
} ;

export const fixAmount = (amount) => Math.round(amount * 100) / 100;

export const formattofixed  = (value) => {
  return Number(value.toFixed(2));
}

export const fixAdditionErrorJavascript  = (value1, value2, dec) => {
  return ((value1 * dec) + (value2 * dec)) / dec //fix addition error of javascript
}

export const sliceDecimalTwo = (value) => {
  const bigNum = new BigNumber(value);
  if (!bigNum.isFinite() || bigNum.isZero()) {
    return new BigNumber(0).toString();
  }
  const truncatedNum = bigNum.decimalPlaces(2, BigNumber.ROUND_DOWN);
  return truncatedNum.toString();
}

export const oneDecimalPlace = (value, decimal = 2, comma = ',') => {
  const factor = new BigNumber(10).pow(decimal);
  const bigValue = new BigNumber(value);
  const truncatedNum = bigValue.multipliedBy(factor).integerValue(BigNumber.ROUND_FLOOR).dividedBy(factor);

  if (truncatedNum.isNaN()) return '0';

  let result = truncatedNum.toFixed(decimal);
  result = result.replace(/\.?0+$/, '');
  let parts = result.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, comma);
  result = parts.join('.');
  return result;
};

export const substract = (value1, value2) => {
  const bigNum1 = new BigNumber(value1);
  const bigNum2 = new BigNumber(value2);
  return Number(bigNum1.minus(bigNum2));
}

import React from 'react'

import { Images, Colors } from "../../theme";
import { CardStyled } from './styled'

export default ({ title, button, image, height, positionImage, ...props }) => (
  <CardStyled {...props} positionImage={positionImage}>
    <div className='wrapper'>
      <div className='image'>
        <img src={image} alt="Image Service" />
      </div>
      <div className='content'>
        <h3 className='title'>{title}</h3>
        {button}
      </div>
    </div>

    <div className='bg-card'>
      <img src={Images.BG_CARD_SERVICE} alt="Background card event" />
    </div>
  </CardStyled>
)
